import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  DeleteConfirmDialog,
  deleteConfirmationWords,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  RBButton,
} from "components/Common/Utils";
import { useParams } from "react-router-dom";
import moment from "moment";

import { format } from "date-fns";
import StarComponent from "./StarComponent";
import Lightbox from "react-image-lightbox";
import Swal from "sweetalert2";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// import "./CustomCardEventOrg.css";
// import EventsListTable from "./EventsListTable";
// import EventReviewListTable from "./EventReviewListTable";

// import EventUserList from "./EventUserList";

// import EventAdListTable from "./EventAdTable";
import AdminListTable from "pages/EventManagement/AdminListTable";
import InviteeUserTable from "./InviteeUserTable";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function UserPromoterEventDetails({
  OrganizerId,
  organizerName,
}) {
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [organizerData, setData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [loader, setLoader] = useState(true);
  const location = useLocation();

  const { state } = location;
  console.log({ state });
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const [textReply, setTextReply] = React.useState("");

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();

  const OrgFetchDetail = async () => {
    setLoader(false);
    postRequest(`event/detail`, { eventId: `${id}` }).then((data) => {
      let userdata = data.data;
      setData(userdata);

      console.info("userdata", userdata);

      if (userdata?.event?.admins?.length > 0) {
        const tadmins = userdata?.users.filter((user) => {
          return userdata?.event?.admins.includes(user._id);
        });

        setAdmins(tadmins);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    OrgFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, url, obj, key);
      }
    });
  };

  // const showRandomWordDialog = (id, url, obj, key) => {
  //   const randomIndex = Math.floor(
  //     Math.random() * deleteConfirmationWords.length
  //   );
  //   const randomWord = deleteConfirmationWords[randomIndex];

  //   Swal.fire({
  //     title: "Confirmation Required",
  //     text: `To confirm deletion, please enter the word: ${randomWord}`,
  //     icon: "warning",
  //     input: "text",
  //     inputPlaceholder: `Type '${randomWord}' to confirm`,
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Confirm",
  //   }).then((result) => {
  //     if (result.isConfirmed && result.value === randomWord) {
  //       deleteItem(id, url, obj, key);
  //     } else if (result.isConfirmed) {
  //       Swal.fire(
  //         "Incorrect Entry",
  //         "Please enter the correct word to confirm.",
  //         "error"
  //       );
  //     }
  //   });
  // };

  const deleteItem = async (id, url, obj, key) => {
    const response = await deleteRequest(url, obj);
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      // Remove from venueData
      const vData = { ...organizerData };
      vData[key] = vData[key].filter((item) => item._id !== id);
      setData(vData);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`event/org/gallery/delete`, {
      eventOrgId: id,
      // image: gallery,
      image: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      OrgFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const deleteReview = async (id, index) => {
    const response = await deleteRequest("rating/event/delete", {
      ratingId: id,
    });
    // remove form OrganizerData.ratings

    if (response.status == 1) {
      const orgData = { ...organizerData };
      orgData.ratings.splice(index, 1);
      setData(orgData);
      toast.success("Review Deleted");
    } else {
      toast.error(response.message);
    }
  };

  const eventImage = organizerData?.event?.image;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Event Detail</h4>

            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Event Management</li>:{" "}
              <li className="breadcrumb-item">Event details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
                onClick={() =>
                  showLightBox(
                    state?.row?.venue?.logo || state?.row?.customVenue?.image
                  )
                }
              ></div>
              <div className="profile-image2">
                <img
                  src={
                    state?.row?.venue?.logo || state?.row?.customVenue?.image
                  }
                  onClick={() =>
                    showLightBox(
                      state?.row?.venue?.logo || state?.row?.customVenue?.image
                    )
                  }
                  alt="Image Not Available"
                />
              </div>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px", marginTop: "20px" }}
                >
                  <br></br>

                  <div className="fst">
                    {" "}
                    <h3>
                      {state?.row?.venue?.name || state?.row?.customVenue?.name}{" "}
                    </h3>{" "}
                  </div>

                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        User Type: <span>{state?.row?.type}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Type: <span> {state?.row?.venueType}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Address{" "}
                        <span>
                          {" "}
                          {state?.row?.venue?.address ||
                            state?.row?.customVenue?.address}
                        </span>{" "}
                      </p>{" "}
                    </div>

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {state?.row?.venue?.email && (
                          <>
                            Email: <span>{state?.row?.venue?.email}</span>
                          </>
                        )}

                        {state?.row?.customVenue?.description && (
                          <>
                            Description:{" "}
                            <span>{state?.row?.customVenue?.description}</span>
                          </>
                        )}
                      </p>
                    </div>

                    {state?.row?.venue?.phone && (
                      <div className="col-md-4 fst py-1">
                        {" "}
                        <p>
                          {" "}
                          Phone
                          <span> {state?.row?.venue?.phone}</span>{" "}
                        </p>{" "}
                      </div>
                    )}

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Dress Code <span> {state?.row?.dressCode}</span>{" "}
                      </p>{" "}
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </Card>
            <Col
              md="12"
              style={{ marginLeft: "inherit", marginBottom: "25px" }}
            >
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#events" data-toggle="tab">
                      {" "}
                      Invited Users{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#reviews" data-toggle="tab">
                      {" "}
                      Admins{" "}
                    </a>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">
                <div className="tab-pane active padded-tab" id="events">
                  <InviteeUserTable admins={state?.row} />
                </div>

                <div className="tab-pane padded-tab" id="reviews">
                  <InviteeUserTable admins={state?.row} />
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
}
