import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, putRequest } from "../../components/Common/Utils.js";
import { Select } from "@material-ui/core";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import BrunchModal from "components/Modal/BrunchModal.js";
import BusinessVenueModal from "components/Modal/BusinessVenueModal.js";

export default function BusinessUpdateSpecialOffer({
  onFormSubmit,
  specialOfferObj,
}) {
  let history = useHistory();

  const state = specialOfferObj;

  const [specialOfferData, setSpecialOfferData] = useState();
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [open, setOpen] = useState(false);
  const [listFetched, setListFetched] = useState(false);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);

  const [venueOptions, setVenueOptions] = useState([
    { value: "", label: "None" },
  ]);

  const [loading, setLoading] = useState(false);

  const [brunches, setBrunches] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    setSpecialOfferData(state);

    if (state?.discount?._id) {
      state.discount = state.discount._id;
    }

    if (state?.brunch) {
      setBrunches(state?.brunch);
    }
  }, [state]);

  useEffect(() => {
    fetchList();
    fetchDiscount();
    setVenueId(state?.venue?._id);
    setVenueText(state?.venue?.name);
    setVenueImage(state?.venue?.logo);
  }, [state]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("venue/discount/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const deleteBrunch = (index) => {
    const tempPack = [...brunches];
    tempPack.splice(index, 1);
    setBrunches(tempPack);
  };

  const pushBrunch = (data) => {
    const tempPack = [...brunches];

    const discountItem = data.discountItem;
    const discountText = discountList.find(
      (item) => item._id === data.itemDiscount
    ).title;

    data.discountText = discountText;

    tempPack.push(data);
    setBrunches(tempPack);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
     
      setListFetched(true);
    }
    setLoading(false);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleModel2 = () => {
    setModel2(!model2);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);

    closeModal2();
  };

  const UpdatespecialOffer = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      specialOfferId: state._id,
      title: specialOfferData.title,
      venueId: venueId,
      claimCode: specialOfferData?.claimCode ? specialOfferData?.claimCode : "",
      description: specialOfferData.description,
      type: specialOfferData.type,
      discount: specialOfferData.discount ? specialOfferData.discount : "",
    };

    if (specialOfferData.type === "brunch") {
      const newbranches = brunches.map((item) => {
        return {
          item: item.item,
          amount: item.amount,
          itemDiscount: item.itemDiscount,
        };
      });
      payload.brunch = newbranches;
    }

    const response = await postRequest(
      "venue/business/my-special-offer-update",
      payload
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        onFormSubmit();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...specialOfferData };
    list[index] = value;
    setSpecialOfferData(list);
  };

  const fetchIndex = (items, search) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value === search) {
        return i;
      }
    }
    return -1;
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Special Offer </h4>
                <Form onSubmit={UpdatespecialOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={specialOfferData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={specialOfferData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel2()}
                          >
                            {venueText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {venueText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //   <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     Select Venue
                              //   </a>
                              // </div>
                              // <div>
                              //   <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={specialOfferData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option> Select</option>
                              <option value="total">Total</option>
                              <option value="brunch">Brunch</option>
                            </select>
                          </div>
                        </div>
                        {specialOfferData?.type === "brunch" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Brunch
                            </label>
                            <div className="col-sm-6">
                              <a
                                onClick={() => toggleModal()}
                                href="javascript:void(0)"
                              >
                                Add Brunches
                              </a>
                              <br />
                              <br />
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {brunches?.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.item}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.discountText}</td>
                                      <td>
                                        <a
                                          onClick={() => deleteBrunch(index)}
                                          href="javascript:void(0)"
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}

                        {specialOfferData?.type === "total" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Discount
                            </label>
                            <div className="col-sm-6">
                              <select
                                value={specialOfferData?.discount}
                                onChange={(e) =>
                                  updateValue("discount", e.target.value)
                                }
                                class="form-select"
                              >
                                <option> Select</option>
                                {discountList.map((item, index) => (
                                  <option value={item._id}>{item.title}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <BrunchModal
        addData={pushBrunch}
        show={open}
        closeModal={closeModal}
        discountList={discountList}
      />
      <BusinessVenueModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow}
      />
    </React.Fragment>
  );
}
