import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

//import filter modal

import moment from "moment";
import FilterModal from "../../components/Modal/FilterModal";
import {
  DeleteConfirmDialog,
  deleteConfirmationWords,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  RBButton,
} from "components/Common/Utils";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BucketList from "pages/UserManagement/BucketList";
import { useHistory, useParams, useLocation } from "react-router-dom";

const filterObj = {
  name: "",
  createdAt: "",
};

const InviteeUserTable = ({ admins }) => {
  const [modal_center, setmodal_center] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const state = location.state?.row;
  const [eventsData, setEventsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [offer, setOffer] = useState();
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [eventId, setEventId] = useState("");

  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };
  let invitedUser = admins?.invitedUser;
  console.log({ invitedUser });
  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? true : false;

    const formData = new FormData();
    formData.append("userId", actId);
    formData.append("enable", status);

    const res = await putRequestForm("bucket/update", formData);

    if (res.status == 1) {
      toast.success("User Status updated Successfully");
      eventsManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email" ||
          key === "phone"
        ) {
          filterObject.type = "regex";
        } else if (key === "gender" || key === "enable") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    toggleModel();
    eventsManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    eventsManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("DD-MM-YYYY HH:mm:ss")} </p>
        </div>
      </React.Fragment>
    );
  }
  function EventTime(cellContent, row) {
    let eventTime = row.event_time;
    return (
      <React.Fragment>
        <div>
          <p>{moment(eventTime).format("DD/MM/YYYY, HH:mm:ss")} </p>
        </div>
      </React.Fragment>
    );
  }
  function InviteTime(cellContent, row) {
    let creationTime = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(creationTime).format("DD-MM-YYYY HH:mm:ss")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }
  function UserNameFormatter(cellContent, row) {
    return (
      <>
        <p>
          <img
            className="avatar-sm rounded-circle"
            src={row.user?.image}
            onClick={() => showLightBox(row.user?.image)}
            alt="img"
            width={"50px"}
          />
          &nbsp;&nbsp;
          {row.user?.first_name} {row.user?.last_name}
        </p>
      </>
    );
  }

  function InvitedByFormatter(cellContent, row) {
    return (
      <>
        <p>
          <img
            className="avatar-sm rounded-circle"
            src={row.invitedBy?.image}
            onClick={() => showLightBox(row.invitedBy?.image)}
            alt="img"
            width={"50px"}
          />
          &nbsp;&nbsp;
          {row.invitedBy?.first_name} {row.invitedBy?.last_name}
        </p>
      </>
    );
  }
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: CreatedAt,
      sort: false,
    },
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   formatter: iconFormatter,
    //   sort: false,
    // },
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, offer, row) {
    return (
      <React.Fragment>
        <div className="d-flex gap-1 justify-content-center align-item-center">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(offer._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  // const bucketUserId = userData[0]?.user_id;

  const eventsManagment = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      eventId: event,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    // const formData = new FormData();
    // formData.append("userId", offer?.user_id);
    // const user = await postRequestForm("bucket/list/user", formData);

    const response = await postRequest(`event/guest/list`, payload);
    const data = response.data;
    const combinedData = data.inviations.map((invite) => {
      const user = data.users.find((u) => u._id === invite.user);
      return { ...invite, user };
    });

    // setTotalPage(data?.count);
    // setCurrentFPage(data?.page);
    // const buckets = data.list.filter((x) => {
    //   if (x.user_id === userData) return x;
    // });

    setEventsData(combinedData);
    setLoader(false);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGuestInvite(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGuestInvite = async (id) => {
    const response = await deleteRequest(`event-organizer/event/guest/delete`, {
      inviteId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      eventsManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleEvents(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleEvents = async (ids) => {
    const response = await deleteRequest(`event-organizer/event/guest/delete`, {
      ids: ids,
      model: "event",
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      eventsManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  <ToolkitProvider
                    keyField="_id"
                    data={invitedUser}
                    columns={columns}
                  >
                    {(props) => (
                      <Row>
                    

                        <Col className="col-lg-12 text-end">
                          <BootstrapTable
                            onTableChange={handleTableChange}
                            remote={false}
                            selectRow={selectRow}
                            rowClasses={rowClasses}
                            classes="table-custom-border"
                            {...props.baseProps}
                          />
                        </Col>
                      </Row>
                    )}
                  </ToolkitProvider>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <BucketList
        showBucketModal={model}
        closeBucketModal={closeBucketModal}
        offer={offer}
      />
    </>
  );
};

export default InviteeUserTable;
