import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner, Form } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequestForm,
  putRequest,
  customValidator,
  RBButton,
  convertTimeToFormattedString,
} from "components/Common/Utils";
import Lightbox from "react-image-lightbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReactCrop from "react-image-crop";
import { format } from "date-fns";
import StarComponent from "./StarComponent";
import InviteListTable from "./InviteListTable";

import RatingsComponent from "./RatingsComponent";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import VenueModal from "components/Modal/VenueModalOrg.js";
import AdminModal from "components/Modal/AdminlistModalId";

import Swal from "sweetalert2";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import { canvasPreview } from "pages/VenueManagement/canvasPreview";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { ToastContainer, toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FollowListTable from "./FollowListTable";

import "./CustomCardOrg.css";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const filterObjRating = {
  userName: "",
  title: "",
  stars: "",
  review: "",
  reply: "",
  status: "",
  createdAt: "",
};

const filterObjEvent = {
  title: "",
  status: "",
  venueName: "",
  type: "",
  venue_type: "",
  event_time: "",
  reservation_time: "",
  createdAt: "",
};

export default function EventOrganizerdetails() {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const [eventData, setEventData] = useState([]);
  const [selectedItemsRating, setselectedItemsRating] = useState([]);
  const [selectedItemsEvent, setselectedItemsEvent] = useState([]);
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [organizerData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [modelAdmin, setModelAdmin] = useState(false);
  const [adminPreview, setAdminPreview] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [eventgalleryData, setEventGalleryData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [passwordData, setPasswordData] = useState([]);
  const [openReview, setOpenReview] = React.useState(false);

  const [image, setImage] = React.useState("");
  const [loader, setLoader] = useState(true);
  const location1 = useLocation();
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);
  const [textReply, setTextReply] = React.useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [disableType, setDisableType] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [eventDetail, setEventDetail] = useState(null);
  const [galleryWindow, setGalleryWindow] = useState("list");
  const [eventWindow, setEventWindow] = useState("list");

  const [selectedLogo, setSelectedLogo] = useState("");
  const [modelEvent, setModelEvent] = useState(false);
  const [modelRating, setModelRating] = useState(false);

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const blobUrlRef = useRef("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [filterRating, setFilterRating] = useState(filterObjRating);
  const [sortRating, setsortRating] = React.useState("createdAt");
  const [sortOrderRating, setsortOrderRating] = React.useState("desc");
  const [searchTextRating, setSearchTextRating] = useState("");
  const [currentFPageRating, setCurrentFPageRating] = useState(1);
  const [currentPageRating, setCurrentPageRating] = useState(1);
  const [totalPageRating, setTotalPageRating] = useState(0);
  const [sizePerPageOrgRating, setSizePerPageRating] = useState(10);
  const [eventRatingdata, setEventRatingdata] = useState([]);

  const [filterEvent, setFilterEvent] = useState(filterObjEvent);
  const [sortEvent, setsortEvent] = React.useState("createdAt");
  const [sortOrderEvent, setsortOrderEvent] = React.useState("desc");
  const [searchTextEvent, setSearchTextEvent] = useState("");
  const [currentFPageEvent, setCurrentFPageEvent] = useState(1);
  const [currentPageEvent, setCurrentPageEvent] = useState(1);
  const [totalPageEvent, setTotalPageEvent] = useState(0);
  const [sizePerPageOrgEvent, setSizePerPageEvent] = useState(10);
  const [eventdata, setEventdata] = useState([]);
  const [activeTab, setActiveTab] = useState("all");

  const onTabChange = (tabId) => {
    if (tabId === "all") {
      setActiveTab("all");
    } else if (tabId === "in") {
      setActiveTab("in");
    } else if (tabId === "out") {
      setActiveTab("out");
    }
  };

  useEffect(() => {
    eventRatingManagment();
  }, [currentPageRating]);

  useEffect(() => {
    eventRatingManagment();
  }, [organizerData._id]);

  const showLightBox2 = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox2(true);
  };

  useEffect(() => {
    eventRatingManagment();
  }, [sizePerPageOrgRating, sortRating, sortOrderRating]);
  const closeModalRating = () => {
    setModelRating(false);
  };
  const toggleModelRating = () => {
    setModelRating(!modelRating);
  };

  const updateFilterRating = (key, value) => {
    const filterObjRating = { ...filterRating };
    filterObjRating[key] = value;
    setFilterRating(filterObjRating);
  };

  const applyFilterRating = () => {
    const filterArgs = [];

    Object.keys(filterRating).forEach((key) => {
      if (
        filterRating[key] !== "" &&
        filterRating[key] !== null &&
        filterRating[key] !== undefined
      ) {
        var filterObject = {
          [key]: filterRating[key],
        };

        if (key === "userName" || key === "review" || key === "reply") {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    
    toggleModelRating();
    eventRatingManagment(filterArgs);
  };
  const resetFilterRating = () => {
    setFilterRating({ ...filterObj });
    toggleModelRating();
    eventRatingManagment();
  };

  useEffect(() => {
    eventManagment();
  }, [currentPageEvent]);

  useEffect(() => {
    eventManagment();
  }, [sizePerPageOrgEvent, sortEvent, sortOrderEvent]);
  const closeModalEvent = () => {
    setModelEvent(false);
  };
  const updateFilterEvent = (key, value) => {
    const filterObjEvent = { ...filterEvent };
    filterObjEvent[key] = value;
    setFilterEvent(filterObjEvent);
  };
  const applyFilterEvent = () => {
    const filterArgs = [];

    Object.keys(filterEvent).forEach((key) => {
      if (
        filterEvent[key] !== "" &&
        filterEvent[key] !== null &&
        filterEvent[key] !== undefined
      ) {
        var filterObject;
        // if (key === "venueName") {
        //   filterObject = {
        //     "venue.name": filterEvent["venueName"],
        //   };
        // } else {
        filterObject = {
          [key]: filterEvent[key],
        };
        // }

        if (key === "title" || key === "venueName") {
          filterObject.type = "regex";
        } else if (key === "status" || key === "type" || key === "venue_type") {
          filterObject.type = "eq";
        } else if (
          key === "createdAt" ||
          key === "event_time" ||
          key === "reservation_time"
        ) {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModelEvent();
    eventManagment(filterArgs);
  };
  const resetFilterEvent = () => {
    setFilterEvent({ ...filterObjEvent });
    toggleModelEvent();
    eventManagment();
  };
  function dateFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.createdAt)}
      </div>
    );
  }
  const componentsEvent = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filterEvent.title}
          onChange={(e) => updateFilterEvent("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Venue Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filterEvent.venueName}
          onChange={(e) => updateFilterEvent("venueName", e.target.value)}
          name="venueName"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Venue Type</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilterEvent("venue_type", e.target.value)}
          className="form-select"
          value={filterEvent.venue_type}
        >
          <option value="">Select Venue Type</option>
          <option value="venue">Venue</option>
          <option value="custom venue">Custom Venue</option>
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Event Time</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filterEvent.event_time}
          onChange={(e) => updateFilterEvent("event_time", e.target.value)}
          name="event_time"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Reservation Time</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filterEvent.reservation_time}
          onChange={(e) =>
            updateFilterEvent("reservation_time", e.target.value)
          }
          name="reservation_time"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filterEvent.createdAt}
          onChange={(e) => updateFilterEvent("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilterEvent}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilterEvent}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];
  const componentsRating = [
    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">User</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filterRating.userName}
          onChange={(e) => updateFilterRating("userName", e.target.value)}
          name="user"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Stars</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilterRating("stars", e.target.value)}
          className="form-select"
        >
          <option value="">Select Star</option>
          <option value="1">1 Star</option>
          <option value="2">2 Star</option>
          <option value="3">3 Star</option>
          <option value="4">4 Star</option>
          <option value="5">5 Star</option>
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Review</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filterRating.review}
          onChange={(e) => updateFilterRating("review", e.target.value)}
          name="review"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Reply</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filterRating.reply}
          onChange={(e) => updateFilterRating("reply", e.target.value)}
          name="reply"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filterRating.createdAt}
          onChange={(e) => updateFilterRating("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilterRating}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilterRating}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  useEffect(() => {
    if (eventData?.user_type == "user") {
      // set type into private & disable type dropdown

      const data = { ...eventData };
      data.type = "private";
      setEventData(data);
      setDisableType(true);
    } else {
      setDisableType(false);
    }
  }, [eventData?.user_type]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const eventRatingManagment = async (filterArgs = []) => {
    if (!organizerData?._id) return;

    setLoading(true);
    const payload = {
      limit: sizePerPageOrgRating,
      page: currentPageRating,
      orgId: organizerData?._id,
      sortField: sortRating,
      sortOrder: sortOrderRating,
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchTextRating) {
        payload.search = searchTextRating;
      }
    }
    const response = await postRequest(`review/list/org`, payload);
    const data = response.data;
    setTotalPageRating(data.count);
    setCurrentFPageRating(data.page);
    setEventRatingdata(data.list);
    setLoading(false);
  };

  const eventManagment = async (filterArgs = []) => {
    setLoading(true);

    const payload = {
      limit: sizePerPageOrgEvent,
      page: currentPageEvent,
      sortField: sortEvent,
      sortOrder: sortOrderEvent,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchTextEvent) {
        payload.search = searchTextEvent;
      }
    }

    const response = await postRequest(`event/org/my-event/list`, payload);
    const data = response.data;
    setTotalPageEvent(data.count);
    setCurrentFPageEvent(data.page);
    setEventdata(data.list);
    setLoading(false);
  };

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const updateValue = (index, value) => {
    const list = { ...organizerData };
    list[index] = value;
    setData(list);
  };

  const updatePassword = (index, value) => {
    const list = { ...passwordData };
    list[index] = value;
    setPasswordData(list);
  };

  const updateValueGallery = (index, value) => {
    const list = { ...eventgalleryData };
    list[index] = value;
    setEventGalleryData(list);
  };
  const handleRemoveAdminPreview = (index) => {
    const newArray = [...adminPreview];
    newArray.splice(index, 1);
    setAdminPreview(newArray);
    const newvenue = [...adminId];
    newvenue.splice(index, 1);
    setAdminId(newvenue);
  };

  const updateEventValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const Creategallery = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    // formData.append("image", selectedImage);
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    const response = await postRequestForm(
      "event/org/my-gallery/create",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      OrgFetchDetail();
      setTimeout(() => {
        setGalleryWindow("list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateEventsData = async (e) => {
    e.preventDefault();
    if (!eventData.title) {
      toast.error("Title is required");
      return;
    }

    if (!eventData.description) {
      toast.error("Please enter description");
      return;
    }

    if (!eventData.type) {
      toast.error("Please select type");
      return;
    }

    if (!eventData.venue_type) {
      toast.error("Please select venue type");
      return;
    }

    if (!eventData.reservation_time) {
      toast.error("Please enter reservation time");
      return;
    }

    if (!eventData.event_time) {
      toast.error("Please enter event time");
      return;
    }
    // check atleast one package added

    const totalSavedPackages = packages.filter((item) => item.saved);

    if (totalSavedPackages.length == 0) {
      toast.error("Please add atleast one package");
      return;
    }

    // check atleast 1 package item is featured

    const isFeatured = packages.filter((item) => item.isFeatured && item.saved);

    if (isFeatured.length == 0) {
      toast.error("Please select atleast one featured package");
      return;
    }

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    formData.append("eventId", selectedEvent);
    formData.append("title", eventData.title);
    formData.append("description", eventData.description);
    formData.append("user_type", eventData.user_type);

    if (eventData?.user_type === "user") {
      formData.append("user_id", userId);
    }
    formData.append("type", eventData.type);

    formData.append("venue_type", eventData.venue_type);
    if (eventData?.venue_type === "venue") {
      if (venueId) {
        formData.append("venue", venueId);
      }
    }

    // formData.append("image", selectedImage);

    formData.append("reservation_time", eventData.reservation_time);
    formData.append("event_time", eventData.event_time);
    // formData.append("event_discounts", eventData.event_discounts);
    //formData.append("status", eventData.status);

    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    // add packages
    packages.forEach((item, index) => {
      if (item.saved) {
        formData.append(`packages_title[]`, item.title);
        formData.append(`packages_price[]`, item.amount);
        formData.append(`packages_discount[]`, item.discount);
        formData.append(`packages_qty[]`, item.qty);
        formData.append(`packages_isFeatured[]`, item.isFeatured);
      }
    });

    if (adminId.length > 0) {
      adminId.forEach((item, index) => {
        formData.append(`admins[]`, item);
      });
    }

    // remove adminPreview

    setAdminPreview([]);
    setAdminId([]);

    setLoading(true);

    const response = await putRequestForm(
      "event/org/my-event/update",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      OrgFetchDetail();
      eventManagment();

      toast.success(response.message);

      setTimeout(() => {
        setEventData({});
        setSelectedImage(null);
        setVenueId(null);
        setVenueText(null);
        setPreviewImage(null);
        setEventWindow("list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const createEventsData = async (e) => {
    e.preventDefault();
    if (!eventData.title) {
      toast.error("Title is required");
      return;
    }

    if (!eventData.description) {
      toast.error("Please enter description");
      return;
    }

    if (!eventData.type) {
      toast.error("Please select type");
      return;
    }

    if (!eventData.venue_type) {
      toast.error("Please select venue type");
      return;
    }

    if (!eventData.reservation_time) {
      toast.error("Please enter reservation time");
      return;
    }

    if (!eventData.event_time) {
      toast.error("Please enter event time");
      return;
    }

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    formData.append("title", eventData.title);
    formData.append("description", eventData.description);
    formData.append("user_type", eventData.user_type);

    if (eventData?.user_type === "user") {
      formData.append("user_id", userId);
    }

    formData.append("type", eventData.type);

    formData.append("venue_type", eventData.venue_type);
    if (eventData?.venue_type === "venue") {
      formData.append("venue", venueId);
    }

    // formData.append("image", selectedImage);
    formData.append("reservation_time", eventData.reservation_time);
    formData.append("event_time", eventData.event_time);
    // formData.append("event_discounts", eventData.event_discounts);
    //formData.append("status", eventData.status);
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    // add packages
    packages.forEach((item, index) => {
      if (item.saved) {
        formData.append(`packages_title[]`, item.title);
        formData.append(`packages_price[]`, item.actual);
        formData.append(`packages_discount[]`, item.discount);
        formData.append(`packages_qty[]`, item.qty);
        formData.append(`packages_isFeatured[]`, item.isFeatured);
      }
    });

    adminId.forEach((item, index) => {
      formData.append(`admins[]`, item);
    });
    

    setLoading(true);

    const response = await postRequestForm(
      "event/org/my-event/create",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      OrgFetchDetail();
      eventManagment();

      toast.success(response.message);
      setTimeout(() => {
        setEventData({});
        setSelectedImage(null);
        setVenueId(null);
        setVenueText(null);
        setPreviewImage(null);
        setEventWindow("list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], eventData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
    gridList: {
      width: "100%",
    },
    // galleryImage: {
    //   width: "100%",
    // },
  });

  const classes = useStyles();

  const { id } = useParams();

  const OrgFetchDetail = async () => {
    setLoader(false);
    postRequest(`event/org/profile`, {}).then((data) => {
      let userdata = data.data.data;
      let status = data.status;
      setData(userdata);
      setLoader(false);
      eventRatingManagment();
    });
  };

  const Updateeventorganizer = async (e) => {
    e.preventDefault();

    const eventData = { ...organizerData };
    if (!eventData.name) {
      toast.error("Please enter event name");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("eventOrgId", id);
    formData.append("name", eventData.name);
    formData.append("description", eventData.description);
    formData.append("phone", eventData.phone);
    formData.append("email", eventData.email);
    formData.append("password", eventData.password);
    formData.append("website", eventData.website);

    if (remoteLogo) {
      formData.append("logo", remoteLogo);
    }
    if (selectedCover) {
      formData.append("cover", selectedCover);
    }

    const response = await putRequestForm("event/org/profile/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      eventManagment();
    } else {
      toast.error(response.message);
    }
  };

  const updateOrgPassword = async (e) => {
    e.preventDefault();

    // check old_password, new_password is not empty & not same

    if (!passwordData.old_password) {
      toast.error("Please enter old password");
      return;
    }

    if (!passwordData.new_password) {
      toast.error("Please enter new password");
      return;
    }

    if (passwordData.new_password == passwordData.old_password) {
      toast.error("Old password and new password should not be same");
      return;
    }

    if (passwordData.confirm_password == passwordData.new_password) {
      setLoading(true);
      const response = await putRequest("event/org/password/update", {
        oldPassword: passwordData.old_password,
        newPassword: passwordData.new_password,
      });
      setLoading(false);
      if (response.status == 1) {
        toast.success(response.message);
        setPasswordData({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Password and confirm password not matched");
    }
  };

  useEffect(() => {
    OrgFetchDetail();
  }, []);

  

  useEffect(() => {
    setPreviewCover(organizerData?.cover ? organizerData?.cover : "");
    setPreviewLogo(organizerData?.logo ? organizerData?.logo : "");

    // wait for 1 sec
  }, [organizerData]);

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          OrgFetchDetail();
          eventManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };
  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleOpenReview = (review) => {
    setSelectedReview(review);
    setOpenReview(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseReview = () => {
    setOpenReview(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateReply = async () => {
    const response = await postRequest("review/reply/addUpdate", {
      reviewId: selectedReview._id,
      reply: textReply,
    });
    handleCloseReview();
    eventRatingManagment();
    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  const updateDiscountNew = (index, value) => {
    const list = [...selectedPackages];
    list[index].discount = value;
    setSelectedPackages(list);
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const toggleModelAdmin = () => {
    setModelAdmin(!modelAdmin);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModalAdmin = () => {
    setModelAdmin(false);
  };
  const toggleModelEvent = () => {
    setModelEvent(!modelEvent);
  };

  const showInvitelist = (event) => {
    setEventWindow("invitelist");
    setSelectedEvent(event._id);
    setEventDetail(event);
  };

  const selectAdminRow = (selectRow, id) => {
    // check if activity is already added

    const isAdminAlreadyAdded = adminId.includes(selectRow._id);

    if (isAdminAlreadyAdded) {
      toast.error("Admin already added");
      return;
    }

    closeModalAdmin();
    let arr = [],
      cover,
      label,
      title,
      description;
    title = selectRow?.first_name + " " + selectRow?.last_name;
    cover = selectRow?.image;
    label = "";

    arr = { cover, label, title };
    setAdminPreview([...adminPreview, arr]);
    setAdminId([...adminId, selectRow._id]);
  };

  const editEvent = (event) => {
    setEventWindow("update");
    setSelectedEvent(event._id);

    const allAdmin = event.admins.map((item) => {
      let arr = [],
        cover,
        label,
        title,
        description;
      title = item?.first_name + " " + item?.last_name;
      cover = item?.image;

      label = "";

      arr = { cover, label, title };
      return arr;
    });

    setAdminPreview(allAdmin);
    setAdminId(event.admins.map((item) => item._id));

    const packages = event.pack.map((item) => {
      return {
        ...item,
        saved: true,
      };
    });

    setPackages(packages);

    if (event?.reservation_time) {
      const reservationDate = new Date(event.reservation_time);
      const formattedDate = `${reservationDate.getFullYear()}-${(
        reservationDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${reservationDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${reservationDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${reservationDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      event.reservation_time = formattedDate;
    }

    if (event?.event_time) {
      const startDate = new Date(event.event_time);
      const formattedDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${startDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${startDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      event.event_time = formattedDate;
    }

    setPreviewImage(event.image);
    setVenueId(event?.venueId);
    setVenueText(event?.venueName);
    setVenueImage(event?.logo);
    setEventData(event);
  };

  function activeFormatterRating(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function CreatedBy(cellContent, row) {
    let created_by = row.userName;
    if (row.user_type === "org") {
      created_by = row.orgName;
    }
    return (
      <React.Fragment>
        <div>
          <p>{created_by} </p>
        </div>
      </React.Fragment>
    );
  }
  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  function Eventtime(cellContent, row) {
    let event_time = row.event_time;
    return (
      <React.Fragment>
        <div>
          <p>{moment(event_time).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function Reservationtime(cellContent, row) {
    let reservation_time = row.reservation_time;
    return (
      <React.Fragment>
        <div>
          <p>{moment(reservation_time).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function iconFormatterEvent(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            onClick={() => showInvitelist(row)}
            className=" btn btn-primary text-light"
          >
            <i className="fal fa-eye text-light"></i>
          </button>
          <button
            type="button"
            onClick={() => editEvent(row)}
            className=" btn btn-info  mx-2"
          >
            <i class="fal fa-pencil  fs-5"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() =>
              handlerdelete(
                row._id,
                "event/remove",
                { event_id: row._id },
                "events"
              )
            }
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  function iconFormatterRating(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            className=" btn btn-info  mx-2"
            // onClick={toggleReplyModel}
            onClick={() => handleOpenReview(row)}
          >
            {/* <Link
            to={{
              pathname: `/addyachtratingreply/${row._id}`,
              state: { row },
            }}
          > */}
            <i class="fal fa-reply fs-5 text-light"></i>
            {/* </Link> */}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() =>
              handlerdelete(
                row._id,
                "review/delete",
                { id: row._id },
                "event_ratings"
              )
            }
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }
  const handleChangeEvent = (event) => {
    setSizePerPageEvent(event.target.value);
  };
  const handlesortingEvent = (event) => {
    setsortEvent(event.target.value);
  };
  const handlesortOrderEvent = (event) => {
    setsortOrderEvent(event.target.value);
  };
  const handleReloadEvent = () => {
    window.location.reload();
    // eventManagment();    
  };

  const selectRowEvent = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItemsEvent([...selectedItemsEvent, row._id]);
      } else {
        setselectedItemsEvent(selectedItemsEvent.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItemsEvent([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItemsEvent(ids);
    },
  };

  function starFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <StarComponent count={row?.stars} />
        </div>
      </React.Fragment>
    );
  }

  const rowClassesEvent = (row, rowIndex) => {
    return selectedItemsEvent.includes(row._id) ? "selected-row-class" : "";
  };
  const handleChangeRating = (event) => {
    setSizePerPageRating(event.target.value);
  };
  const handlesortingRating = (event) => {
    setsortRating(event.target.value);
  };
  const handlesortOrderRating = (event) => {
    setsortOrderRating(event.target.value);
  };
  const handleReloadRating = () => {
    window.location.reload();
  };

  const handleTableChangeRating = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPageRating) {
        setCurrentPageRating(page);
      }
    }
  };
  const handleTableChangeEvent = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPageEvent) {
        setCurrentPageEvent(page);
      }
    }
  };

  const rowClassesRating = (row, rowIndex) => {
    return selectedItemsRating.includes(row._id) ? "selected-row-class" : "";
  };

  const selectRowRating = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItemsRating([...selectedItemsRating, row._id]);
      } else {
        setselectedItemsRating(
          selectedItemsRating.filter((x) => x !== row._id)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItemsRating([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItemsRating(ids);
    },
  };

  const handlerdeleteMultipleRating = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`review/delete`, {
          ids: ids,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          eventRatingManagment();
          setselectedItemsRating([]);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdeleteMultipleEvent = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`event/remove/ids`, {
          event_ids: ids,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          eventManagment();
          setselectedItemsEvent([]);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const addRow = () => {
    setPackages([
      ...packages,
      {
        title: "",
        discount: "",
        amount: 0,
        qty: 0,
        isFeatured: false,
        saved: false,
      },
    ]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };
  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const columnsEvent = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    // {
    //   text: "id",
    //   dataField: "_id",
    //   sort: true,
    // },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
    },

    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "event_time",
      text: "Event Time",
      formatter: Eventtime,
      sort: false,
    },
    {
      dataField: "reservation_time",
      text: "Reservation Time",
      formatter: Reservationtime,
      sort: false,
    },

    {
      dataField: "venue_type",
      text: "Venue Type",
      sort: false,
    },

    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatterEvent,
      sort: false,
    },
  ];
  const columnsRating = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
    },
    {
      dataField: "userName",
      text: "User",
      sort: false,
    },

    {
      dataField: "stars",
      text: "Stars",
      formatter: starFormatter,
    },

    {
      dataField: "review",
      text: "Review",
      sort: false,
    },
    {
      dataField: "reply",
      text: "Reply",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatterRating,
      sort: false,
    },
  ];

  const { name, logo, cover, phone, email, website, createdAt, updatedAt } =
    organizerData;

  return (
    <>
      <Dialog
        open={openReview}
        onClose={handleCloseReview}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReview} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

      <Row>
        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Col md="3">
              <Row>
                <Col md="12">
                  <Card
                    className="custom-card"
                    style={{ width: "480px", height: "480px" }}
                  >
                    <div
                      className="cover-image"
                      style={{
                        backgroundImage: `url(${cover})`,
                        width: "480px",
                        height: "480px",
                      }}
                      onClick={() => showLightBox(cover)}
                    ></div>
                    <div className="profile-image">
                      <img
                        src={logo}
                        onClick={() => showLightBox(logo)}
                        alt="Image Not Available"
                      />
                    </div>

                    <div className="row my-2 py-2">
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "30px" }}
                      >
                        <p style={{ visibility: "hidden" }}>
                          Description:
                          <span> {organizerData?.description}</span>
                        </p>
                        <p style={{ visibility: "hidden" }}>
                          Description:
                          <span> {organizerData?.description}</span>
                        </p>
                        <div className="fst">
                          <h3> {name} </h3>
                        </div>
                        <div className="row">
                          <div className="col-md-12 fst py-1"> </div>
                          <div className="col-md-12 fst py-1">
                            <p>
                              phone: <span> {phone}</span>
                            </p>
                          </div>
                          <div className="col-md-12 fst py-1">
                            <p>
                              Email: <span> {email}</span>
                            </p>
                          </div>
                          <div className="col-md-12 fst py-1">
                            <p>
                              Website:
                              <span>
                                <a href="{website}" _blank>
                                  {website}
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md="9">
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#dashboard" data-toggle="tab">
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="#events" data-toggle="tab">
                      Events
                    </a>
                  </li>
                  <li>
                    <a href="#follows" data-toggle="tab">
                      Followers
                    </a>
                  </li>
                  <li className="">
                    <a href="#reviews" data-toggle="tab">
                      Reviews
                    </a>
                  </li>
                  <li>
                    <a href="#gallery" data-toggle="tab">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="#profile" data-toggle="tab">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a href="#setting" data-toggle="tab">
                      Setting
                    </a>
                  </li>
                </ul>
              </div>
              <br></br>

              <div
                className="tab-content clearfix"
                style={{ marginLeft: "15px" }}
              >
                <div className="tab-pane padded-tab " id="profile">
                  <Col lg={12}>
                    <Card>
                      {loading ? (
                        <CardBody style={{ height: "100px" }}>
                          <Spinner
                            color="info"
                            style={{
                              height: "4rem",
                              width: "4rem",
                              position: "absolute",
                              left: "50%",
                            }}
                          >
                            Loading...
                          </Spinner>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <h4> Save Profile </h4>
                          <Form onSubmit={Updateeventorganizer}>
                            <Row>
                              <Col lg={12} className="ms-lg-auto">
                                <div className="mt-5 mt-lg-4">
                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateValue("name", e.target.value)
                                        }
                                        value={organizerData?.name}
                                        name="name"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Description
                                    </label>
                                    <div className="col-sm-9">
                                      <textarea
                                        type="text"
                                        onChange={(e) =>
                                          updateValue(
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        value={organizerData?.description}
                                        name="description"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Phone
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateValue("phone", e.target.value)
                                        }
                                        value={organizerData?.phone}
                                        name="phone"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Email
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="email"
                                        onChange={(e) =>
                                          updateValue("email", e.target.value)
                                        }
                                        value={organizerData?.email}
                                        name="email"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Website
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateValue("website", e.target.value)
                                        }
                                        value={organizerData?.website}
                                        name="website"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-password-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Logo
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="file"
                                        // onChange={(e) =>
                                        //   setSelectedLogo(e.target.files[0])
                                        // }
                                        onChange={handleLogoChange}
                                        name="logo"
                                        className="form-control"
                                        id="horizontal-password-input"
                                      />
                                      <br />

                                      {previewLogo && selectedLogo ? (
                                        <ReactCrop
                                          crop={crop}
                                          src={previewLogo}
                                          onChange={(newCrop) =>
                                            setCrop(newCrop)
                                          }
                                          onComplete={handleCropComplete}
                                          style={{
                                            maxWidth: "720px",
                                            maxHeight: "600px",
                                          }}
                                        >
                                          <img ref={imgRef} src={previewLogo} />
                                        </ReactCrop>
                                      ) : (
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                          src={previewLogo}
                                        />
                                      )}
                                      {completedCrop && (
                                        <div>
                                          <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                              border: "1px solid black",
                                              objectFit: "contain",
                                              width: completedCrop.width,
                                              height: completedCrop.height,
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-password-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Cover
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          setSelectedCover(e.target.files[0])
                                        }
                                        name="cover"
                                        className="form-control"
                                        id="horizontal-password-input"
                                      />
                                      <br />
                                      {previewCover && (
                                        <img
                                          src={previewCover}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="mt-5 mt-lg-4">
                                    <div className="row justify-content-end">
                                      <div className="col-sm-9">
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </div>

                <div className="tab-pane padded-tab " id="setting">
                  <Col lg={12}>
                    <Card>
                      {loading ? (
                        <CardBody style={{ height: "100px" }}>
                          <Spinner
                            color="info"
                            style={{
                              height: "4rem",
                              width: "4rem",
                              position: "absolute",
                              left: "50%",
                            }}
                          >
                            Loading...
                          </Spinner>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <h4> Change Password </h4>
                          <Form onSubmit={updateOrgPassword}>
                            <Row>
                              <Col lg={12} className="ms-lg-auto">
                                <div className="mt-5 mt-lg-4">
                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Old Password
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="password"
                                        onChange={(e) =>
                                          updatePassword(
                                            "old_password",
                                            e.target.value
                                          )
                                        }
                                        name="name"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      New Password
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="password"
                                        onChange={(e) =>
                                          updatePassword(
                                            "new_password",
                                            e.target.value
                                          )
                                        }
                                        name="phone"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Confirm Password
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="password"
                                        onChange={(e) =>
                                          updatePassword(
                                            "confirm_password",
                                            e.target.value
                                          )
                                        }
                                        name="email"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="mt-5 mt-lg-4">
                                    <div className="row justify-content-end">
                                      <div className="col-sm-9">
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                          >
                                            Update Password
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </div>

                <div className="tab-pane padded-tab active" id="dashboard">
                  <Row>
                    <Col xl={3}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4 mb-4">Events</CardTitle>

                          <Row className="text-center mt-4">
                            <div className="col-12">
                              <a href="#events" data-toggle="tab">
                                <h5 className="font-size-20">
                                  {organizerData?.events?.length}
                                </h5>
                                <p className="text-muted">Events</p>
                              </a>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xl={3}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4 mb-4">Followers</CardTitle>

                          <Row className="text-center mt-4">
                            <div className="col-12">
                              <a href="#follows" data-toggle="tab">
                                <h5 className="font-size-20">
                                  {organizerData?.followers?.length
                                    ? organizerData?.followers?.length
                                    : 0}
                                </h5>
                                <p className="text-muted">Followers</p>
                              </a>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xl={3}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4 mb-4">Reviews</CardTitle>

                          <Row className="text-center mt-4">
                            <div className="col-12">
                              <a href="#reviews" data-toggle="tab">
                                <h5 className="font-size-20">
                                  {organizerData?.reviews?.length}
                                </h5>
                                <p className="text-muted">Reviews</p>
                              </a>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={3}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4 mb-4">Gallery</CardTitle>

                          <Row className="text-center mt-4">
                            <div className="col-12">
                              <a href="#gallery" data-toggle="tab">
                                <h5 className="font-size-20">
                                  {organizerData?.galleries?.length}
                                </h5>
                                <p className="text-muted">Gallery</p>
                              </a>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className="tab-pane padded-tab" id="reviews">
                  {organizerData?.ratingSummary && (
                    <RatingsComponent
                      ratingData={organizerData?.ratingSummary}
                    />
                  )}

                  <Col className="col-12">
                    <Card>
                      {loading ? (
                        <CardBody style={{ height: "100px" }}>
                          <Spinner
                            color="info"
                            style={{
                              height: "4rem",
                              width: "4rem",
                              position: "absolute",
                              left: "50%",
                            }}
                          >
                            Loading...
                          </Spinner>
                        </CardBody>
                      ) : (
                        <CardBody className="table-shorting">
                          {
                            <PaginationProvider
                              pagination={paginationFactory({
                                page: currentFPageRating,
                                sizePerPage: sizePerPageOrgRating,
                                totalSize: totalPageRating,
                                custom: true,
                              })}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <>
                                  <ToolkitProvider
                                    keyField="_id"
                                    data={eventRatingdata}
                                    columns={columnsRating}
                                  >
                                    {(props) => (
                                      <Row>
                                        <Col className="col-lg-12 d-flex mb-3">
                                          <label className="mb-0 text-label">
                                            Page Size
                                          </label>
                                          <select
                                            value={sizePerPageOrgRating}
                                            onChange={handleChangeRating}
                                            className="form-select"
                                            style={{ width: "7%" }}
                                          >
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                          </select>

                                          <label className="mb-0 text-label ms-3">
                                            Sort By:
                                          </label>
                                          <select
                                            value={sortRating}
                                            onChange={handlesortingRating}
                                            className="form-select"
                                            style={{ width: "25%" }}
                                          >
                                            <option value="stars">Stars</option>
                                            <option value="review">
                                              Review
                                            </option>
                                            <option value="reply">Reply</option>
                                            <option value="createdAt">
                                              Created At
                                            </option>
                                          </select>
                                          <label className="mb-0 text-label ms-3">
                                            Sort Order:
                                          </label>
                                          <select
                                            value={sortOrderRating}
                                            onChange={handlesortOrderRating}
                                            className="form-select"
                                            style={{ width: "25%" }}
                                          >
                                            <option value="asc">
                                              Ascending
                                            </option>
                                            <option value="desc">
                                              Descending
                                            </option>
                                          </select>
                                          <div className="filter-item">
                                            {selectedItemsRating.length > 0 && (
                                              <button
                                                className="btn btn-danger mx-2"
                                                onClick={() => {
                                                  handlerdeleteMultipleRating(
                                                    selectedItemsRating
                                                  );
                                                }}
                                              >
                                                Delete Selected
                                              </button>
                                            )}
                                            <input
                                              type="search"
                                              placeholder="Search"
                                              value={searchTextRating}
                                              class="form-control"
                                              onChange={(e) =>
                                                setSearchTextRating(
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "30%" }}
                                            />
                                            <button
                                              onClick={() => {
                                                eventRatingManagment();
                                              }}
                                              type="button"
                                              className="btn btn-primary mx-2"
                                            >
                                              Search
                                            </button>
                                            {/* <button
                                            type="button"
                                            onClick={handleReloadRating}
                                            className="btn btn-danger mx-2"
                                          >
                                            Reset
                                          </button> */}

                                            <button
                                              type="button"
                                              onClick={toggleModelRating}
                                              className="btn btn-info"
                                            >
                                              Filter
                                            </button>
                                          </div>
                                        </Col>

                                        <Col className="col-lg-12 text-end">
                                          <BootstrapTable
                                            onTableChange={
                                              handleTableChangeRating
                                            }
                                            remote={true}
                                            selectRow={selectRowRating}
                                            rowClasses={rowClassesRating}
                                            // classes="table-custom-striped"
                                            classes="table-custom-border"
                                            {...props.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                  </ToolkitProvider>

                                  <Row>
                                    <Col className="col-lg-12 text-end">
                                      <div className="text-md-right">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </PaginationProvider>
                          }
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </div>

                <div className="tab-pane padded-tab" id="follows">
                  <FollowListTable eventId={selectedEvent} />
                </div>

                <div className="tab-pane padded-tab" id="gallery">
                  {galleryWindow == "add" && (
                    <>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div class="col-md-2 offset-md-10 text-end">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => setGalleryWindow("list")}
                          >
                            <i class="fas fa-chevron-left"></i>Back
                          </button>
                        </div>
                      </div>

                      <Card>
                        <CardBody>
                          <h4 className="card-title"> Create Event Gallery </h4>
                          <Form onSubmit={Creategallery}>
                            <Row>
                              <Col lg={12} className="ms-lg-auto">
                                <div className="mt-5 mt-lg-4">
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-password-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Image
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          setSelectedImage(e.target.files[0])
                                        }
                                        name="mediaUrl"
                                        className="form-control"
                                        id="horizontal-password-input"
                                        required="required"
                                      />
                                      <br />
                                      {previewImage && (
                                        <img
                                          src={previewImage}
                                          onClick={() =>
                                            showLightBox2(previewImage)
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="mt-5 mt-lg-4">
                                    <div className="row justify-content-end">
                                      <div className="col-sm-9">
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  )}

                  {galleryWindow == "list" && (
                    <>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div class="col-md-2 offset-md-10 text-end">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => setGalleryWindow("add")}
                          >
                            <i class="fas fa-plus"></i> Add Gallery
                          </button>
                        </div>
                      </div>

                      <Row>
                        {organizerData?.galleries?.map((gallery, index) => (
                          <Col
                            key={gallery}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={3}
                          >
                            <Card>
                              <CardBody>
                                <img
                                  src={gallery}
                                  alt="gallery image "
                                  className="mb-3"
                                  style={{
                                    height: "200px",
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                  onClick={() => showLightBox(gallery)}
                                />
                                <div className="d-flex justify-content-between align-items-center">
                                  <RBButton
                                    color="danger"
                                    onClick={() =>
                                      handlerdelete(
                                        gallery,
                                        "event/org/gallery/delete",
                                        { image: gallery },
                                        "galleries"
                                      )
                                    }
                                  >
                                    <i className="fal fa-trash fs-5"></i>
                                  </RBButton>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </div>

                <div className="tab-pane padded-tab" id="events">
                  {eventWindow == "add" && (
                    <>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div class="col-md-2 offset-md-10 text-end">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => setEventWindow("list")}
                          >
                            <i class="fas fa-chevron-left"></i> Back
                          </button>
                        </div>
                      </div>

                      <Card>
                        <CardBody>
                          <h4 className="card-title">Create Events </h4>
                          <Form onSubmit={createEventsData}>
                            <Row>
                              <Col lg={12} className="ms-lg-auto">
                                <div className="mt-5 mt-lg-4">
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Title
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.title}
                                        name="title"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Description
                                    </label>
                                    <div className="col-sm-9">
                                      <textarea
                                        type="text"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.description}
                                        name="description"
                                        className="form-control"
                                        id="horizontal-firstname-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-password-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Image
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          setSelectedImage(e.target.files[0])
                                        }
                                        name="image"
                                        className="form-control"
                                        id="horizontal-password-input"
                                      />
                                      <br />
                                      {previewImage && (
                                        <img
                                          src={previewImage}
                                          onClick={() =>
                                            showLightBox2(previewImage)
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Type
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        value={eventData?.type}
                                        onChange={(e) =>
                                          updateEventValue(
                                            "type",
                                            e.target.value
                                          )
                                        }
                                        disabled={disableType}
                                        className="form-select"
                                        name="type"
                                      >
                                        <option value="">Select</option>
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Venue Type
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        onChange={(e) =>
                                          updateEventValue(
                                            "venue_type",
                                            e.target.value
                                          )
                                        }
                                        value={eventData?.venue_type}
                                        name="venue_type"
                                        className="form-select"
                                      >
                                        <option value="">
                                          Select Venue Type
                                        </option>
                                        <option value="venue">Venue</option>
                                        <option value="custom">
                                          Custom Venue
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  {eventData?.venue_type === "venue" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Venue
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleModel()}
                                      >
                                        {venueText ? (
                                          //   <div
                                          //     style={{
                                          //       display: "flex",
                                          //       border: "1px solid #ced4da",
                                          //       padding:
                                          //         " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          //       borderRadius: "0.25rem",
                                          //       lineHeight: 1.5,
                                          //     }}
                                          //   >
                                          //     <div style={{ minWidth: "100%" }}>
                                          //       <a
                                          //         href="javascript:void(0)"
                                          //         style={{
                                          //           textDecoration: "none",
                                          //           color: "#5b626b",
                                          //           fontSize: "0.8125rem",
                                          //           fontWeight: 400,
                                          //         }}
                                          //       >
                                          //         {venueText}
                                          //       </a>
                                          //     </div>
                                          //     <div>
                                          //     <a
                                          //     onClick={() => toggleModel()}
                                          //     href="javascript:void(0)"
                                          //      >
                                          //     <i
                                          //         style={{ color: "#5b626b" }}
                                          //         class="fa fa-plus"
                                          //       ></i>
                                          // </a>
                                          //     </div>
                                          //   </div>
                                          <div class="input-group">
                                            <button
                                              class="btn"
                                              style={{
                                                border: "1px solid #ced4da",
                                                background: "#e60489",
                                                color: "#ffff",
                                              }}
                                              type="button"
                                              id="chooseVenueBtn"
                                              onclick={() => toggleModel()}
                                            >
                                              Choose Venue
                                            </button>
                                            <div
                                              style={{
                                                border: "1px solid #ced4da",
                                                width: "16px",
                                              }}
                                            ></div>
                                            <img
                                              src={venueImage}
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                border: "1px solid #ced4da",
                                                borderRight: "none",
                                                borderLeft: "none",
                                              }}
                                            ></img>
                                            <input
                                              type="text"
                                              style={{ borderLeft: "none" }}
                                              class="form-control"
                                              readonly
                                              value={venueText}
                                              id="selectedVenue"
                                              placeholder="No venue chosen"
                                            />
                                          </div>
                                        ) : (
                                          // <div
                                          //   style={{
                                          //     display: "flex",
                                          //     border: "1px solid #ced4da",
                                          //     padding:
                                          //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          //     borderRadius: "0.25rem",
                                          //     lineHeight: 1.5,
                                          //   }}
                                          // >
                                          //   <div style={{ minWidth: "100%" }}>
                                          //     <a
                                          //       href="javascript:void(0)"
                                          //       style={{
                                          //         textDecoration: "none",
                                          //         color: "#5b626b",
                                          //         fontSize: "0.8125rem",
                                          //         fontWeight: 400,
                                          //       }}
                                          //     >
                                          //       Select Venue
                                          //     </a>
                                          //   </div>
                                          //   <div>
                                          //     <a
                                          //       onClick={() => toggleModel()}
                                          //       href="javascript:void(0)"
                                          //     >
                                          //       <i
                                          //         style={{ color: "#5b626b" }}
                                          //         class="fa fa-plus"
                                          //       ></i>
                                          //     </a>
                                          //   </div>
                                          // </div>
                                          <div class="input-group">
                                            <button
                                              class="btn"
                                              style={{
                                                border: "1px solid #ced4da",
                                                background: "#e60489",
                                                color: "#ffff",
                                              }}
                                              type="button"
                                              id="chooseVenueBtn"
                                              onclick={() => toggleModel()}
                                            >
                                              Choose Venue
                                            </button>
                                            <input
                                              type="text"
                                              class="form-control"
                                              readonly
                                              id="selectedVenue"
                                              placeholder="No venue chosen"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {eventData?.type === "offer" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Offer
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleOfferModel()}
                                      >
                                        {offerText ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {offerText}
                                              </a>
                                            </div>
                                            <div></div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Select Offer
                                              </a>
                                            </div>
                                            <div>
                                              <a
                                                onClick={() =>
                                                  toggleOfferModel()
                                                }
                                                href="javascript:void(0)"
                                              >
                                                <i
                                                  style={{ color: "#5b626b" }}
                                                  class="fa fa-plus"
                                                ></i>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {eventData?.type === "category" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Category
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleCategoryModel()}
                                      >
                                        {categoryText ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {categoryText}
                                              </a>
                                            </div>
                                            <div></div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Select Category
                                              </a>
                                            </div>
                                            <div>
                                              <a
                                                onClick={() =>
                                                  toggleCategoryModel()
                                                }
                                                href="javascript:void(0)"
                                              >
                                                <i
                                                  style={{ color: "#5b626b" }}
                                                  class="fa fa-plus"
                                                ></i>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Event Time
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="datetime-local"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "event_time",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.event_time}
                                        name="event_time"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Reservation Time
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="datetime-local"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "reservation_time",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={
                                          eventData?.reservation_time
                                        }
                                        name="reservation_time"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label">
                                      Assign Admin
                                    </label>

                                    {adminPreview.map((form, index) => (
                                      <div className="col-lg-3 mb-3">
                                        <div class="admin-card">
                                          <div class="admin-card-image">
                                            <img
                                              src={form?.cover}
                                              alt="Avatar"
                                            />
                                          </div>

                                          <div class="admin-card-content">
                                            <h4>{form?.title}</h4>
                                          </div>
                                        </div>

                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveAdminPreview(index)
                                            }
                                            className="btn-remove"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="col-lg-3">
                                      <button
                                        type="button"
                                        onClick={toggleModelAdmin}
                                        className="modal-custom-button mt-3 mb-3"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Enter Packages Discounts
                                    </label>
                                    <div className="col-sm-9">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Package Title</th>
                                            <th>Package Amount</th>
                                            <th>Package Discount</th>
                                            <th>Qty</th>
                                            <th>Is Featured</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {packages.map((item, index) => (
                                            <tr key={index}>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.title}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "title",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  value={`${item?.amount}`}
                                                  onChange={(e) => {
                                                    updatePackageField(
                                                      index,
                                                      "amount",
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.discount}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "discount",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.qty}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "qty",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  checked={item.isFeatured}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "isFeatured",
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {!item.saved ? (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      color: "white",
                                                      backgroundColor: "blue",
                                                      border: "none",
                                                      padding: "10px 20px",
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() =>
                                                      savePackage(index)
                                                    }
                                                    disabled={item.saved}
                                                  >
                                                    Save
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      color: "white",
                                                      backgroundColor: "red",
                                                      border: "none",
                                                      padding: "10px 20px",
                                                    }}
                                                    onClick={() =>
                                                      deletePackage(index)
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td colSpan={12}>
                                              <button
                                                type="button"
                                                style={{
                                                  color: "white",
                                                  backgroundColor: "green",
                                                  border: "none",
                                                  padding: "10px 20px",
                                                }}
                                                onClick={addRow}
                                              >
                                                Add Row
                                              </button>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                  </div>

                                  {/* <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Status
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        value={eventData?.status}
                                        onChange={(e) =>
                                          updateEventValue(
                                            "status",
                                            Number(e.target.value)
                                          )
                                        }
                                        className="form-select"
                                      >
                                        <option value="">Select Status</option>
                                        <option value="true">Active</option>
                                        <option value="false">Inactive</option>
                                      </select>
                                    </div>
                                  </div> */}

                                  <div className="mt-5 mt-lg-4">
                                    <div className="row justify-content-end">
                                      <div className="col-sm-9">
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col lg={6} className="ms-lg-auto"></Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  )}

                  {eventWindow == "update" && (
                    <>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div class="col-md-2 offset-md-10 text-end">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => setEventWindow("list")}
                          >
                            <i class="fas fa-chevron-left"></i> Back
                          </button>
                        </div>
                      </div>

                      <Card>
                        <CardBody>
                          <h4 className="card-title">Update Event </h4>
                          <Form onSubmit={updateEventsData}>
                            <Row>
                              <Col lg={12} className="ms-lg-auto">
                                <div className="mt-5 mt-lg-4">
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Title
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.title}
                                        name="title"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Description
                                    </label>
                                    <div className="col-sm-9">
                                      <textarea
                                        type="text"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.description}
                                        name="description"
                                        className="form-control"
                                        id="horizontal-firstname-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-password-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Image
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          setSelectedImage(e.target.files[0])
                                        }
                                        name="image"
                                        className="form-control"
                                        id="horizontal-password-input"
                                      />
                                      <br />
                                      {previewImage && (
                                        <img
                                          src={previewImage}
                                          onClick={() =>
                                            showLightBox2(previewImage)
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Type
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        value={eventData?.type}
                                        onChange={(e) =>
                                          updateEventValue(
                                            "type",
                                            e.target.value
                                          )
                                        }
                                        disabled={disableType}
                                        className="form-select"
                                        name="type"
                                      >
                                        <option value="">Select</option>
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Venue Type
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        onChange={(e) =>
                                          updateEventValue(
                                            "venue_type",
                                            e.target.value
                                          )
                                        }
                                        value={eventData?.venue_type}
                                        name="venue_type"
                                        className="form-select"
                                      >
                                        <option value="">
                                          Select Venue Type
                                        </option>
                                        <option value="venue">Venue</option>
                                        <option value="custom">
                                          Custom Venue
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  {eventData?.venue_type === "venue" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Venue
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleModel()}
                                      >
                                        {venueText ? (
                                          // <div
                                          //   style={{
                                          //     display: "flex",
                                          //     border: "1px solid #ced4da",
                                          //     padding:
                                          //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          //     borderRadius: "0.25rem",
                                          //     lineHeight: 1.5,
                                          //   }}
                                          // >
                                          //   <div style={{ minWidth: "100%" }}>
                                          //     <a
                                          //       href="javascript:void(0)"
                                          //       style={{
                                          //         textDecoration: "none",
                                          //         color: "#5b626b",
                                          //         fontSize: "0.8125rem",
                                          //         fontWeight: 400,
                                          //       }}
                                          //     >
                                          //       {venueText}
                                          //     </a>
                                          //   </div>
                                          //   <div>
                                          //     <a
                                          //       onClick={() => toggleModel()}
                                          //       href="javascript:void(0)"
                                          //     >
                                          //       <i

                                          //         style={{ color: "#5b626b" }}
                                          //         class="fa fa-plus"
                                          //       ></i>
                                          //     </a>
                                          //   </div>
                                          // </div>
                                          <div class="input-group">
                                            <button
                                              class="btn"
                                              style={{
                                                border: "1px solid #ced4da",
                                                background: "#e60489",
                                                color: "#ffff",
                                              }}
                                              type="button"
                                              id="chooseVenueBtn"
                                              onclick={() => toggleModel()}
                                            >
                                              Choose Venue
                                            </button>
                                            <div
                                              style={{
                                                border: "1px solid #ced4da",
                                                width: "16px",
                                              }}
                                            ></div>
                                            <img
                                              src={venueImage}
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                border: "1px solid #ced4da",
                                                borderRight: "none",
                                                borderLeft: "none",
                                              }}
                                            ></img>
                                            <input
                                              type="text"
                                              style={{ borderLeft: "none" }}
                                              class="form-control"
                                              readonly
                                              value={venueText}
                                              id="selectedVenue"
                                              placeholder="No venue chosen"
                                            />
                                          </div>
                                        ) : (
                                          // <div
                                          //   style={{
                                          //     display: "flex",
                                          //     border: "1px solid #ced4da",
                                          //     padding:
                                          //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          //     borderRadius: "0.25rem",
                                          //     lineHeight: 1.5,
                                          //   }}
                                          // >
                                          //   <div style={{ minWidth: "100%" }}>
                                          //     <a
                                          //       href="javascript:void(0)"
                                          //       style={{
                                          //         textDecoration: "none",
                                          //         color: "#5b626b",
                                          //         fontSize: "0.8125rem",
                                          //         fontWeight: 400,
                                          //       }}
                                          //     >
                                          //       Select Venue
                                          //     </a>
                                          //   </div>
                                          //   <div>
                                          //     <a
                                          //       onClick={() => toggleModel()}
                                          //       href="javascript:void(0)"
                                          //     >
                                          //       <i
                                          //         style={{ color: "#5b626b" }}
                                          //         class="fa fa-plus"
                                          //       ></i>
                                          //     </a>
                                          //   </div>
                                          // </div>
                                          <div class="input-group">
                                            <button
                                              class="btn"
                                              style={{
                                                border: "1px solid #ced4da",
                                                background: "#e60489",
                                                color: "#ffff",
                                              }}
                                              type="button"
                                              id="chooseVenueBtn"
                                              onclick={() => toggleModel()}
                                            >
                                              Choose Venue
                                            </button>
                                            <input
                                              type="text"
                                              class="form-control"
                                              readonly
                                              id="selectedVenue"
                                              placeholder="No venue chosen"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {eventData?.type === "offer" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Offer
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleOfferModel()}
                                      >
                                        {offerText ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {offerText}
                                              </a>
                                            </div>
                                            <div></div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Select Offer
                                              </a>
                                            </div>
                                            <div>
                                              <a
                                                onClick={() =>
                                                  toggleOfferModel()
                                                }
                                                href="javascript:void(0)"
                                              >
                                                <i
                                                  style={{ color: "#5b626b" }}
                                                  class="fa fa-plus"
                                                ></i>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {eventData?.type === "category" && (
                                    <div className="row mb-4">
                                      <label className="col-sm-3 col-form-label">
                                        Select Category
                                      </label>

                                      <div
                                        className="col-sm-9"
                                        onClick={() => toggleCategoryModel()}
                                      >
                                        {categoryText ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {categoryText}
                                              </a>
                                            </div>
                                            <div></div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              border: "1px solid #ced4da",
                                              padding:
                                                " 0.375rem 1.75rem 0.375rem 0.75rem",
                                              borderRadius: "0.25rem",
                                              lineHeight: 1.5,
                                            }}
                                          >
                                            <div style={{ minWidth: "100%" }}>
                                              <a
                                                href="javascript:void(0)"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#5b626b",
                                                  fontSize: "0.8125rem",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Select Category
                                              </a>
                                            </div>
                                            <div>
                                              <a
                                                onClick={() =>
                                                  toggleCategoryModel()
                                                }
                                                href="javascript:void(0)"
                                              >
                                                <i
                                                  style={{ color: "#5b626b" }}
                                                  class="fa fa-plus"
                                                ></i>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Event Time
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="datetime-local"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "event_time",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={eventData?.event_time}
                                        name="event_time"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <label
                                      htmlFor="horizontal-firstname-input"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Reservation Time
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="datetime-local"
                                        onChange={(e) =>
                                          updateEventValue(
                                            "reservation_time",
                                            e.target.value
                                          )
                                        }
                                        defaultValue={
                                          eventData?.reservation_time
                                        }
                                        name="reservation_time"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label">
                                      Assign Admin
                                    </label>

                                    {adminPreview.map((form, index) => (
                                      <div className="col-lg-3 mb-3">
                                        <div class="admin-card">
                                          <div class="admin-card-image">
                                            <img
                                              src={form?.cover}
                                              alt="Avatar"
                                            />
                                          </div>

                                          <div class="admin-card-content">
                                            <h4>{form?.title}</h4>
                                          </div>
                                        </div>

                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveAdminPreview(index)
                                            }
                                            className="btn-remove"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="col-lg-3 mb-3">
                                      <button
                                        type="button"
                                        onClick={toggleModelAdmin}
                                        className="modal-custom-button mt-3 mb-3"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label">
                                      Enter Packages Discounts
                                    </label>
                                    <div className="col-sm-9">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Package Title</th>
                                            <th>Package Amount</th>
                                            <th>Package Discount</th>
                                            <th>Qty</th>
                                            <th>Is Featured</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {packages.map((item, index) => (
                                            <tr key={index}>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.title}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "title",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  value={`${item?.amount}`}
                                                  onChange={(e) => {
                                                    updatePackageField(
                                                      index,
                                                      "amount",
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.discount}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "discount",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  value={item?.qty}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "qty",
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  checked={item.isFeatured}
                                                  onChange={(e) =>
                                                    updatePackageField(
                                                      index,
                                                      "isFeatured",
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {!item.saved ? (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      color: "white",
                                                      backgroundColor: "blue",
                                                      border: "none",
                                                      padding: "10px 20px",
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() =>
                                                      savePackage(index)
                                                    }
                                                    disabled={item.saved}
                                                  >
                                                    Save
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      color: "white",
                                                      backgroundColor: "red",
                                                      border: "none",
                                                      padding: "10px 20px",
                                                    }}
                                                    onClick={() =>
                                                      deletePackage(index)
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td colSpan={12}>
                                              <button
                                                type="button"
                                                style={{
                                                  color: "white",
                                                  backgroundColor: "green",
                                                  border: "none",
                                                  padding: "10px 20px",
                                                }}
                                                onClick={addRow}
                                              >
                                                Add Row
                                              </button>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                  </div>

                                  <div className="mt-5 mt-lg-4">
                                    <div className="row justify-content-end">
                                      <div className="col-sm-9">
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-md"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col lg={6} className="ms-lg-auto"></Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  )}

                  {eventWindow == "list" && (
                    <>
                      <Col className="col-12">
                        <Card>
                          {loader ? (
                            <CardBody style={{ height: "100px" }}>
                              <Spinner
                                color="info"
                                style={{
                                  height: "4rem",
                                  width: "4rem",
                                  position: "absolute",
                                  left: "50%",
                                }}
                              >
                                Loading...
                              </Spinner>
                            </CardBody>
                          ) : (
                            <CardBody className="table-shorting">
                              {
                                <PaginationProvider
                                  pagination={paginationFactory({
                                    page: currentFPageEvent,
                                    sizePerPage: sizePerPageOrgEvent,
                                    totalSize: totalPageEvent,
                                    custom: true,
                                  })}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <>
                                      <ToolkitProvider
                                        keyField="_id"
                                        data={eventdata}
                                        columns={columnsEvent}
                                      >
                                        {(props) => (
                                          <Row>
                                            <Col className="col-lg-12 d-flex mb-3">
                                              <label className="mb-0 text-label">
                                                Page Size
                                              </label>

                                              <select
                                                value={sizePerPageOrgEvent}
                                                onChange={handleChangeEvent}
                                                className="form-select"
                                                style={{ width: "7%" }}
                                              >
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                              </select>

                                              <label className="mb-0 text-label ms-3">
                                                Sort By:
                                              </label>
                                              <select
                                                value={sortEvent}
                                                onChange={handlesortingEvent}
                                                className="form-select"
                                                style={{ width: "25%" }}
                                              >
                                                <option value="title">
                                                  Title
                                                </option>
                                                <option value="event_time">
                                                  Event Time
                                                </option>
                                                <option value="reservation_time">
                                                  Reservation Time
                                                </option>
                                                <option value="createdAt">
                                                  Created At
                                                </option>
                                              </select>
                                              <label className="mb-0 text-label ms-3">
                                                Sort Order:
                                              </label>
                                              <select
                                                value={sortOrderEvent}
                                                onChange={handlesortOrderEvent}
                                                className="form-select"
                                                style={{ width: "25%" }}
                                              >
                                                <option value="asc">
                                                  Ascending
                                                </option>
                                                <option value="desc">
                                                  Descending
                                                </option>
                                              </select>
                                              <div className="filter-item">
                                                {selectedItemsEvent.length >
                                                  0 && (
                                                  <button
                                                    className="btn btn-danger mx-2"
                                                    onClick={() => {
                                                      handlerdeleteMultipleEvent(
                                                        selectedItemsEvent
                                                      );
                                                    }}
                                                  >
                                                    Delete Selected
                                                  </button>
                                                )}
                                                <input
                                                  type="search"
                                                  placeholder="Search"
                                                  value={searchTextEvent}
                                                  class="form-control"
                                                  onChange={(e) =>
                                                    setSearchTextEvent(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{ width: "30%" }}
                                                />
                                                <button
                                                  onClick={() => {
                                                    eventManagment();
                                                  }}
                                                  type="button"
                                                  className="btn btn-primary mx-2"
                                                >
                                                  Search
                                                </button>

                                                <button
                                                  type="button"
                                                  onClick={toggleModelEvent}
                                                  className="btn btn-info"
                                                >
                                                  Filter
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary mx-2"
                                                  onClick={() =>
                                                    setEventWindow("add")
                                                  }
                                                >
                                                  <i class="fas fa-plus"></i>
                                                  Add Event
                                                </button>
                                              </div>
                                            </Col>

                                            <Col className="col-lg-12 text-end">
                                              <BootstrapTable
                                                onTableChange={
                                                  handleTableChangeEvent
                                                }
                                                remote={true}
                                                selectRow={selectRowEvent}
                                                rowClasses={rowClassesEvent}
                                                // classes="table-custom-striped"
                                                classes="table-custom-border"
                                                {...props.baseProps}
                                                {...paginationTableProps}
                                              />
                                            </Col>
                                          </Row>
                                        )}
                                      </ToolkitProvider>

                                      <Row>
                                        <Col className="col-lg-12 text-end">
                                          <div className="text-md-right">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </PaginationProvider>
                              }
                            </CardBody>
                          )}
                        </Card>
                      </Col>
                    </>
                  )}

                  {eventWindow == "invitelist" && (
                    <>
                      <div className="row" style={{ marginBottom: "20px" }}>
                        <div class="col-md-2 offset-md-10 text-end">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            onClick={() => setEventWindow("list")}
                          >
                            <i class="fas fa-chevron-left"></i> Back
                          </button>
                        </div>
                      </div>
                      <Card className="custom-card">
                        <div
                          className="cover-image"
                          style={{
                            backgroundImage: `url(${eventDetail?.image})`,
                          }}
                          onClick={() => showLightBox(cover)}
                        ></div>
                        {/* <div className="profile-image2">
                          <img
                            src={logo}
                            onClick={() => showLightBox(logo)}
                            alt="Image Not Available"
                          />
                        </div> */}

                        <div className="row my-2 py-2">
                          <div
                            className="userinfo col-md-12 "
                            style={{ paddingLeft: "20px" }}
                          >
                            <br></br>

                            <div className="fst">
                              <h3>{eventDetail?.title} </h3>
                            </div>

                            <div className="row">
                              <div className="col-md-4 fst py-1">
                                <p>
                                  Description:
                                  <span> {eventDetail?.description}</span>
                                </p>
                                <br />
                                <p>
                                  Reservation Time:
                                  <span> {eventDetail?.reservation_time}</span>
                                </p>
                              </div>
                              <div className="col-md-4 fst py-1">
                                <p>
                                  Type: <span> {eventDetail?.type}</span>
                                </p>
                                <br />
                                <p>
                                  Venue Name:
                                  <span> {eventDetail?.venueName}</span>
                                </p>
                              </div>
                              <div className="col-md-4 fst py-1">
                                <p>
                                  Event Time:
                                  <span> {eventDetail?.event_time}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Col md="12">
                        <div
                          id="exTab2"
                          className="twitter-like-tabs2"
                          style={{ background: "#ffff" }}
                        >
                          <ul
                            className="nav nav-pills"
                            style={{ width: "480px", display: "contents" }}
                          >
                            <li className="active">
                              <a
                                href="#all"
                                data-toggle="tab"
                                data-target="#all"
                                onClick={() => onTabChange("all")}
                              >
                                All
                              </a>
                            </li>

                            <li>
                              <a
                                href="#in"
                                data-toggle="tab"
                                data-target="#in"
                                onClick={() => onTabChange("in")}
                              >
                                In{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href="#out"
                                data-toggle="tab"
                                data-target="#out"
                                onClick={() => onTabChange("out")}
                              >
                                Out
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="tab-content clearfix">
                          <div className="tab-pane  padded-tab" id="all">
                            <InviteListTable
                              eventId={selectedEvent}
                              activeTab={activeTab}
                            />

                            {/* <InviteListTable eventId={selectedEvent} /> */}
                          </div>

                          <div className="tab-pane active padded-tab" id="in">
                            <InviteListTable
                              eventId={selectedEvent}
                              activeTab={activeTab}
                            />
                          </div>

                          <div className="tab-pane padded-tab" id="out">
                            <InviteListTable
                              eventId={selectedEvent}
                              activeTab={activeTab}
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>

      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      <FilterModal
        show={modelEvent}
        closeModal={closeModalEvent}
        components={componentsEvent}
      />
      <FilterModal
        show={modelRating}
        closeModal={closeModalRating}
        components={componentsRating}
      />
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <AdminModal
        show={modelAdmin}
        closeModal={closeModalAdmin}
        selectRow={selectAdminRow}
      />
    </>
  );
}
