import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import {
  deleteRequest,
  putRequest,
  postRequest,
  putRequestForm,
  RBButton,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
import Lightbox from "react-image-lightbox";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./CustomCard.css";
import VenueOfferListTable from "./VenueOfferTable";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function CategoryDetails({
  venueId,
  categoryId,
  categoryName = "",
}) {
  const [venueData, setData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const [textReply, setTextReply] = React.useState("");

  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
    if (newSelectedMusic.length > oldMusic.length) {
      const addedMusic = newSelectedMusic.filter(
        (newItem) => !oldMusic.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: addedMusic[0]._id,
        });
      }
    } else if (newSelectedMusic.length < oldMusic.length) {
      const removedMusic = oldMusic.filter(
        (oldItem) =>
          !newSelectedMusic.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: removedMusic[0]._id,
        });
      }
    }
  };

  const handleThemeSelectionChange = (newSelectedTheme) => {
    const oldTheme = [...selectedTheme];
    if (newSelectedTheme.length > oldTheme.length) {
      const addedTheme = newSelectedTheme.filter(
        (newItem) => !oldTheme.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: addedTheme[0]._id,
        });
      }
    } else if (newSelectedTheme.length < oldTheme.length) {
      const removedTheme = oldTheme.filter(
        (oldItem) =>
          !newSelectedTheme.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: removedTheme[0]._id,
        });
      }
    }
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {
    const oldCuisine = [...selectedCuisine];
    if (newSelectedCuisine.length > oldCuisine.length) {
      const addedCuisine = newSelectedCuisine.filter(
        (newItem) => !oldCuisine.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: addedCuisine[0]._id,
        });
      }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
      const removedCuisine = oldCuisine.filter(
        (oldItem) =>
          !newSelectedCuisine.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: removedCuisine[0]._id,
        });
      }
    }
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeatureSelectionChange = (newSelectedFeature) => {
    const oldFeature = [...selectedFeature];
    if (newSelectedFeature.length > oldFeature.length) {
      const addedFeature = newSelectedFeature.filter(
        (newItem) => !oldFeature.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: addedFeature[0]._id,
        });
      }
    } else if (newSelectedFeature.length < oldFeature.length) {
      const removedFeature = oldFeature.filter(
        (oldItem) =>
          !newSelectedFeature.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: removedFeature[0]._id,
        });
      }
    }
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff",
      backgroundColor: "#3f51b5",
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },
  });

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const categoryFetchDetail = async () => {
    setLoader(false);
    postRequest(`venue/category/detail/`, { categoryId: `${id}` }).then(
      (data) => {
        let venueData = data.data;
        setData(venueData);
        setLoader(false);
      }
    );
  };

  const Updatevenue = async (e) => {
    const formData = new FormData();
    formData.append("venueId", id);

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        }
      });
    }

    const response = await putRequestForm("venue/update", formData);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    categoryFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteVenueGallery(id);

        const tempVenueData = { ...venueData };
        tempVenueData.banners = tempVenueData.banners.filter(
          (item) => item._id !== id
        );

        setData(tempVenueData);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteVenueGallery = async (id) => {
    const response = await deleteRequest(`venue/banner/delete`, {
      bannerId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      VenueFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const { subTitle, title, color } = venueData;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Category Management</li>:{" "}
              <li className="breadcrumb-item">Category details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
              ></div>
              <div className="profile-image2">
                <img
                  src={venueData?.image}
                  onClick={() => showLightBox(venueData?.image)}
                  alt="Image Not Available"
                />
              </div>
              <br></br>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <div className="fst">
                    {" "}
                    <h3> {title} </h3>{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Color Start: <span> {color?.startColor}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Color End: <span> {color?.endColor}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <p>
                        {" "}
                        Subtitle: <span> {subTitle}</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Col md="12" style={{marginLeft:"inherit"}} >
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#gallery" data-toggle="tab">
                      {" "}
                      Banners{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#offers" data-toggle="tab">
                      {" "}
                      Offers
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">
                <div className="tab-pane padded-tab" id="offers">
                  {venueData.length !== 0 && (
                    <VenueOfferListTable
                      userData={venueData?.offers}
                      categoryId={venueData?._id}
                      categoryName={venueData?.title}
                    />
                  )}
                </div>
                <div className="tab-pane active padded-tab" id="gallery">
                  <Link
                    to={{
                      pathname: `/createBanner`,
                      state: {
                        row: {
                          type: "category",
                          venueId: venueData?._id,
                          venueName: venueData?.title,
                        },
                      },
                    }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="btn btn-info mx-2"
                      style={{ margin: "15px" }}
                    >
                      {" "}
                      <i class="fas fa-plus"></i> Create
                    </button>{" "}
                  </Link>
                  <br></br>
                  <Row>
                    {venueData?.banners?.map((gallery, index) => (
                      <Col key={gallery} xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Card>
                          <CardBody>
                            <img
                              src={gallery.image}
                              alt="gallery image "
                              className="mb-3"
                              style={{
                                height: "200px",
                                cursor: "pointer",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              onClick={() => showLightBox(gallery.image)}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <h4>{gallery.type}</h4>
                              <RBButton
                                color="danger"
                                onClick={() => handlerdelete2(gallery._id)}
                              >
                                <i className="fal fa-trash fs-5"></i>
                              </RBButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </Row>
      <ToastContainer />{" "}
    </>
  );
}
