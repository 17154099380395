import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  highlighted: {
    backgroundColor: "#e60489 !important",
    color: "#fff !important",
  },
}));

function isSelected(item, selectedItemList) {
  return selectedItemList.some((selectedItem) => selectedItem === item);
}

export default function TagSelection({
  itemList,
  selectedItemList,
  onSelectionChange,
  addButton=null,
}) {
  const classes = useStyles();

  // use the passed 'selectedItemList' as the default state
  const [selectedItems, setSelectedItems] = useState([...selectedItemList]);

  const handleClick = (item) => {
    let newSelectedItems;
    if (isSelected(item, selectedItems)) {
      newSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      newSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(newSelectedItems);
    onSelectionChange(newSelectedItems); // Update here with new state
  };

  useEffect(() => {
    onSelectionChange(selectedItems);
  }, [selectedItems, onSelectionChange]);

  return (
    <div className={classes.root}>
      <>
      {itemList?.map((item) => {
        let label = item;

        return (
          <li key={item._id}>
            <Chip
              label={label}
              onClick={() => handleClick(item)}
              className={`${classes.chip} ${
                isSelected(item, selectedItems) ? classes.highlighted : ""
              }`}
            />
          </li>
        );
      })}

      {addButton && (
          <button type="button" className=" btn btn-primary mx-1">
          <Link
            to={{
              pathname: `${addButton.path}`,
              state:  addButton.state ,                          
            }}
          >
            <i className="fal fa-plus fs-5 text-light"></i>
          </Link>
        </button>
      )}

      </>



    </div>
  );
}
