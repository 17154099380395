import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Modal,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  customValidator,
  putRequestForm,
  postRequestForm,
} from "../../components/Common/Utils.js";
import FileUpload from "../../components/Common/FileUpload"; 

export default function UpdateBucketModal({
  showBucketUpdate,
  closeBucketUpdateModal,
  bucketUpdateData,
}) {
  let history = useHistory();

  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [bucketData, setBucketData] = useState({
    bucketId: "",
    selectedImage: "",
  });
  const [bucketId, setBucketId] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [selectedCover, setSelectedCover] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setBucketData(bucketUpdateData);
    setPreviewImage(bucketUpdateData?.coverImage);
    setBucketId(bucketUpdateData?._id);
  }, [bucketUpdateData]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);
  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const updatebucket = async (e) => {
    e.preventDefault();

    bucketData.bucketId = bucketId;
    bucketData.selectedImage = selectedImage;

    const validator = customValidator(
      [
        {
          field: "bucketId",
          errorMsg: "Please select bucketId",
        },
        {
          field: "name",
          errorMsg: "Please select name",
        },
      ],
      bucketData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    
    let selectedImageRes = null;
    const formData = new FormData();

    if(selectedImage){
      const selectedImageForm = new FormData();
      selectedImageForm.append("image", selectedImage);
      setStartLoader(true);
      selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
      setStartLoader(false);
    }
    
    
    formData.append("id", bucketData._id);
    formData.append("name", bucketData.name);

    // if (selectedImage) {
    //   formData.append("image", selectedImage);
    // }
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    } 
    setLoading(true);
    const response = await putRequestForm("bucket/admin/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        window.location.reload();
        closeBucketUpdateModal();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bucketData };
    list[index] = value;
    setBucketData(list);
  };

  return (
    <Modal
      isOpen={showBucketUpdate}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <React.Fragment>
        <ToastContainer />

        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Update Bucket
          </h5>
          <button
            onClick={() => {
              closeBucketUpdateModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {/* header */}

        {/* form */}
        <Row>
          <Col lg={12}>
            <Card>
              {loading ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody>
                  <Form onSubmit={updatebucket}>
                    <Row>
                      <Col lg={12} className="ms-lg-auto">
                        <div className="mt-5 mt-lg-4">
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                onChange={(e) =>
                                  updateValue("name", e.target.value)
                                }
                                value={bucketData?.name}
                                name="name"
                                className="form-control"
                              ></input>
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-password-input"
                              className="col-sm-3 col-form-label"
                            >
                              Cover Image
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="file"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="coverImage"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                              <br />
                              {previewImage && (
                                <img
                                  src={previewImage}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                            </div>
                          </div>

                          <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                              <div className="col-sm-9">
                                <div>
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <FileUpload message="File Uploading" status={startLoader} />
      </React.Fragment>
    </Modal>
  );
}
