import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";
import {
  postRequest,
  deleteRequest,
  putRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { file } from "@babel/types";

import YachtClubModal from "components/Modal/YachtClubModal.js";
import FileUpload from "../../components/Common/FileUpload";
import YachtGalleryModal from "components/Modal/YachtGalleryModal";

const filterObj = {
  venue_name: "",
  title: "",
  status: "",
  createdAt: "",
};

const GalleryManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [gallerydata, setgallerydata] = useState([]);
  const [galleryPartialData, setGalleryPartialdata] = useState([]);
  const [lbData, setLBData] = React.useState([]);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [galleryMdl, setGalleryMdl] = useState(false);
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [gallery, setGallery] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [yachtClubId, setYachtClubId] = useState("");
  const [yachtClubText, setYachtClubText] = useState("");
  const [yachtClubImage, setYachtClubImage] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [loader2, setLoader2] = useState(false);
  const [showload, setShowLoad] = useState(false);

  
 
  useEffect(() => {
    if(!yachtClubId) return;
    galleryManagment(true);
  }, [yachtClubId]);

  const closeModal = () => {
    setModel(false);
  };

  const closeGalleryModal = () => {
    setGalleryMdl(false);
  };


  const toggleModel = () => {
    setModel(!model);
  };
   
  const selectYachtClubRow = (id, name,club) => {
    setYachtClubId(id?._id);
    setYachtClubText(id.name);
    setYachtClubImage(id.logo);
    closeModal();
  };

  

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.gallery}
          onClick={() => showLightBox(row.gallery)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  const showLightBox = (galleryData, initialIndex = 0) => {
    setLBData(galleryData);
    setPhotoIndex(initialIndex);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }



  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },
    {
      dataField: "gallery",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, row.gallery)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const galleryManagment = async (reset = true) => {    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      yachtClubId: yachtClubId,
      sortField: sort,
      sortOrder: sortOrder,
    };
   try{
    const response = await postRequest(`yacht/gallery/list/admin`, payload);
    const data = response.data;
    setTotalPage(data.count);

    if (data?.list) {     
      setgallerydata([...data.list]);     
    }}catch(error){
      console.error("Failed to fetch data:", error);
    }finally { 
    setLoader(false);
    }
  };

  const handlerdelete = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this gallery image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryImage(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryImage = async (id, file) => {
    const response = await deleteRequest("yacht/gallery/delete", {
      yachtClubId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");      
      galleryManagment(true);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>      

        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Yacht Gallery Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Yacht Gallery Management</li>:{" "}
                <li className="breadcrumb-item">Gallery List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-12 text-end" style={{ marginBottom: "20px" }}>
          <Col  className="col-lg-12 d-flex mb-3 justify-content-end">

                          <div
                            className="col-sm-3"
                            onClick={() => toggleModel()}
                          >
                            {yachtClubText ? (
                             
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Yacht Club
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={yachtClubImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={yachtClubText}
                                  id="selectedVenue"
                                  placeholder="No Yacht Club chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Yacht Club
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Yacht Club chosen"
                                />
                              </div>
                            )}
                          </div> 

                          {" "}

                          { yachtClubId && (
                            <Button className="mx-2" onClick={ () => setGalleryMdl(true)}>
                            {" "}
                            <i class="fas fa-plus"></i> Upload
                              </Button>
                          )}
            </Col>
          </div>

          {loader ? (
            <Col md={12} style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </Col>
          ) : (
            <>
            {
                gallerydata?.length == 0 && (
                  <div className="col-md-12 text-center">
                    <h4>{ yachtClubId == "" ? 'Plaese Select Yacht Club' : 'No Image' }</h4>
                  </div>
                )
              }
              {gallerydata.map((item, index) => (
                <Col key={item._id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Card>
                    <CardBody>
                      <img
                        src={item.gallery}
                        onClick={() => showLightBox(gallerydata.map(item => item.gallery), index)}
                        alt="img"
                        // width="100%"
                        // height="100%"
                        className="mb-3"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          objectFit: "cover"
                        }}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                      <h5 className="text-truncate font-size-14">
                          {item.yachtclubName}
                        </h5>
                        <Button
                          color="danger"
                          onClick={() => handlerdelete(item._id, item.gallery)}
                        >
                          <i className="fal fa-trash fs-5"></i>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>

        
        <YachtClubModal
        show={model}
        closeYachtClubModal={closeModal}
        selectRow={selectYachtClubRow}
      />
      <YachtGalleryModal show={galleryMdl} closeModal={closeGalleryModal} yachtClubId={yachtClubId} reloadImage={galleryManagment} />
      <FileUpload message="File Uploading" status={startLoader} />
       
      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={lbData[photoIndex]}
          onCloseRequest={() => setOpenLightBox(false)}
          nextSrc={lbData[(photoIndex + 1) % lbData.length]}
          prevSrc={lbData[(photoIndex + lbData.length - 1) % lbData.length]}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lbData.length - 1) % lbData.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lbData.length)
          }
        />
      )}
    </>
  );
};

export default GalleryManagementlist;
