import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import FileUpload from "../../components/Common/FileUpload"; 
import EventGalleryModal from "components/Modal/EventGalleryModal";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";
import {
  postRequest,
  deleteRequest,
  putRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const filterObj = {
  eventOrgName: "",

  createdAt: "",
};

const EventGalleryManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [gallerydata, setgallerydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [lbData, setLBData] = React.useState([]);
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [orgId, setOrgId] = useState("");
  const [orgText, setOrgText] = useState("");
  const [orgImage, setOrgImage] = useState("");
  const [galleryMdl, setGalleryMdl] = useState(false);

  const [loader2, setLoader2] = useState(false);
  const [showload, setShowLoad] = useState(false);

 
  const closeModal = () => {
    setModel(false);
  };

  useEffect(() => {
    if(!orgId) return;
    eventgalleryManagment();
  }, [orgId]);

  const closeGalleryModal = () => {
    setGalleryMdl(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const selectRow = (id, name,org) => {
    setOrgId(id);
    setOrgText(name);
    setOrgImage(org?.logo);
    closeModal();
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "venue_name") {
          var filterObject = {
            "venue.name": filter["venue_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key],
          };
        }

        if (key === "eventOrgName" || key === "title") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
   
    toggleModel();
    eventgalleryManagment();
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    eventgalleryManagment();
  };

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.gallery}
          onClick={() => showLightBox(row.gallery)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  const showLightBox = (galleryData, initialIndex = 0) => {
    setLBData(galleryData);
    setPhotoIndex(initialIndex);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Event Organizer</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.eventOrgName}
          onChange={(e) => updateFilter("eventOrgName", e.target.value)}
          name="eventOrgName"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "eventOrgName",
      text: "Event Organizer",
      sort: false,
    },

    {
      dataField: "gallery",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, row.gallery)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const eventgalleryManagment = async (reset = true) => {    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      eventOrgId: orgId,
      sortField: sort,
      sortOrder: sortOrder,
    };   
   try{
    const response = await postRequest(`event/org/gallery/list`, payload);
    const data = response.data;   
    if (data?.list) {      
      setgallerydata([...data.list]);     
    }}catch(error){
      setgallerydata([]);          
    }finally{   
    setLoader(false);
    }
  };

  const handlerdelete = (id, gallery) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, gallery);
      }
    });
  };

  const showRandomWordDialog = (id, gallery) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id, gallery);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id, gallery) => {
    const response = await deleteRequest("event/org/gallery/delete", {
      eventOrgId: id,
      image: gallery,
    });
    if (response.status === 1) {
      Swal.fire("Deleted!", "Deleted successfully");
      eventgalleryManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  

  // const selectRow = {
  //   mode: "checkbox", // or 'radio' for single selection
  //   clickToSelect: false, // enable click to select
  //   style: { background: "#c8e6c9" }, // background color for selected rows
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       setselectedItems([...selectedItems, row._id]);
  //     } else {
  //       setselectedItems(selectedItems.filter((x) => x !== row._id));
  //     }
  //   },
  //   onSelectAll: (isSelect, rows, e) => {
  //     if (!isSelect) {
  //       setselectedItems([]);
  //       return;
  //     }

  //     const ids = rows.map((r) => r._id);
  //     setselectedItems(ids);
  //   },
  // };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Event Gallery Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Event Gallery Management
                </li>
                : <li className="breadcrumb-item">Event Gallery List</li>
              </ol>
            </div>
          </Col>
         
                          

                         
                        
          <div className="col-md-12 text-end" style={{ marginBottom: "20px" }}>
          <Col  className="col-lg-12 d-flex mb-3 justify-content-end">        
          <div
                            className="col-sm-3"
                            onClick={() => toggleModel()}
                          >
                            {orgText ? (
                             
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Event Organizer
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={orgImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={orgText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Event Organizer
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Event Organizer chosen"
                                />
                              </div>
                            )}
                          </div>   

            {/* <Link to="/createEventGallery">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link> */}

              { orgId && (
                 <Button className="mx-2" onClick={ () => setGalleryMdl(true)}>
                 {" "}
                 <i class="fas fa-plus"></i> Upload
                  </Button>
              )}

            </Col>
          </div>
         
          {loader ? (
            <Col md={12} style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </Col>
          )
          
          : (
            <>
               {
                gallerydata?.length == 0 && (
                  <div className="col-md-12 text-center">
                    <h4>{ orgId == "" ? 'Plaese Select Event Organizer' : 'No Image' }</h4>
                  </div>
                )
              }

              {gallerydata.map((item, index) => (
                <Col key={item._id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Card>
                    <CardBody>
                      <img
                        src={item.gallery}
                        onClick={() => showLightBox(gallerydata.map(item => item.gallery), index)}
                        alt="img"
                        // width="100%"
                        // height="100%"
                        className="mb-3"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          objectFit: "cover"
                        }}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-truncate font-size-14">
                          {item.eventOrgName}
                        </h5>
                        <Button
                          color="danger"
                          onClick={() => handlerdelete(item._id, item.gallery)}
                        >
                          <i className="fal fa-trash fs-5"></i>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
      
        <OrganizerlistModal
        show={model}
        closeModal={closeModal}
        selectRow={selectRow}
      />
        <EventGalleryModal show={galleryMdl} closeModal={closeGalleryModal} orgId={orgId} reloadImage={eventgalleryManagment} />  
      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={lbData[photoIndex]}
          onCloseRequest={() => setOpenLightBox(false)}
          nextSrc={lbData[(photoIndex + 1) % lbData.length]}
          prevSrc={lbData[(photoIndex + lbData.length - 1) % lbData.length]}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lbData.length - 1) % lbData.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lbData.length)
          }
        />
      )}
       
      <FileUpload message="File Uploading" status={startLoader} />
    </>
  );
};

export default EventGalleryManagementlist;
