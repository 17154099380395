import React, { useState, useEffect } from "react";

import {
    deleteRequest,
    putRequest,
    postRequest,
    putRequestForm,
    RBButton,
    deleteConfirmationWords,
  } from "components/Common/Utils";

import EventCard from './EventCard'

// const demoEvent = {
//     imageSrc: "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/170919969195617091107834641707802395457screenshot_2024-02-13_at_9.jpg",
//     logoSrc: "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/logo/17091107735991705063130730le_patio-logo.jpg",
//     venueName: "Le Patio",
//     venueLocation: "Al Habtoor City - Sheik...",
//     date: "Wed, 13 Mar 2024",
//     time: "9:30PM - 11:00PM",
//     title: "hello",
//     createdDate: "Thu, 7 Mar 2024",
//     usersInvited: [
//       { name: "Alice", initials: "A" , isIn: true, },
//       { name: "Bob", initials: "B" },
//       { name: "Charlie", initials: "C", avatar: "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/video/1709299298239img_20240301_185123155.jpg" },
//       { name: "David", initials: "D", isIn: true},
//       { name: "Eve", initials: "E" },
//       { name: "XYZ", initials: "E" },     
//     ],
//     extraGuests: 15,
//     status: "upcoming", // can be "upcoming", "completed", or "cancelled"
//   };

const OutingCompletedList = ({venueId}) => {
    const [events, setEvents] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        bucketManagment();
      }
    , []);

    const processEvents = (eventsData) => {
        return eventsData.map(event => {
          const { venue, invitedUser, extraGuest,  offer = null, ...rest } = event;

          console.log("invitedUser", venue)
      
          const usersInvited = invitedUser.map(user => ({
            name: `${user.first_name}`,
            initials: `${user.first_name[0]}${user.last_name[0]}`,
            avatar: user.image,
            isIn: user.inviteStatus === 'in',
          }));
      
          return {
            imageSrc: offer?.image || venue.cover,
            logoSrc: venue.logo,
            venueName: venue.name,
            venueLocation: venue.address,
            date: new Date(event.date).toLocaleDateString(undefined, { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' }),
            time: `${event.startTime} - ${event.endTime}`,
            title: event?.title,
            createdDate: new Date(event.createdAt).toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' }),
            usersInvited,
            extraGuests: extraGuest,
            status: event.status,
          };
        });
      };

    const bucketManagment = async (filterArgs = []) => {
        setLoader(true);
        const payload = {
          limit: 10000,
          page: 1,
          sortField: 'createdAt',
          sortOrder: 'desc',
          venueId: venueId,
          currentStatus: 'completed',
        };    
       
        try {
          const response = await postRequest(`outing/list`, payload);
          const data = response.data.outing  ;
          const processedEvents = processEvents(data);  
          setEvents(processedEvents || []);         
        } catch (error) {
          console.error("Failed to fetch data:", error);
        } finally {
          setLoader(false);
        }
      };


    




  return (
    <>
        <br/><br/>

        <div class="row" style={{ padding: "90px 10px"}}>
            <div class="col-lg-1"> 
            </div> 

            <div class="col-lg-10"> 
            <div class="row">
                    {events.map((event, index) => (
                    <div key={index} className="col-xl-6 col-lg-6 col-md-6">
                    <EventCard event={event} />
                    </div>
                ))}            
            </div>
            </div>
            
            <div class="col-lg-1"> 
            </div> 
        </div>        
    </>
  )
}

export default OutingCompletedList