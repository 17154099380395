import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequest,
  pushUpdates,
} from "../../components/Common/Utils.js";
import { Select } from "@material-ui/core";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import BrunchModal from "components/Modal/BrunchModal.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import OfferModal from "components/Modal/OfferModal.js";

export default function UpdateSpecialOffer() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  console.log({state})

  const [specialOfferData, setSpecialOfferData] = useState();
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [open, setOpen] = useState(false);
  const [listFetched, setListFetched] = useState(false);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [defaultOffer, setDefaultOffer] = useState([]);


  const [venueOptions, setVenueOptions] = useState([
    { value: "", label: "None" },
  ]);

  const [offermode, setoffermode] = useState(false);
  const [SelectedspealOffer, setSelectedspealOffer] = useState();
  const closeOfferModal = () => {
    setoffermode(false);
  };

  const OpenOfferModal = () => {
    setoffermode(true);
  };

  const selectSpeacialOffer = (selectRow, id) => {
    console.log("SSSSSSSSS", { selectRow });
    setSelectedspealOffer(selectRow);
    closeOfferModal();
  };


  const [loading, setLoading] = useState(false);

  const [brunches, setBrunches] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  const { id } = useParams();






  useEffect(() => {
    setSpecialOfferData(state);
    console.log(state.offerIds)
    if (state?.discount?._id) {
      state.discount = state.discount._id;
    }

    if (state?.brunch) {
      setBrunches(state?.brunch);
    }
    if(state?.offer){
      setSelectedspealOffer(state?.offer)
    }
  }, [state]);

  useEffect(() => {
    fetchList();
    fetchDiscount();
    // fetchOfferList();
    setVenueId(state?.venue?._id);
    setVenueText(state?.venue?.name);
    setVenueImage(state?.venue?.logo);
    const defaultItems = state?.offerIds ? state?.offerIds : []
    setSelectedOffer([...defaultItems]);
  }, [state]);


  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("venue/discount/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const deleteBrunch = (index) => {
    const tempPack = [...brunches];
    tempPack.splice(index, 1);
    setBrunches(tempPack);
  };

  const pushBrunch = (data) => {
    const tempPack = [...brunches];

    // const discountItem = data.discountItem
    // const discountText = discountList.find(item => item._id === data.itemDiscount).title

    // data.discountText = discountText

    tempPack.push(data);
    setBrunches(tempPack);
    setOpen(false);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };


  useEffect(() => {
    if (!selectedOffer?.length) return;
    fetchOfferList();
  }, [selectedOffer]);


  const fetchOfferList = async () => {
    const response = await postRequest("venue/offer/list-by-venue/admin", {     
      venueId: venueId,
    });
    if (response.status == 1) {
      const options = response.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setOfferList(options);
      if (selectedOffer.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedOffer.includes(item.id);
        });
        setDefaultOffer(defaultItems);
      }
    }
  };

  const msOfferSelect = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };
  const msOfferRemove = (selectedList, removedItem) => {
    const ids = selectedList.map((item) => item.id).filter(id => id !== removedItem.id);
    setSelectedOffer(ids);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });

      setListFetched(true);
    }
    setLoading(false);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleModel2 = () => {
    setModel2(!model2);
  };

  useEffect(() => {
    if (venueId !== null) {
      fetchOfferList();
    }
  }, [venueId]);

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal2();
  };

  const UpdatespecialOffer = async (e) => {
    e.preventDefault();


    console.log({selectedOffer})

    if (!specialOfferData?.title) {
      toast.error("Please enter title");
      return;
    }

    if(!specialOfferData?.description) {
      toast.error("Please enter description");
      return;
    }
   
    if (!venueId) {
      toast.error("Please select venue");
      return;
    }

    if (!specialOfferData?.type) {
      toast.error("Please select type");
      return;
    }

    if(specialOfferData?.type === "brunch"){
      if (!SelectedspealOffer) {
        toast.error("Please select offer");
        return;
      }
    }
     
    if (!specialOfferData?.discount) {
      toast.error("Please enter discount");
      return;
    }
    if (specialOfferData?.discount > 99 && specialOfferData?.discount < 5) {
      toast.error("Discount must be less than 100");
      return;
    }
 
    if (specialOfferData?.type === "total") {
      if (!specialOfferData?.maxPersonAllowed) {
        toast.error("Please enter max person allowed");
        return;
      }
      if (specialOfferData?.maxPersonAllowed > 10 && specialOfferData?.maxPersonAllowed < 0) {
        toast.error("Max person allowed should be between 0 to 10");
        return;
      }
    }

    setLoading(true);

    const payload = {
      specialOfferId: state._id,
      title: specialOfferData.title,
      venueId: venueId,
      // claimCode: specialOfferData?.claimCode ? specialOfferData?.claimCode : "",
      maxPersonAllowed: specialOfferData?.maxPersonAllowed
        ? specialOfferData?.maxPersonAllowed
        : "",
      pricePerPerson: specialOfferData?.pricePerPerson
        ? specialOfferData?.pricePerPerson
        : "",
      description: specialOfferData.description,
      type: specialOfferData.type,
      // offerIds: selectedOffer,
      offerId: SelectedspealOffer?.offerId ||SelectedspealOffer?._id,
      discount: specialOfferData.discount ? specialOfferData.discount : "",
    };

    if (specialOfferData.type === "brunch") {
      const newbranches = brunches.map((item) => {
        return {
          item: item.item,
          amount: item.amount,
          // itemDiscount: item.itemDiscount
          pricePerBrunch: item.pricePerBrunch,
          discount: item.discount,
          qty: item.qty,
        };
      });
      payload.brunch = newbranches;
    }

    const response = await putRequest("venue/special-offer/update", payload);
    setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "special-offer",
          id: id,
          receivers: [],
        });
        // Check if the pushUpdates call was successful, handle the response as needed       
        toast.success(response.message);
        setTimeout(() => {
          // history.push("/specialoffer");
          history.goBack();
        }, 3000);
      } catch (pushUpdatesError) {
        console.error("Error in pushUpdates:", pushUpdatesError);
        toast.error("Error in pushUpdates");
      }
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...specialOfferData };
    list[index] = value;
    setSpecialOfferData(list);
  };

  const fetchIndex = (items, search) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value === search) {
        return i;
      }
    }
    return -1;
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Discount Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
              Discount Management
              </li>
              : <li className="breadcrumb-item">Update Discount</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div> 
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Discount </h4>
                <Form onSubmit={UpdatespecialOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={specialOfferData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={specialOfferData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel2()}
                          >
                            {venueText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {venueText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //   <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     Select Venue
                              //   </a>
                              // </div>
                              // <div>
                              //   <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={specialOfferData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option> Select</option>
                              <option value="total">Total</option>
                              <option value="brunch">Brunch</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Discount
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min="5"
                                  max="99"
                                  onChange={(e) =>
                                    updateValue("discount", e.target.value)
                                  }
                                  value={specialOfferData?.discount}
                                  name="title"                                  
                                  className="form-control"
                                />
                              </div>
                            </div>

                            {specialOfferData?.type === "brunch" && (
                         <>
                          <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Offers
                                  </label>

                                  <div
                                className="col-sm-6"
                                onClick={() => OpenOfferModal()}
                              >
                                {SelectedspealOffer ? (
                                  <div class="input-group">
                                    <button
                                      class="btn"
                                      style={{
                                        border: "1px solid #ced4da",
                                        background: "#e60489",
                                        color: "#ffff",
                                      }}
                                      type="button"
                                      id="chooseVenueBtn"
                                      // onclick={() => toggleModel()}
                                      onClick={() => OpenOfferModal()}
                                    >
                                      Choose offer
                                    </button>
                                    <div
                                      style={{
                                        border: "1px solid #ced4da",
                                        width: "16px",
                                      }}
                                    ></div>
                                    <img
                                      src={SelectedspealOffer?.image}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #ced4da",
                                        borderRight: "none",
                                        borderLeft: "none",
                                      }}
                                    ></img>
                                    <input
                                      type="text"
                                      style={{ borderLeft: "none" }}
                                      class="form-control"
                                      readonly
                                      value={SelectedspealOffer?.title}
                                      id="selectedVenue"
                                      placeholder="No offer chosen"
                                    />
                                  </div>
                                ) : (
                                  // <div
                                  //   style={{
                                  //     display: "flex",
                                  //     border: "1px solid #ced4da",
                                  //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  //     borderRadius: "0.25rem",
                                  //     lineHeight: 1.5,
                                  //   }}
                                  // >
                                  // <div style={{ minWidth: "100%" }}>
                                  //   <a
                                  //     href="javascript:void(0)"
                                  //     style={{
                                  //       textDecoration: "none",
                                  //       color: "#5b626b",
                                  //       fontSize: "0.8125rem",
                                  //       fontWeight: 400,
                                  //     }}
                                  //   >
                                  //     Select Venue
                                  //   </a>
                                  // </div>
                                  // <div>
                                  //   <a
                                  //     onClick={() => toggleModel()}
                                  //     href="javascript:void(0)"
                                  //   >
                                  //     <i
                                  //       style={{ color: "#5b626b" }}
                                  //       class="fa fa-plus"
                                  //     ></i>
                                  //   </a>
                                  // </div>
                                  <div class="input-group">
                                    <button
                                      class="btn"
                                      style={{
                                        border: "1px solid #ced4da",
                                        background: "#e60489",
                                        color: "#ffff",
                                      }}
                                      type="button"
                                      id="chooseVenueBtn"
                                      // onclick={() => toggleModel()}
                                      onClick={() => OpenOfferModal()}
                                    >
                                      Choose offer
                                    </button>
                                    <input
                                      type="text"
                                      class="form-control"
                                      readonly
                                      id="selectedVenue"
                                      placeholder="No offer chosen"
                                    />
                                  </div>
                                )}
                              </div>

                                  {/* <div className="col-sm-6">
                                    <MultiSelect
                                      options={offerList}
                                      onRemove={msOfferRemove}
                                      onSelect={msOfferSelect}
                                      selectedValues={defaultOffer}
                                      placeholder="Select Offers"
                                    />
                                  </div> */}



                                </div>
                         </>
                        )}
                        {/* {specialOfferData?.type === "brunch" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Brunch
                            </label>
                            <div className="col-sm-6">
                              <a
                                onClick={() => toggleModal()}
                                href="javascript:void(0)"
                              >
                                Add Brunches
                              </a>
                              <br />
                              <br />
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">pricePerBrunch</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {brunches?.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.item}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.discount}</td>
                                      <td>{item.qty}</td>
                                      <td>{item.pricePerBrunch}</td>

                                      <td>
                                        <a
                                          onClick={() => deleteBrunch(index)}
                                          href="javascript:void(0)"
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )} */}

                        {specialOfferData?.type === "total" && (
                          <div>
                            {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <select
                                value={specialOfferData?.discount}
                                onChange={(e) =>
                                  updateValue("discount", e.target.value)
                                }
                                class="form-select"
                              >
                                <option> Select</option>
                                {discountList.map((item, index) => (
                                  <option value={item._id}>{item.title}</option>
                                ))}
                              </select>
                          </div>
                        </div> */}
                    
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Maximum Person Allowed
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min="1"
                                  max="6"
                                  onChange={(e) =>
                                    updateValue(
                                      "maxPersonAllowed",
                                      e.target.value
                                    )
                                  }
                                  value={specialOfferData?.maxPersonAllowed}
                                  name="title"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Price Per Person
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min="1"
                                  onChange={(e) =>
                                    updateValue(
                                      "pricePerPerson",
                                      e.target.value
                                    )
                                  }
                                  value={specialOfferData?.pricePerPerson}
                                  name="title"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <BrunchModal
        addData={pushBrunch}
        show={open}
        closeModal={closeModal}
        discountList={discountList}
      />

<OfferModal
        show={offermode}
        closeModal={closeOfferModal}
        selectRow={selectSpeacialOffer}
      />
      <VenueModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow}
      />
    </React.Fragment>
  );
}
