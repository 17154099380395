import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";

// Component to generate an avatar image from a name
function AvatarPromoter({
  name,
  round = false,
  size = 256,
  iconSize = 48,
  caption = "",
}) {
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [avatarURL, setAvatarURL] = useState("");
  const [image, setImage] = React.useState("");

  useEffect(() => {
    const baseURL = "https://ui-avatars.com/api/";
    const bgColor = getRandomColor();
    const constructedURL = `${baseURL}?name=${encodeURI(
      name
    )}&background=${bgColor}&size=${size}&round=${round}`;
    setAvatarURL(constructedURL);
  }, [name]);

  // Function to generate a random hex color
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  return (
    <>
      <img
        // style={{ width: iconSize, height: iconSize, marginRight: "5px" }}
        style={{
          cursor: "pointer",
          height: "220px",
          width: "100%",
          objectFit: "cover",
          paddingBottom: "15px",
        }}
        src={avatarURL}
        onClick={() => showLightBox(avatarURL)}
        alt={`${name} Avatar`}
      />

      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
}

export default AvatarPromoter;
