import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  RBButton,
} from "components/Common/Utils";
import { useParams } from "react-router-dom";
import moment from "moment";

import { format } from "date-fns";
import StarComponent from "./StarComponent";
import Lightbox from "react-image-lightbox";
import Swal from "sweetalert2";
import LocationPicker from "react-location-picker";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import RatingTable from "./RatingTable";
import ActivityBookingListTable from "./ActivityBookingListTable";
import ActivityOrderManagmentlist from "./ActivityOrderManagmentlist";

import "./CustomCardAct.css";
import ActivityAdListTable from "./ActivityAdsList";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Activitydetails(activityId, activityName) {
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [activityData, setData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openLightbox, setOpenLightBox] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(true);
  const location1 = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  const [textReply, setTextReply] = useState("");

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();

  const ActivityFetchDetail = async () => {
    setLoader(false);
    postRequest(`activity/admin/detail`, { actId: `${id}` }).then((data) => {
      let activitydata = data.data;
      setData(activitydata);

      setCurrentLocation({
        lat: parseFloat(activitydata?.provider?.location?.coordinates[1]),
        lng: parseFloat(activitydata?.provider?.location?.coordinates[0]),
      }); // getting error

      setLoader(false);
    });
  };

  useEffect(() => {
    ActivityFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...activityData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };
  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const deleteReview = async (id, index) => {
    const response = await deleteRequest("rating/event/delete", {
      ratingId: id,
    });
    // remove form OrganizerData.ratings

    if (response.status == 1) {
      const orgData = { ...activityData };
      orgData.ratings.splice(index, 1);
      setData(orgData);
      toast.success("Review Deleted");
    } else {
      toast.error(response.message);
    }
  };

  const {
    name,
    logo,
    bookings,
    coverImage,
    providerName,
    avilableFeatures,
    typeName,
    price,
    discount,
    totalSeats,
    avilableDays,
    activityTime,
    endDate,
    startDate,
    description,
    provider,
    address,
    website,
    createdAt,
    updatedAt,
  } = activityData;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Activity Detail</h4>

            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Activity Management</li>:{" "}
              <li className="breadcrumb-item">Activity</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                // style={{ backgroundImage: `url(${coverImage})` }}
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
                onClick={() => showLightBox(coverImage)}
              ></div>
              {/* <div className="profile-image2">
      <img src={logo} 
      onClick={() => showLightBox(logo)}
      alt="Image Not Available" />
    </div> */}

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <br></br>

                  <div className="fst">
                    {" "}
                    <h3>{name} </h3>{" "}
                  </div>

                  <div className="row">
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Provider Name: <span> {providerName}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Activity Type: <span> {typeName}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Price: <span> {price}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Discount: <span> {discount}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Number of Seats: <span> {totalSeats}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Available Days: <span>
                          {" "}
                          {avilableDays?.join(", ")}
                        </span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Start Date: <span> {startDate?.slice(0, 10)}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-3 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        End Date: <span> {endDate?.slice(0, 10)}</span>{" "}
                      </p>{" "}
                    </div>
                    <br></br>
                    <hr />

                    <div className="row">
                      <div className="col-md-12 fst py-1">
                        <p>
                          {" "}
                          About: <span> {description}</span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Col md="12" style={{marginLeft:"inherit"}} >
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#location" data-toggle="tab">
                      {" "}
                      Location{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#provider" data-toggle="tab">
                      {" "}
                      Provider{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#reviews" data-toggle="tab">
                      {" "}
                      Reviews{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#gallery" data-toggle="tab">
                      {" "}
                      Gallery
                    </a>
                  </li> */}
                  <li>
                    <a href="#time" data-toggle="tab">
                      Activity Time
                    </a>
                  </li>                 
                  <li>
                    <a href="#activityOrders" data-toggle="tab">
                      Orders
                    </a>
                  </li>
                  <li>
                    <a href="#feature" data-toggle="tab">
                      {" "}
                      Feature
                    </a>
                  </li>

                  <li>
                    <a href="#ads" data-toggle="tab">
                      {" "}
                      Ads
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">
                <div className="tab-pane active padded-tab" id="location">
                  <div className="row">
                    <div className="map-container">
                      {currentLocation == null ? null : (
                        <LocationPicker
                          containerElement={<div style={{ height: "100%" }} />}
                          mapElement={<div style={{ height: "350px" }} />}
                          defaultPosition={
                            currentLocation ? currentLocation : defaultPosition
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="tab-pane padded-tab" id="reviews">
                  <RatingTable activityId={id} />
                </div>
                <div className="tab-pane  padded-tab" id="gallery">
                  <Link
                    to={{
                      pathname: `/createGallery`,
                      state: {
                        row: {
                          activityId: activityData?._id,
                          activityName: activityName?.name,
                        },
                      },
                    }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="btn btn-info mx-2"
                      style={{ margin: "15px" }}
                    >
                      {" "}
                      <i class="fas fa-plus"></i> Create
                    </button>{" "}
                  </Link>
                  <br></br>

                  <Row>
                    {activityData?.galleries?.map((galleries, index) => (
                      <Col key={gallery} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                          <CardBody>
                            <img
                              src={galleries}
                              alt="gallery image "
                              className="mb-3"
                              style={{
                                height: "200px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              onClick={() => showLightBox(galleries)}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <RBButton
                                color="danger"
                                onClick={() =>
                                  handlerdelete2(activityData?._id, galleries)
                                }
                              >
                                <i className="fal fa-trash fs-5"></i>
                              </RBButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="tab-pane  padded-tab" id="feature">
                  <GridList
                    cellHeight={160}
                    className={classes.gridList}
                    cols={5}
                  >
                    {activityData?.avilableFeatures?.map((icon, index) => (
                      <GridListTile
                        onClick={() => showLightBox(icon.icon)}
                        key={avilableFeatures}
                        cols={1}
                      >
                        <img src={icon.icon} alt={icon.feature} />
                        <GridListTileBar title={icon.feature} />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>

                <div className="tab-pane padded-tab" id="provider">
                  <div className="row">
                    <div className="col-md-6 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Provider Name: <span> {provider?.name}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-6 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Address: <span> {provider?.address}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-6 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Email: <span> {provider?.email}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-6 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Phone: <span> {provider?.phone}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-6 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Category: <span> {provider?.category}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>

                <div className="tab-pane padded-tab" id="time">
                  <div className="row">
                    {activityTime?.type === "slot" ? (
                      activityTime?.slot?.map((slot, index) => (
                        <div key={index} className="col-md-12 fst py-1">
                          <p>
                            Slot Time: <span>{slot?.time}</span>
                          </p>
                          <p>
                            Seats: <span>{slot?.totalSeats}</span>
                          </p>
                        </div>
                      ))
                    ) : (
                      <div>
                        <div className="col-md-6 fst py-1">
                          <p>
                            Start Time: <span>{activityTime?.start}</span>
                          </p>
                        </div>
                        <div className="col-md-6 fst py-1">
                          <p>
                            End Time: <span>{activityTime?.end}</span>
                          </p>
                        </div>
                      </div>
                    )}

                    {/* <>if(activityTime.type=="slot"){ */}
                    {/* <div className="col-md-6 fst py-1"> <p> Slot Time:   <span> {slot?.time}</span> </p> </div> */}
                    {/* }</>    */}
                    {/* <div className="col-md-6 fst py-1"> <p> Provider Name:   <span> {provider?.name}</span> </p> </div>
          <div className="col-md-6 fst py-1"> <p> Address:   <span> {provider?.address}</span> </p> </div>
          <div className="col-md-6 fst py-1"> <p> Email:   <span> {provider?.email}</span> </p> </div>   
          <div className="col-md-6 fst py-1"> <p> Phone:   <span> {provider?.phone}</span> </p> </div> 
          <div className="col-md-6 fst py-1"> <p> Category:   <span> {provider?.category}</span> </p> </div>  */}
                  </div>
                </div>
             

                <div className="tab-pane padded-tab" id="ads">
                  {activityData.length !== 0 && (
                    <ActivityAdListTable
                      adData={activityData?.ads}
                      itemId={activityData?._id}
                      activityName={activityData?.name}
                    />
                  )}
                </div>
                <div className="tab-pane padded-tab" id="activityOrders">
                  {activityData.length !== 0 && (
                    <ActivityOrderManagmentlist
                      // adData={activityData?.ads}
                      itemId={activityData?._id}
                      // activityName={activityData?.name}
                    />
                  )}
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
}
