import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "../../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";
import VenueModal from "components/Modal/VenueModalOrg.js";
import AdminModal from "components/Modal/AdminlistModalId";
import InviteModal from "components/Modal/InvitelistModalId.js";
import EventPackageModal from "components/Modal/EventPackageModal.js";
import FileUpload from "../../../components/Common/FileUpload"; 

import UserlistModal from "components/Modal/UserlistModal.js";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import { parseISO, format } from "date-fns";
import { useHistory, useParams, useLocation } from "react-router-dom";

export default function UpdateEvent() {
  const { id } = useParams();
  const location = useLocation();
  const state = location.state?.row;
  // const [eventData, setEventData] = useState(null);

  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    user_type: "",
    org_id: "",
    user_id: "",
    type: "",
    venue_type: "",
    venue: "",
    image: "",
    reservation_time: "",
    event_time: "",
    pack: "",
    admins: "",
  });
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [eventTime, setEventTime] = useState('');
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [modelAdmin, setModelAdmin] = useState(false);
  const [modelInvite, setModelInvite] = useState(false);
  const [adminPreview, setAdminPreview] = useState([]);
  const [invitePreview, setInvitePreview] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [inviteId, setInviteId] = useState([]);
  const [defaultInviteId, setDefaultInviteId] = useState([]);

  const [eventOrganizerId, setEventOrganizerId] = useState("");
  const [eventOrganizerText, setEventOrganizerText] = useState("");
  const [eventOrganizerImage, setEventOrganizerImage] = useState("");

  const [orgList, setOrgList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [orgText, setOrgText] = useState("");
  const [openPackage, setOpenPackage] = useState(false);
  const [userId, setUserId] = useState("");
  const [userText, setUserText] = useState("");
  const [userImage, setUserImage] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [model, setModel] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [orgModal, setOrgModal] = useState(false);

  const [disableType, setDisableType] = useState(false);

  // useEffect(() => {
  //   if (!eventOrganizerId) return;
  //   fetchFollowers(eventOrganizerId);
  // }, [eventOrganizerId]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  useEffect(() => {
    if (!state) return;

    if (state?.reservation_time) {
      const reservationDate = new Date(state.reservation_time);

      // Format date and time as desired (e.g., "YYYY-MM-DDTHH:mm:ss")
      const formattedDate = reservationDate.toISOString().slice(0, 19);
      state.reservation_time = formattedDate;
    }

    if (state?.event_time) {
      const startDate = new Date(state.event_time);

      // Format date and time as desired (e.g., "YYYY-MM-DDTHH:mm:ss")
      const formattedDate = startDate.toISOString().slice(0, 19);
      state.event_time = formattedDate;
    }
    if (state?.pack?.length) {
      // add saved key to packages
      const packages = state.pack.map((item) => {
        return {
          ...item,
          saved: true,
        };
      });
      setPackages(packages);
    }

    const allAdmin = state.admins?.map((item) => {
      let arr = [],
        cover,
        label,
        title,
        description;
      title = item?.first_name + " " + item?.last_name;
      cover = item?.image;

      label = "";

      arr = { cover, label, title };
      return arr;
    });

    const allInvites = state.invites?.map((item) => {
      let arr = [],
        cover,
        label,
        title,
        id,
        description;
      title = item?.first_name + " " + item?.last_name;
      cover = item?.image;
      id = item?._id;
      label = "";

      arr = { cover, label, title, id };
      return arr;
    });


    setAdminPreview(allAdmin);
    setInvitePreview(allInvites);
    setAdminId(state.admins?.map((item) => item._id));
    setDefaultInviteId(state.invites?.map((item) => item._id));

    if (state?.reservation_time) {
      const reservationDate = new Date(state.reservation_time);
      const formattedDate = `${reservationDate.getFullYear()}-${(
        reservationDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${reservationDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${reservationDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${reservationDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      state.reservation_time = formattedDate;
    }

    if (state?.event_time) {
      const startDate = new Date(state.event_time);
      const formattedDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${startDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${startDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      state.event_time = formattedDate;
    }
    setAdminId(state.admins?.map((item) => item._id));
    setPreviewImage(state.image);
    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venue?.logo);
    setEventOrganizerId(state?.org_id);
    setEventOrganizerText(state?.orgName);
    setEventOrganizerImage(state?.orgLogo);
    setUserId(state?.user?._id);
    setUserText(state?.user?.first_name + " " + state?.user?.last_name);
    setUserImage(state?.user?.image);
    setEventData(state);
  }, [state]);

  useEffect(() => {
    if (eventData?.user_type == "user") {
      // set type into private & disable type dropdown

      const data = { ...eventData };
      data.type = "private";
      setEventData(data);
      setDisableType(true);
    } else {
      setDisableType(false);
    }
  }, [eventData?.user_type]);

  let history = useHistory();

  const handleRemoveAdminPreview = (index) => {
    const newArray = [...adminPreview];
    newArray.splice(index, 1);
    setAdminPreview(newArray);
    const newvenue = [...adminId];
    newvenue.splice(index, 1);
    setAdminId(newvenue);
  };

  const handleRemoveInvitePreview = (index) => {
    const newArray = [...invitePreview];
    newArray.splice(index, 1);
    setInvitePreview(newArray);
    const newvenue = [...inviteId];
    newvenue.splice(index, 1);
    setInviteId(newvenue);
  };

  const toggleModelAdmin = () => {
    setModelAdmin(!modelAdmin);
  };

  const toggleModelInvite = () => {
    setModelInvite(!modelInvite);
  };

  const closeModalAdmin = () => {
    setModelAdmin(false);
  };

  const closeModalInvite = () => {
    setModelInvite(false);
  };

  const selectAdminRow = (selectRow, id) => {
    // check if activity is already added

    const isAdminAlreadyAdded = adminId.includes(selectRow._id);

    if (isAdminAlreadyAdded) {
      toast.error("Admin already added");
      return;
    }
   

   
    closeModalAdmin();
    let arr = [],
      cover,
      label,
      title,
      description;
    title = selectRow?.first_name + " " + selectRow?.last_name;
    cover = selectRow?.image;
    label = "";

    arr = { cover, label, title };
    setAdminPreview([...adminPreview, arr]);
    setAdminId([...adminId, selectRow._id]);
  };
 
  const selectInviteRow = (selectRow, id) => { 

    const isDefaultInvite = defaultInviteId.includes(selectRow?._id);
    if (isDefaultInvite) {
      console.log("isDefaultInvite",selectRow, defaultInviteId)
      toast.error("Invite already added");
      return;
    }
    const isInviteAlreadyAdded = inviteId.includes(selectRow?._id);
    if (isInviteAlreadyAdded) {
      toast.error("Invite already added");
      return;
    }       
    closeModalInvite();
    let arr = [],
      cover,
      label,
      title,
      description;
    title = selectRow?.first_name + " " + selectRow?.last_name;
    cover = selectRow?.image;
    label = "";

    arr = { cover, label, title };
    setInvitePreview([...invitePreview, arr]);
    setInviteId([...inviteId, selectRow._id]);
  };

  const updateEventsData = async (e) => {
    e.preventDefault();
    if (!eventData.title) {
      toast.error("Title is required");
      return;
    }
    if (!eventData.description) {
      toast.error("Please enter description");
      return;
    }    
    let imgres = null; 
    if(selectedImage){
      const x = new FormData();      
      x.append("image", selectedImage);
      setStartLoader(true);   
      imgres = await postRequestForm("comman/img/upload", x);
      setStartLoader(false);   
    }
    const packages_title = [];
    const packages_price = [];
    const packages_discount = [];
    const packages_qty = [];
    const packages_isFeatured = [];
    const packages_description = [];
    const packages_id = [];
    const packages_leftQtyAlert = [];
    const packages_isAllowSale = [];


    packages.forEach((item, index) => {      
        packages_title.push(item.title);
        packages_description.push(item?.packages_description || item?.description)
        packages_price.push(parseFloat(item.amount));        
        packages_discount.push(item.discount);
        packages_qty.push(item.qty);
        packages_id.push(item?._id || null);
        packages_leftQtyAlert.push(item?.leftQtyAlert || null);
        packages_isAllowSale.push(item?.isAllowSale || null);
        packages_isFeatured.push(item.isFeatured || false);      
    });

    // check atleast one package is added

    // if (packages_title.length === 0) {
    //   toast.error("Please add atleast one package");
    //   return;
    // }

    // check if atleast one package is featured

    const isFeatured = packages_isFeatured.includes(true);

    // if (!isFeatured) {
    //   toast.error("Please select atleast one featured package");
    //   return;
    // }

    // check if atleast one admin is added

    // if (adminId.length === 0) {
    //   toast.error("Please add atleast one admin");
    //   return;
    // }

    const dataObj = {
      event_id: eventData._id,
      title: eventData.title,
      description: eventData.description,
      // user_type: eventData.user_type,
      user_type: 'org',
      org_id: eventData.user_type === "org" ? eventOrganizerId : null,
      user_id: eventData.user_type === "user" ? userId : null,
      type: eventData.type,
      image: eventData.image,
      // venue_type: eventData.venue_type,
      disclaimerDescription: eventData.disclaimerDescription,
      disclaimerTitle: eventData.disclaimerTitle,
      venue: venueId,
      reservation_time: eventData.reservation_time,
      event_time: eventData.event_time,
      status: eventData.status == 'true' || eventData.status == true ? 1 : 0,
      packages_title: packages_title,
      packages_price: packages_price,
      packages_discount: packages_discount,
      packages_description: packages_description,
      packages_qty: packages_qty,
      packages_id: packages_id,
      packages_isFeatured: packages_isFeatured,
      packages_leftQtyAlert: packages_leftQtyAlert,
      packages_isAllowSale: packages_isAllowSale,
      admins: adminId,
      invite_users: inviteId,
    };

    if (imgres?.data?.url) {
      dataObj.image = imgres.data.url;
    }

    // const formData = new FormData();
    // formData.append("eventId", id);
    // formData.append("title", eventData.title);
    // formData.append("description", eventData.description);
    // formData.append("user_type", eventData.user_type);

    // if (eventData?.user_type === "org") {
    //   formData.append("org_id", eventOrganizerId);
    // }
    // if (eventData?.user_type === "user") {
    //   formData.append("user_id", userId);
    // }
    // formData.append("type", eventData.type);

    // formData.append("venue_type", eventData.venue_type);
    // if (eventData?.venue_type === "venue") {
    //   formData.append("venue", venueId);
    // }

    // formData.append("image", selectedImage);
    // formData.append("reservation_time", eventData.reservation_time);
    // formData.append("event_time", eventData.event_time);
    // // formData.append("event_discounts", eventData.event_discounts);
    // formData.append("status", eventData.status);

    // // add packages
    // packages.forEach((item, index) => {
    //   if (item.saved) {
    //     formData.append(`packages_title[]`, item.title);
    //     formData.append(`packages_price[]`, item.actualPrice);
    //     formData.append(`packages_discount[]`, item.discountValue);
    //   }
    // });

    // if (adminId.length > 0) {
    //   adminId.forEach((item, index) => {
    //     formData.append(`admins[]`, item);
    //   });
    // }
    
    setLoading(true);
    const response = await putRequest("event/update", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        // history.push("/events");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleUserModel = () => {
    setUserModel(!userModel);
  };

  const toggleOrgModel = () => {
    setOrgModal(!orgModal);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeUserModal = () => {
    setUserModel(false);
  };

  const closeOrgModal = () => {
    setOrgModal(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectUserRow = (id, name, user) => {   
    setUserId(id);
    setUserText(name);
    setUserImage(user?.image);
    closeUserModal();
  };

  const selectOrgRow = (id, name, org) => {
    setEventOrganizerId(id);
    setEventOrganizerText(name);
    setEventOrganizerImage(org?.logo);
    closeOrgModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };
  const selectOfferRow = (id, name) => {
    setOfferId(id);
    setOfferText(name);
    closeOfferModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const addRow = () => {
    setPackages([
      ...packages,
      {
        title: "",
        discount: "",
        amount: 0,
        qty: 1,
        isFeatured: false,
        saved: false,
      },
    ]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const addPackage = () => {
    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {
    const tempPackage = data
    tempPackage.description = data.packages_description

    setPackages([...packages,tempPackage]);
    setOpenPackage(false);
  };

  const updatePackageData = (index, field, value) => {
    const newPackages = [...packages];
    newPackages[index][field] = value;
    setPackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, '0')}`;
    const day = `${now.getDate().toString().padStart(2, '0')}`;
    const hours = `${now.getHours().toString().padStart(2, '0')}`;
    const minutes = `${now.getMinutes().toString().padStart(2, '0')}`;

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Events Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Events Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Update Events</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Events </h4>
                <Form onSubmit={updateEventsData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={eventData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              defaultValue={eventData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={eventData?.disclaimerTitle}
                              onChange={(e) =>
                                updateValue("disclaimerTitle", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Disclaimer Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimerDescription", e.target.value)
                              }
                              value={eventData?.disclaimerDescription}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            User Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={eventData?.user_type}
                              onChange={(e) =>
                                updateValue("user_type", e.target.value)
                              }
                              name="user_type"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="user">User</option>
                              <option value="org">Organization</option>
                            </select>
                          </div>
                        </div>
                        {eventData?.user_type === "user" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select User
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleUserModel()}
                            >
                              {userText ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {userText}
                                //   </a>
                                // </div>
                                // <div>
                                // <a
                                //     onClick={() => toggleUserModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                //   </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseUserBtn"
                                    onclick={() => toggleUserModel()}
                                  >
                                    Choose User
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={userImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={userText}
                                    id="selectedUser"
                                    placeholder="No User chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select User
                                //     </a>
                                //   </div>
                                //   <div>
                                //     <a
                                //       onClick={() => toggleUserModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseUserBtn"
                                    onclick={() => toggleUserModel()}
                                  >
                                    Choose User
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedUser"
                                    placeholder="No User chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )} */}

                        {/* {eventData?.user_type === "org" && ( */}
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Organizer
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleOrgModel()}
                            >
                              {eventOrganizerText ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       {eventOrganizerText}
                                //     </a>
                                //   </div>
                                //   <div>
                                //   <a
                                //       onClick={() => toggleOrgModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOrganizerBtn"
                                    onclick={() => toggleOrgModel()}
                                  >
                                    Choose Organizer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventOrganizerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={eventOrganizerText}
                                    id="selectedOrganizer"
                                    placeholder="No Organizer chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Organizer
                                //     </a>
                                //   </div>
                                //   <div>
                                //     <a
                                //       onClick={() => toggleOrgModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>

                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOrganizerBtn"
                                    onclick={() => toggleOrgModel()}
                                  >
                                    Choose Organizer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedOrganizer"
                                    placeholder="No Organizer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        {/* )} */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={eventData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              disabled={disableType}
                              className="form-select"
                              name="type"
                            >
                              <option value="">Select</option>
                              <option value="public">Public</option>
                              <option value="private">Private</option>
                            </select>
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Venue Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("venue_type", e.target.value)
                              }
                              value={eventData?.venue_type}
                              name="venue_type"
                              className="form-select"
                            >
                              <option value="">Select Venue Type</option>
                              <option value="venue">Venue</option>
                              <option value="custom">Custom Venue</option>
                            </select>
                          </div>
                        </div> */}

                        {/* {eventData?.venue_type === "venue" && ( */}
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       {venueText}
                                //     </a>
                                //   </div>
                                //   <div></div>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Venue
                                //     </a>
                                //   </div>
                                //   <div>
                                //     <a
                                //       onClick={() => toggleModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        {/* )} */}
                        {/* {eventData?.type === "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {offerText}
                                    </a>
                                  </div>
                                  <div></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select Offer
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      onClick={() => toggleOfferModel()}
                                      href="javascript:void(0)"
                                    >
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {eventData?.type === "category" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleCategoryModel()}
                            >
                              {categoryText ? (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {categoryText}
                                    </a>
                                  </div>
                                  <div></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select Category
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      onClick={() => toggleCategoryModel()}
                                      href="javascript:void(0)"
                                    >
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )} */}
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Event Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="datetime-local"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEventTime(newValue);
                                updateValue("event_time", newValue);
                              }}
                              value={eventData?.event_time}
                              name="event_time"
                              className="form-control"
                              // min={getCurrentDateTime()}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Reservation Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="datetime-local"
                              onChange={(e) =>
                                updateValue("reservation_time", e.target.value)
                              }
                              value={eventData?.reservation_time}
                              name="reservation_time"
                              className="form-control"
                              // min={getCurrentDateTime()}
                              max={eventTime}
                            />
                          </div>
                        </div>

                        {eventOrganizerText && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Assign Admin
                            </label>

                            {adminPreview?.map((form, index) => (
                              <div className="col-lg-3 mb-3">
                                <div class="admin-card">
                                  <div class="admin-card-image">
                                    <img src={form?.cover} alt="Avatar" />
                                  </div>

                                  <div class="admin-card-content">
                                    <h4>{form?.title}</h4>
                                  </div>
                                </div>

                                <div style={{ textAlign: "start" }}>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveAdminPreview(index)
                                    }
                                    className="btn-remove"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-3">
                              <button
                                type="button"
                                onClick={toggleModelAdmin}
                                className="modal-custom-button mt-3 mb-3"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {eventOrganizerText && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Send Invite
                            </label>

                            {invitePreview?.map((form, index) => (
                              <div className="col-lg-3 mb-3">
                                <div class="admin-card">
                                  <div class="admin-card-image">
                                    <img src={form?.cover} alt="Avatar" />
                                  </div>

                                  <div class="admin-card-content">
                                    <h4>{form?.title}</h4>
                                  </div>
                                </div>

                                <div style={{ textAlign: "start" }}>

                                  { !defaultInviteId?.includes(form?.id) ?  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveInvitePreview(index)
                                    }                                    
                                    className="btn-remove"
                                  >
                                    Remove
                                  </button> : null }

                                </div>
                              </div>
                            ))}

                            <div className="col-lg-3">
                              <button
                                type="button"
                                onClick={toggleModelInvite}
                                className="modal-custom-button mt-3 mb-3"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-9">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Package Title</th>
                                  <th>Package Description</th>
                                  <th>Package Amount</th>
                                  <th>Package Discount</th>
                                  <th>Qty</th>
                                  <th>Is Featured</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        value={item?.title}
                                        onChange={(e) =>
                                          updatePackageField(
                                            index,
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="text"
                                        value={item?.description}
                                        onChange={(e) =>
                                          updatePackageField(
                                            index,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </td>

                                    <td>

                                      <input
                                        type="number"
                                        value={`${item?.amount}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "amount",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item?.discount}
                                        onChange={(e) =>
                                          updatePackageField(
                                            index,
                                            "discount",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item?.qty}
                                        onChange={(e) =>
                                          updatePackageField(
                                            index,
                                            "qty",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.isFeatured}
                                        onChange={(e) =>
                                          updatePackageField(
                                            index,
                                            "isFeatured",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={12}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div> */}
                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-6">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add package
                            </button>
                            <table className="table">
                              <thead>
                              <tr>
                                  <th>Package Title</th>
                                  <th>Package Description</th>
                                  <th>Package Amount</th>
                                  <th>Package Discount</th>
                                  <th>Qty</th>
                                  <th>Left Qty Alert On</th>
                                  <th>Is Allow Sell </th>
                                  <th>Is Featured</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      {/* <input
                                        type="text"
                                        value={item.title}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}
                                      {item.title}
                                    </td>

                                    <td>
                                      {/* <input
                                        type="text"
                                        value={item.packages_description}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "packages_description",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}
                                      {item.description}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="number"
                                        value=
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "amount",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}
                                      {item.amount}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="text"
                                        value={item.discount}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "discount",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}
                                      {item.discount}
                                    </td>
                                    
                                    <td>
                                      {/* <input
                                        type="number"
                                        value={item.qty}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "qty",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}

                                      {item.qty}
                                    </td>

                                    <td>
                                      {/* <input
                                        type="number"
                                        value={item.qty}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "qty",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                      /> */}

                                      {item.leftQtyAlert || 0 }
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.isAllowSale}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "isAllowSale",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                      

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.isFeatured}
                                        onChange={(e) =>
                                          updatePackageData(
                                            index,
                                            "isFeatured",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <i
                                        className="fas fa-trash-alt"
                                        onClick={() => deletePackage(index)}
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={eventData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <UserlistModal
        show={userModel}
        closeModal={closeUserModal}
        selectRow={selectUserRow}
      />
      <OrganizerlistModal
        show={orgModal}
        closeModal={closeOrgModal}
        selectRow={selectOrgRow}
      />
      <EventPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
      />
      <AdminModal
        show={modelAdmin}
        closeModal={closeModalAdmin}
        selectRow={selectAdminRow}
        orgId={eventOrganizerId}
      />
      <InviteModal
        show={modelInvite}
        closeModal={closeModalInvite}
        selectRow={selectInviteRow}
        orgId={eventOrganizerId}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
