import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const TableComponent = ({ selectRow }) => {
  const [yachtData, setyachtData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("name");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    iconPackManagemnt();
  }, [currentPage]);

  useEffect(() => {
    iconPackManagemnt();
  }, [sizePerPageOrg, sort]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img src={row.image} alt="logo img" width={"50px"} />
      </>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Name",
      sort: false,
    },  

    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "select",
      text: "Select",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => selectRow(row)}
          >
            <i class="fal fa-plus  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    
  };

  const handleSearchClick = () => {
    iconPackManagemnt(); // reload icons
  };



  const iconPackManagemnt = async (page = 0) => {
    setLoader(true);
    const payload = {
      limit: 10000,
      page: 1 
    };

    if (searchText) {
      payload.search = searchText;
    }

  
    const response = await postRequest(`yacht/list`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setyachtData(data.list);
    setLoader(false);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="">
                  <div style={{display: 'flex', marginBottom: "10px", gap:"10px"}}>
                  <input 
                  type="text"                 
                  className="form-control"
                  placeholder="Search..."
                  value={searchText}   
                  onChange={handleSearch}              
                />
                <button className="btn btn-success btn-block" onClick={handleSearchClick}>Search</button>

                  </div>
               


                <div style={{
                  display: 'grid', 
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', 
                  gap: '10px'
                }}>
                  {yachtData.map(icon => (
                    <div style={{textAlign: 'center'}} key={icon._id}>
                      <img src={icon.image} style={{ maxWidth: '100%', maxHeight: '100%',width: '80px', height: '80px', }}  alt="Icon" />
                      <button style={{marginTop:"10px"}} type="button" className="btn btn-danger" onClick={() => selectRow(icon)}>
                        <i class="fal fa-plus fs-5"></i>
                      </button>
                    </div>
                  ))}
                </div>
                
              </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const SelectyachModal = ({ show, closeModal, selectRow }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          yachtData Pack
        </h5>

        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent selectRow={selectRow} />
      </div>
    </Modal>
  );
};

export default SelectyachModal;
