import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

import {
  postRequest,
  putRequest,
  putRequestForm,
} from "../../components/Common/Utils.js";

import { ToastContainer, toast } from "react-toastify";
import CategoryModal from "components/Modal/CategoryModal.js";
import OfferModal from "components/Modal/OfferModalOrg.js";
import VenueModal from "components/Modal/VenueModalOrg.js";
import SelectUserList from "components/Common/SelectUserList.js";
import NotificationUsers from "./NotificationUserListTable.js";

export default function Notificationdetails() {
  const nav = useHistory();
  const [notificationData, setNotificationData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  // let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const msUpdateTarget = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTarget(ids);
  };

  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState();

  const [model, setModel] = useState(false);
  const [categoryModel, setCategoryModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false); 

  useEffect(() => {
    fetchState();
  }, []);

  // const fetchTarget = async () => {
  //   const param = { page: 1, limit: 100000 };
  //   const theme = await postRequest("theme/list", param);
  //   if (theme.status == 1) {
  //     const options = theme.data.list.map((item) => {
  //       return { id: item._id, name: item.title };
  //     });
  //     setUserList(options);
  //   }
  // };

  const fetchState = async () => {
    const data = { ...state };
    setNotificationData(data);
    setLoading(false);
  };

  //   let search = state._id;
  //   let finalsearch = search.toString();

  

  const updateNotificationData = async (e) => {
    e.preventDefault();
    if (!notificationData.title) {
      toast.error("Title is required");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("notificationId", notificationData._id);
    formData.append("title", notificationData.title);
    formData.append("description", notificationData.description);
    formData.append("scheduled", notificationData.scheduled);

    if (notificationData?.scheduled === 1) {
      formData.append("date", notificationData.date);
    }
    if (notificationData?.scheduled === 1) {
      formData.append("time", notificationData.time);
    }
    formData.append("platform", notificationData.platform);
    formData.append("type", notificationData.type);

    if (notificationData?.type === "link") {
      formData.append("link", notificationData.link);
    }
    formData.append("targetUser", notificationData.targetUser);
    if (notificationData?.targetUser === "all") {
      formData.append("users", []);
    }

    if (notificationData?.targetUser === "specific") {
      const users = [];

      selectedTarget.forEach((item) => {
        users.push(item?.id);
      });
      formData.append("users", users);
    }
    formData.append("status", notificationData.status);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    if (notificationData?.type === "venue") {
      formData.append("venueId", venueId);
    }
    if (notificationData?.type === "category") {
      formData.append("categoryId", categoryId);
    }
    if (notificationData?.type === "offer") {
      formData.append("offerId", offerId);
    }

    // return;

    const response = await putRequestForm("user/notification/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      // redirect to list page
      setTimeout(() => {
        nav.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  

  const fetchUsers = async () => {
    const param = { page: 1, limit: 100000 };
    const user = await postRequest("user/list/admin", param);
    if (user.status == 1) {
      // const selected = user.data.list.map((item) => {

      // });
      // setSelectedTarget(selected);
      const options = user.data.list.map((item) => {
        return {
          id: item._id,
          phone: item.phone,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
        };
      });
      setUserList(options);
    }
  };

  useEffect(() => {
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchUsers();
    setCategoryId(notificationData?.typeId);
    setVenueId(notificationData?.typeId);
    setOfferId(notificationData?.typeId);
    if (state.type === "venue") fetchVenueList();
    if (state.type === "offer") fetchOfferList();
    if (state.type === "category") fetchCategoryList();
  }, []);

  const fetchVenueList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
      setVenueText(
        response.data.list?.find((x) => x._id === state.venueId)?.name
      );
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
      setOfferText(
        response.data.list?.find((x) => x._id === state.offerId)?.title
      );
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setCategoryList(response.data.list);
      setCategoryText(
        response.data.list?.find((x) => x._id === state.categoryId)?.title
      );
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };
  const selectRow = (id, name) => {
    setVenueId(id);
    setVenueText(name);
    closeModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };
  const selectOfferRow = (id, name) => {
    setOfferId(id);
    setOfferText(name);
    closeOfferModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };
  const closeCategoryModal = () => {
    setCategoryModel(false);
  };
  const selectCategoryRow = (id, name) => {
    setCategoryId(id);
    setCategoryText(name);
    closeCategoryModal();
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Notifications</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Notifications</li>:{" "}
              <li className="breadcrumb-item">Notification details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </Row>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Notification Details </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              readOnly
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={notificationData?.title}
                              name="title"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-9">
                            <input
                              readOnly
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={notificationData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-9">
                            {previewImage && (
                              <img
                                src={previewImage}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}

                            {imageIcon && (
                              <img
                                src={imageIcon}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Scheduled
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={location?.state.row.scheduled}
                              onChange={(e) =>
                                updateValue("scheduled", Number(e.target.value))
                              }
                              disabled
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                        {notificationData?.scheduled === 1 && (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Date
                              </label>
                              <div className="col-sm-9">
                                <input
                                  value={notificationData?.date}
                                  readOnly
                                  type="date"
                                  onChange={(e) =>
                                    updateValue("date", e.target.value)
                                  }
                                  name="date"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Time
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="time"
                                  readOnly
                                  onChange={(e) =>
                                    updateValue("time", e.target.value)
                                  }
                                  value={notificationData?.time}
                                  name="time"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-9">
                            <select
                              onChange={(e) =>
                                updateValue("platform", e.target.value)
                              }
                              disabled
                              value={notificationData?.platform}
                              className="form-select"
                            >
                              <option value="">Select Platform</option>
                              <option value="android">Android</option>
                              <option value="ios">IOS</option>
                              <option value="huawei">Huawei</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={notificationData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              disabled
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="general">General</option>
                              <option value="link">Link</option>
                              <option value="category">Category</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div>

                        {notificationData?.type === "link" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Link
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                readOnly
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                defaultValue={notificationData?.link}
                                name="link"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>

                            <div className="col-sm-9">
                              {venueText !== "" ? (
                                <div
                                  defaultValue={notificationData?.venueId}
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {notificationData?.venueName}
                                    </a>
                                  </div>
                                  <div></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select Venue
                                    </a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-9"
                              defaultValue={notificationData?.offerId}
                            >
                              {offerText !== "" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {notificationData?.offerName}
                                    </a>
                                  </div>
                                  <div></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select Offer
                                    </a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "category" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>

                            <div className="col-sm-9">
                              {categoryText !== "" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      defaultValue={
                                        notificationData?.categoryText
                                      }
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {notificationData?.categoryName}
                                    </a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #ced4da",
                                    padding:
                                      " 0.375rem 1.75rem 0.375rem 0.75rem",
                                    borderRadius: "0.25rem",
                                    lineHeight: 1.5,
                                  }}
                                >
                                  <div style={{ minWidth: "100%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      style={{
                                        textDecoration: "none",
                                        color: "#5b626b",
                                        fontSize: "0.8125rem",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Select Category
                                    </a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i
                                        style={{ color: "#5b626b" }}
                                        class="fa fa-plus"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Target User
                          </label>
                          <div className="col-sm-9">
                            <select
                              onChange={(e) =>
                                updateValue("targetUser", e.target.value)
                              }
                              disabled
                              value={notificationData?.targetUser}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="all">All User</option>
                              <option value="specific">Specific User</option>
                            </select>
                          </div>
                        </div>
                        {notificationData?.targetUser === "specific" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Specific Users
                            </label>
                            <div className="col-sm-9">
                              <NotificationUsers
                                notification={notificationData?._id}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={notificationData?.status}
                              onChange={(e) =>
                                updateValue("status", Number(e.target.value))
                              }
                              disabled
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="true">Published</option>
                              <option value="false">Unpublished</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectOfferRow={selectOfferRow}
      />
      <CategoryModal
        show={categoryModel}
        closeCategoryModal={closeCategoryModal}
        selectCategoryRow={selectCategoryRow}
      />
    </React.Fragment>
  );
}
