import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData, currentPackageData }) => {
  console.log("currentPackageData", currentPackageData);

  const [packageData, setPackageData] = useState(currentPackageData || {});

  const [Discount, setDiscount] = useState(0);

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const addDataPackage = (e) => {
    //e.preventDefault();

    if (!packageData.title) {
      alert("Title is required");
      return false;
    }

    if (!packageData.amount) {
      alert("Please enter amount");
      return false;
    }

    if (packageData.amount) {
      if (packageData.amount < 1) {
        alert("Please enter valid amount");
        return false;
      }
    }

    if (packageData.discount > 0) {
      // check discount less then 5 or greater then 99

      if (packageData.discount < 5 || packageData.discount > 99) {
        alert("Please enter valid discount (5-99)");
        return false;
      }
    }

    // if(!packageData.discount){
    //   alert("Please enter discount");
    //   return false;
    // }

    // if (packageData.discount) {
    //   if (packageData.discount < 5 || packageData.discount > 99) {
    //     alert("Please enter valid discount");
    //     return false;
    //   }
    // }

    // if (packageData.claimCode){
    //   if (packageData.claimCode.length != 8) {
    //     alert("Please enter 8 digit claim code");
    //     return false;
    //   }

    //   if (/^[A-Za-z]{2}\d{6}$/.test(packageData.claimCode) === false) {
    //     alert("Please enter valid claim code format (WY123456)");
    //     return false;
    //   }
    // }

    // if (packageData.qty) {
    //   if (packageData.qty < 1) {
    //     alert("Please enter valid qty");
    //     return false;
    //   }
    // }

    if (packageData.qty) {
      if (packageData.qty < 1) {
        alert("Please enter valid qty");
        return false;
      }
    }

    if (packageData.leftQtyAlert) {
      if (packageData.leftQtyAlert < 1) {
        alert("Please enter valid left qty alert");
        return false;
      }
    }

    // if (packageData.qty < packageData.leftQtyAlert) {
    //   alert("can not set alert less than qty");
    //   return false;
    // }

    // if (packageData.pricePerBrunch) {
    //   alert("Please enter price per brunch");
    //   return false;
    // }

    // pricePerBrunch is less than amount
    if (packageData.pricePerBrunch > packageData.amount) {
      alert("Claim amount should be less than amount");
      return false;
    }

    if (packageData.pricePerBrunch < 0) {
      alert("Please enter valid price per brunch");
      return false;
    }

    if (packageData.package_type === "group") {
      // check buy qty and get qty

      if (packageData.buyQty < 1) {
        alert("Please enter valid buy qty");
        return false;
      }

      if (packageData.getQty < 1) {
        alert("Please enter valid get qty");
        return false;
      }
    }

    // if(packageData?.discount=0||null){
    //   ClaimAmount();
    // }
    const packData = { ...packageData };
    addData(packageData);
    packData.title = "";
    packData.packages_description = "";
    packData.isFeatured = false;
    packData.qty = 0;
    packData.leftQtyAlert = 0;
    packData.pricePerBrunch = 0;
    packData.amount = 0;
    packData.discount = 0;

    // packData.claimCode = 0;
    //setPackageData(packData);
  };

  const Percentagemange = (e) => {
    let value = e.target.value;
    console.log({ value });
    if (value == 0) {
      setDiscount(0);
    } else {
      setDiscount(5);
    }
  };

  const ClaimAmount = (e) => {
    e.preventDefault();

    let presentVenue = (packageData.amount / 100) * packageData.discount;

    presentVenue = presentVenue.toFixed(2);

    console.log("Present Venue:", presentVenue);

    if (packageData?.pricePerBrunch >= presentVenue) {
      Swal.fire({
        title: "Are you sure?",
        text: "Claim amount exceeds applied discount. Please double-check for accuracy.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Overwrite!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          addDataPackage();
          console.log("User confirmed!");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked "No, cancel!" or outside the modal
          console.log("User canceled!");
        }
      });
    }

    // else if(!packageData.discount)  {
    //   Swal.fire({
    //     title: "Discount Not Selected",
    //     text: "Please select a discount before proceeding.",
    //     icon: "error",
    //   });
    // }
    else if (packageData.discount) {
      addDataPackage();
    } else {
      addDataPackage();
    }
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="">
                <Form onSubmit={ClaimAmount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("package_type", e.target.value)
                              }
                              value={packageData?.package_type || "regular"}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="regular" selected>
                                Regular
                              </option>
                              <option value="group">Group</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              required
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue(
                                  "packages_description",
                                  e.target.value
                                )
                              }
                              value={
                                packageData?.packages_description ||
                                packageData?.description
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={packageData?.amount}
                              name="amount"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount In Percentage
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) => {
                                updateValue("discount", e.target.value),
                                  Percentagemange(e);
                              }}
                              value={packageData?.discount}
                              name="discount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Claim Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              required
                              onChange={(e) => {
                                const inputClaimCode = e.target.value;
                                // Check if the input is a 4-digit number                                
                                  updateValue("claimCode", inputClaimCode);                                                           
                              }}
                              value={packageData?.claimCode}
                              name="claimCode"                             
                              className="form-control"
                              placeholder="WY123456"
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            is Featured ?
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isFeatured}
                              onChange={(e) =>
                                updateValue(
                                  "isFeatured",
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Sell
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isAllowSale}
                              onChange={(e) =>
                                updateValue("isAllowSale", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        {packageData.isAllowSale && (
                          <div>

                            {packageData.package_type === "group" && (
                              <>
                                 <div
                                 className="row mb-4 d-flex"
                                //  style={{
                                //   display: packageData.isAllowClaim
                                //     ? "none"
                                //     : "flex",
                                // }}                                 
                               >
                                 <label className="col-sm-3 col-form-label">
                                   Buy QTY
                                 </label>
                                 <div className="col-sm-6">
                                   <input
                                     type="number"
                                     min={1}
                                     onChange={(e) =>
                                       updateValue("buyQty", e.target.value)
                                     }
                                     value={packageData?.buyQty}
                                     // value={
                                     //   packageData?.qty === undefined
                                     //     ? 0
                                     //     : packageData.qty
                                     // }
                                     name="buyQty"
                                     className="form-control"
                                   />
                                 </div>
                               </div>


                               <div
                                 className="row mb-4 d-flex"   
                                //  style={{
                                //   display: packageData.isAllowClaim
                                //     ? "none"
                                //     : "flex",
                                // }}                              
                               >
                                 <label className="col-sm-3 col-form-label">
                                   Get QTY
                                 </label>
                                 <div className="col-sm-6">
                                   <input
                                     type="number"
                                     min={1}
                                     onChange={(e) =>
                                       updateValue("getQty", e.target.value)
                                     }
                                     value={packageData?.getQty}
                                     // value={
                                     //   packageData?.qty === undefined
                                     //     ? 0
                                     //     : packageData.qty
                                     // }
                                     name="getQty"
                                     className="form-control"
                                   />
                                 </div>
                               </div>
                               </>
                            )}

                            <div
                              className="row mb-4 d-flex"
                              // style={{
                              //   display: packageData.isAllowClaim
                              //     ? "none"
                              //     : "flex",
                              // }}
                            >
                              <label className="col-sm-3 col-form-label">
                                QTY
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={1}
                                  onChange={(e) =>
                                    updateValue("qty", e.target.value)
                                  }
                                  value={packageData?.qty}
                                  // value={
                                  //   packageData?.qty === undefined
                                  //     ? 0
                                  //     : packageData.qty
                                  // }
                                  name="qty"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div
                              className="row mb-4 d-flex"
                              // style={{
                              //   display: packageData.isAllowClaim
                              //     ? "none"
                              //     : "flex",
                              // }}
                            >
                              <label className="col-sm-3 col-form-label">
                                Left Qty Alert On
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={1}
                                  max={packageData?.qty}
                                  onChange={(e) =>
                                    updateValue("leftQtyAlert", e.target.value)
                                  }
                                  value={packageData?.leftQtyAlert}
                                  // value={
                                  //   packageData?.qty === undefined
                                  //     ? 0
                                  //     : packageData.qty
                                  // }
                                  name="qty"
                                  className="form-control"
                                />
                              </div>
                            </div>            


                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Claim
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isAllowClaim}
                              onChange={(e) =>
                                updateValue("isAllowClaim", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        {packageData.isAllowClaim && (
                          <div>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                is Packages Free ?
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="checkbox"
                                  checked={packageData.isFree}
                                  onChange={(e) =>
                                    updateValue(
                                      "isFree",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>

                            {packageData.isFree ? (
                              ""
                            ) : (
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Claim Amount
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="number"
                                    required
                                    onChange={(e) => {
                                      // Check if the entered value is negative
                                      const newValue = Math.max(
                                        0,
                                        e.target.value
                                      );
                                      updateValue("pricePerBrunch", newValue);
                                    }}
                                    value={packageData?.pricePerBrunch}
                                    name="pricePerBrunch"
                                    className="form-control"
                                    min="1"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Featured
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isFeatured}
                              onChange={(e) =>
                                updateValue("isFeatured", e.target.checked)
                              }
                            />
                          </div>
                        </div> */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const PackageModal = ({ show, closeModal, addData, currentPackageData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Package Info
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPackageData={currentPackageData}
        />
      </div>
    </Modal>
  );
};

export default PackageModal;
