import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


const FileUpload = ({ message = "Uploading Files", status = 0 }) => {
    const isOpen = status;
  
    return (
      <Modal isOpen={isOpen} centered>        
        <ModalBody>
          <div className="text-center">
            {/* Replace with your loading animation GIF */}
            <img style={{height: "300px"}} src="https://assets.materialup.com/uploads/c8a1e109-dca0-4b9e-9aa6-1e339b5ba903/preview.gif" alt="Loading..." />
            <p>{message}</p>
          </div>
        </ModalBody>
      </Modal>
    );
  };
  
  export default FileUpload;