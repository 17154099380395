import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData, currentPackageData }) => {
  console.log("currentPackageData", currentPackageData);

  const [packageData, setPackageData] = useState(currentPackageData || {});

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const ClaimAmount = (e) => {
    e.preventDefault();

    if (!packageData.title) {
      alert("Title is required");
      return false;
    }
    if (!packageData.description) {
      alert("Title is required");
      return false;
    }

    if (!packageData.amount) {
      alert("Please enter amount");
      return false;
    }

    if (packageData.amount) {
      if (packageData.amount < 1) {
        alert("Please enter valid amount");
        return false;
      }
    }

    const packData = {
      ...packageData,
      slots: timeSlots,
    };

    addData(packData);

    packData.title = "";
    packData.packages_description = "";
    packData.amount = 0;
    packData.slots = timeSlots;
    console.log(packData, packageData);
  };

  const [timeSlots, setTimeSlots] = useState(currentPackageData?.slots || []);

  const addSlot = () => {
    const initialTimeSlots = timeSlots || [];
    setTimeSlots([...initialTimeSlots, { availabilityTime: "00:00-00:00" }]);
  };

  const updateSlot = (index, type, value) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][type] = value;
    setTimeSlots(updatedSlots);
  };

  const deleteSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };
  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="">
                <Form onSubmit={ClaimAmount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              required
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={packageData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={packageData?.amount}
                              name="amount"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              value={packageData?.discount}
                              name="discount"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Slots
                          </label>

                          <div className="col-sm-8">
                            {timeSlots?.map((slot, index) => (
                              <div className="col-sm-12" key={index}>
                                <div
                                  class="row"
                                  style={{ marginBottom: "16px" }}
                                >
                                  <div className="col-lg-3 px-1">
                                    <input
                                      type="time"
                                      min="00:00"
                                      className="form-control"
                                      value={
                                        slot.availabilityTime.split("-")[0]
                                      }
                                      onChange={(e) => {
                                        const newStartTime = e.target.value;
                                        const newEndTime =
                                          slot.availabilityTime.split("-")[1];
                                        if (newEndTime < newStartTime) {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newStartTime}`
                                          );
                                        } else {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newEndTime}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-3 px-1">
                                    <input
                                      type="time"
                                      min={slot.availabilityTime.split("-")[0]}
                                      className="form-control"
                                      value={
                                        slot.availabilityTime.split("-")[1]
                                      }
                                      onChange={(e) => {
                                        const newEndTime = e.target.value;
                                        const newStartTime =
                                          slot.availabilityTime.split("-")[0];
                                        if (newEndTime < newStartTime) {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newStartTime}`
                                          );
                                        } else {
                                          updateSlot(
                                            index,
                                            "availabilityTime",
                                            `${newStartTime}-${newEndTime}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-sm-3">
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={() => deleteSlot(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <button
                              type="button"
                              style={{
                                color: "white",
                                backgroundColor: "green",
                                border: "none",
                                padding: "10px 20px",
                              }}
                              onClick={addSlot}
                            >
                              Add Slot
                            </button>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const YachtOfferAddModal = ({
  show,
  closeModal,
  addData,
  currentPackageData,
}) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Package Info
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPackageData={currentPackageData}
        />
      </div>
    </Modal>
  );
};

export default YachtOfferAddModal;
