import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import moment from "moment";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  postRequest,
  deleteRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
const filterObj = {
  title: "",
  status: "",
  createdAt: "",
};

const Eventlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [eventdata, seteventdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [resetSearch, setResetSearch] = useState(false);
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    eventManagment();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage == 1) {
    eventManagment();
    return;
    }
    setCurrentPage(1);
  }, [sizePerPageOrg, sort, sortOrder]);
  
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };
  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "business_name") {
          var filterObject = {
            "business_id.name": filter["business_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (key === "title" || key === "phone" || key === "email") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    eventManagment(filterArgs);
  };
  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    eventManagment();
  };

  function Eventtime(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.event_time)}        
      </div>
    );
  }
  function Reservationtime(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.reservation_time)}        
      </div>
    );
  }
  function dateFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.createdAt)}        
      </div>
    );
  }
  function CreatedBy(cellContent, row) {
    let created_by = row.userName;
    if (row.user_type === "org") {
      created_by = row.orgName;
    }
    else{
      created_by = `${row?.user?.first_name} ${row?.user?.last_name}`;
    }
    return (
      <React.Fragment>
        <div>
          <p>{created_by} </p>
        </div>
      </React.Fragment>
    );
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
    }, 
    {
      text: "Created By",
      formatter: CreatedBy,
      sort: false,
    },
    {
      dataField: "user_type",
      text: "User Type",
      sort: false,
    },

    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    // {
    //   dataField: "event_time",
    //   text: "Event Time",
    //   formatter: Eventtime,
    //   sort: false,
    // },
    // {
    //   dataField: "reservation_time",
    //   text: "Reservation Time",
    //   formatter: Reservationtime,
    //   sort: false,
    // },

    {
      dataField: "venue_type",
      text: "Venue Type",
      sort: false,
    },
    

    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },

    {     
      text: "Status",
      formatter: statusFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");   
  };
  function statusFormatter(cellContent, row) {
    return (
      <div style={{}}>
        {row?.event_status === "upcoming" && (
          <div
            class="badge"
            style={{
              backgroundColor: "rgb(19 203 44)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.event_status}
          </div>
        )}
        {row?.event_status === "completed" && (
          <div
            class="badge"
            style={{
              backgroundColor: "#29BCE3",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.event_status}
          </div>
        )}
        {row?.event_status === "cancelled" && (
          <div
            class="badge"
            style={{
              backgroundColor: "#EC536C",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.event_status}
          </div>
        )}
      </div>
    );
  }
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">

        {/* <Tippy content="View Tracking">
            <button type="button" className=" btn btn-warning mx-1">
              <Link
                to={{
                   pathname: `/eventGraphdetails/${row._id}`,
                  state: { row },
                }}
              >
                <i className="fal fa-chart-line fs-5 text-light"></i>
              </Link>
            </button>
          </Tippy>            */}
        <Tippy content="Detail">
          <button type="button" className=" btn btn-primary text-light  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/eventdetails/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          <Tippy content="Invite List">
          <button type="button" className=" btn btn-secondary mx-1">
            <Link
              to={{
                pathname: `/inviteList/${row._id}`,
                state: { row },
              }}
            >
              <i className="fal fa-user-plus fs-5 text-light"></i>
            </Link>
          </button>
          </Tippy>
          <Tippy content="Edit">
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/updateevents/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const eventManagment = async (filterArgs = []) => {
    setLoader(true);

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
   try{
    const response = await postRequest(`event/list`, payload);
    const data = response.data;
    if(resetSearch){
      setResetSearch(false);
    }
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    seteventdata(data.list);
   }catch(error){
    console.error("Failed to fetch data:", error);
   }finally{
    setLoader(false);
   }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id) => {
    const response = await deleteRequest("event/remove", {
      event_id: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      eventManagment();
    } else {
      Swal.fire({
        title: "Event Contains Order",
        text: "This event may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction2(id);
          
        }
      });
    }
  };

  const deleteFunction2 = async (id) => {
    const response = await deleteRequest("event/remove", {
      event_id: id,
      isForced: true
    });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  }; 

  const [itemsdata, setItems] = useState([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoader(true);
    const themes = await postRequestForm(
      "venue/theme/list?page=0&limit=1000&sortBy=first_name&sortOrder=desc&q="
    );

    setItems(themes.data.list);   
    // fetchState();
    setLoader(false);
  };
  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You want to delete this event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("event/remove", {
      ids: ids,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      eventManagment();
      setselectedItems([]);
    } else {
      Swal.fire({
        title: "Event Contains Order",
        text: "This event may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction3(ids);
          
        }
      });
    }
  };
  const deleteFunction3 = async (ids) => {
    const response = await deleteRequest("event/remove", {
      ids: ids,
      isForced: true
    });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  }; 


  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Event Management</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Event Management</li>:{" "}
                <li className="breadcrumb-item">Event Management List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
          <Link to="/eventDelete">
              {" "}
              <Button style={{ backgroundColor: '#ec536c' }}>
                {" "}
                <i class="fas fa-trash"></i> Deleted List
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/createevents">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={eventdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="title"> Title</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        eventManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    // classes="table-custom-striped"
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default Eventlist;
