import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import MultiSelect from "components/Multiselect/MultiselectCommon.js";

import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import ActivityProviderModal from "components/Modal/ActivityProviderModal.js";
import ActivityTypeModal from "components/Modal/ActivityTypeModal.js";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import IconPackModal from "components/Modal/IconPackModal.js";

import FileDrop from "components/FileDrop.js";
import SelectyachModal from "components/Modal/SelectyachModal.js";
import AddOnModal from "components/Modal/AddOnModal.js";

export default function AddPackagesYach() {
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [packages, setPackages] = useState([]);

  const [AddOnId, setAddOnId] = useState([]);

  const [eventOrganizerId, setEventOrganizerId] = useState("");
  const [eventOrganizerText, setEventOrganizerText] = useState("");

  const [YachtId, setYachtId] = useState("");
  const [providerText, setProviderText] = useState("");
  const [providerImage, setProviderImage] = useState("");

  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");

  const [model, setModel] = useState(false);
  const [providerModel, setProviderModel] = useState(false);
  const [orgModal, setOrgModal] = useState(false);
  const [iconModal, setIconModal] = useState(false);

  const [disableType, setDisableType] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [daysSelected, setDaysSelected] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const [timeSlots, setTimeSlots] = useState([]);

  let today = new Date();

  // Create a new Date object for tomorrow based on today
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Convert to ISO string and split to get only the date part
  today = today.toISOString().split("T")[0];
  tomorrow = tomorrow.toISOString().split("T")[0];

  const [regularTime, setRegularTime] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (eventData?.user_type == "user") {
      // set type into private & disable type dropdown

      const data = { ...eventData };
      data.type = "private";
      setEventData(data);
      setDisableType(true);
    } else {
      setDisableType(false);
    }
  }, [eventData?.user_type]);

  let history = useHistory();

  const AddPackagesYachData = async (e) => {
    e.preventDefault();
    if (!eventData.title) {
      toast.error("Title is required");
      return;
    }

    if (!eventData.description) {
      toast.error("Please enter description");
      return;
    }

    console.log({ eventData });

    if (!eventData?.amount) {
      toast.error("Please enter amount");
      return;
    }

    // price will be positive

    if (eventData?.amount < 1) {
      toast.error("amount must be greater than 0");
      return;
    }

    if (!eventData.discount) {
      toast.error("Please enter discount");
      return;
    }

    if (eventData.discount < 5) {
      toast.error("Discount must be 5 or greater than 5");
      return;
    }

    if (eventData.discount > 99) {
      toast.error("Discount must be less than 100");
      return;
    }

    if (!YachtId) {
      toast.error("Please select Yacht");
      return;
    }

    if (!regularTime) {
      toast.error("Please select Start Time / End Time");
      return;
    }
    if (!AddOnId) {
      toast.error("Please select Add On");
      return;
    }
    if (!packages) {
      toast.error("Please select whats Include ");
      return;
    }

    eventData.yachtId = YachtId;
    eventData.addOn = AddOnId?.map((item)=>item?._id);
    eventData.whatsInclude = packages;
    eventData.time = "2024-03-26T06:11:42.449+00:00";

    setLoading(true);

    const response = await postRequest("yacht/add/package", eventData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/Yacht-Package-Management-list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleProviderModel = () => {
    setProviderModel(!providerModel);
  };

  const toggleOrgModel = () => {
    setOrgModal(!orgModal);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeProviderModal = () => {
    setProviderModel(false);
  };

  const closeOrgModal = () => {
    setOrgModal(false);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const selectIconRow = (icon) => {
    console.log({ icon });
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const AddOnselectIconRow = (icon) => {
     console.log({icon})
    setAddOnId((prevState) => [...prevState, icon]);

    closeModal();
  };

  const selectProviderRow = (id) => {
    console.log({ id });
    setYachtId(id?._id);
    setProviderText(id?.name);
    setProviderImage(id?.image);
    closeProviderModal();
  };


 
  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };



  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const deleteAddon = (index) => {
    const newPackages = AddOnId.filter((item, i) => i !== index);
    setAddOnId(newPackages);
  };







  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Yacht Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Yacht Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Create Yacht Packages</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Create Yacht Packages </h4>
                <Form onSubmit={AddPackagesYachData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={eventData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              defaultValue={eventData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              defaultValue={eventData?.amount}
                              name="amount"
                              className="form-control"
                              min={0}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Discount (In Percent)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              defaultValue={eventData?.discount}
                              name="discount"
                              className="form-control"
                              min={0}
                              max={100}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Yacht
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleProviderModel()}
                          >
                            {providerText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     {providerText}
                              //   </a>
                              // </div>
                              // <div>
                              // <a
                              //     onClick={() => toggleProviderModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityBtn"
                                  onclick={() => toggleProviderModel()}
                                >
                                  Choose Yacht
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={providerImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={providerText}
                                  id="selectedActivity"
                                  placeholder="No Yacht chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     Select Provider
                              //   </a>
                              // </div>
                              // <div>
                              //   <a
                              //     onClick={() => toggleProviderModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityBtn"
                                  onclick={() => toggleProviderModel()}
                                >
                                  Choose Yacht
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedActivity"
                                  placeholder="No Yacht chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
{/* 
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Start Time / End Time
                          </label>
                          <div className="col-sm-3">
                            <label>Start Time:</label>
                            <input
                              type="time"
                              onChange={(e) =>
                                updateRegulaTime("start", e.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label>End Time:</label>
                            <input
                              type="time"
                              onChange={(e) =>
                                updateRegulaTime("end", e.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Add On
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Icon</th>
                                  <th>Title</th>
                                  <th>Price</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AddOnId.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.image && (
                                            <img
                                             
                                              src={item.image}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.title}`}
                                        readOnly
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.price}`}
                                        readOnly
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                          border: "none",
                                          padding: "10px 20px",
                                        }}
                                        onClick={() => deleteAddon(index)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={toggleModel}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            whats Include
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      <SelectyachModal
        show={providerModel}
        closeModal={closeProviderModal}
        selectRow={selectProviderRow}
      />

      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />

      <AddOnModal
        show={model}
        closeModal={closeModal}
        selectRow={AddOnselectIconRow}
      />
    </React.Fragment>
  );
}
