import React , {useEffect ,useRef, useState} from "react"

import { CountUp } from 'countup.js';

import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Button
} from "reactstrap"

import { postRequest} from "../../../components/Common/Utils";

// import ActiveSubscription from "../../../components/Common/ActiveSubscription";

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ChartComponets from "./ChartData.js";
// import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import VenueAnalytics from "./VenueAnalytics";
import OfferAnalytics from "./OfferAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";

import DashCard from "./DashCard";
import Calendar from "./Calendar";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";



const Dashboard = (props) => {
   

    const [dashCardStat, setDashCardStat] = React.useState([]);

    const [salesData, setSalesData] = React.useState([]);

    const [countryWiseData, setCountryWiseData] = React.useState([]);
    const [activePlan, setActivePlan] = useState(true);
    const [activeAnalytic, setActiveAnalytic] = useState(false);

    const [ calendarEvents, setCalendarEvents ] = React.useState([]);

    const [pieChartData, setPieChartData] = React.useState({});

    const [claimedAmount, setClaimedAmount] = React.useState(null);

    const [analyticsData, setAnalyticsData] = React.useState(null);  

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  useEffect(() => {
    const fetchActivePlan = async () => {
      setLoader(true);

      const businessId = localStorage.getItem("stored_businessId");

      const payload = {
        businessId: businessId // ensure you pass venueId as a prop
      };
      try {
        const response = await postRequest(`subscription/fetch-venue-subscription`, payload);
        const data = response?.data;       

        // if (data != null) {
        //   setActivePlan(data);
        // }

        if(data?.subscription){
          setActivePlan(true);
          setActiveAnalytic(data?.subscription?.haveAnalytics || false);

         
        }else{
          setActivePlan(false);
        }

      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchActivePlan();
  }, []);

  const DashboardStat = async () => {    

    const bid = localStorage.getItem("stored_bussinessId");

    postRequest(`user/admin/dashboard-data`, {bid} ).then((data) => {
      let Counts = data?.data;  
      setDashCardStat(Counts.dashCard);
      let tempSales = [...salesData];
      tempSales = Counts.salesData;
      setSalesData(tempSales);
      setCountryWiseData(Counts.countryWiseData);
      setCalendarEvents(Counts.calendarData);
      setPieChartData(Counts.pieChartData);


      let tempClaimedAmount = {...claimedAmount};
      tempClaimedAmount = Counts.claimedAmount;

      console.log('tempClaimedAmount', tempClaimedAmount);

      setClaimedAmount(tempClaimedAmount)


      let tempStat = {...analyticsData};
      tempStat = Counts.logstat;
      setAnalyticsData(tempStat);
      
    });
  };

  useEffect(() => {
    DashboardStat();
  }, []); 

 

  return (
    <React.Fragment>

    
<Row>
<Col className='12'>
            <div className="page-title-box">
              <h4>Dashboard </h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Dashboard</li>
                : <li className="breadcrumb-item">Dashboard Counter</li>
              </ol>
            </div>
          </Col>

          {/* <div className="col-md-2 text-end">
            <Link to="/graph">
              {" "}
              <Button>
                
                {" "}
                <i class="fal fa-chart-line fs-5 text-light"></i> View Graph
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> */}


      


<Row>


  <Col className='3' xl="3">
  
  </Col>

  {/* <Col className='12' xl="12">
    <ActiveSubscription />
  </Col> */}

  <Col className='3' xl="3">
  
  </Col>



 



            <DashCard values={dashCardStat} />


            {salesData.map((item, index) => (
                <Col key={index} className='4' xl="4">
                    <YearlySales
                        data={item.data}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        count={item.count || 0}
                    />
                </Col>
            ))}

  

</Row>



      <Row>
                            <Col xl={12}>
                                {/* <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                    
                                </Row> */}
                                {/* revenue Analytics */}
                                <Row>
                                  { analyticsData && ( <RevenueAnalytics data={analyticsData} /> ) }
                                </Row>

                                <Row>

                                  <VenueAnalytics /> 
                                </Row>

                                <Row>

                                 <OfferAnalytics /> 

                                </Row>
                            </Col>                            
                        </Row>
                        
                        <Row>
                            {/* sources */}
                            {/* <Sources/> */}

                            {/* recent activity */}
                            {/* <RecentlyActivity/> */}

                            {/* revenue by locations */}

                            {/* <Col xl={12}>
                              <RevenueByLocations countryData={countryWiseData}/>
                            </Col> */}

                            


                            

                            
                        </Row> 

                        <Row>
                                <Col xl={12}>
                                    <Calendar events={calendarEvents} />                                    
                                </Col>

                                {/* <Col xl={3}>
                                  <SalesAnalytics data={pieChartData}/>
                                </Col> */}
                          </Row>  
        

                          <Row>
         <Col xl="12">

         { claimedAmount && <MonthlyEarnings2 data={claimedAmount} /> }

         
        </Col>

      </Row>

</Row>

      {/* <Row>

        <Col xl="4" lg="6">
          
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
         
          <RecentActivity />

        </Col>
        <Col xl="4">
          
          <WidgetUser />

         
          <YearlySales />
        </Col>
      </Row> */}

      

      <Row>
        {/* <Col xl="6">
         
          <LatestTransactions />
        </Col> */}

        {/* <Col xl="6">
         
          <LatestOrders />
        </Col> */}
      </Row>
      {/* <h3 style={{ textAlign: "center" }}>Custom Chart</h3> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
      <ChartComponets /> */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);