import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Table,
  Button,
  Modal,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  postRequest,
  postRequestForm,
  postCustomRequestForm,
  pushUpdates,
  postRequestOuting,
} from "../../components/Common/Utils.js";
import icon1 from "../../assets/images/icon1.png";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Select from "react-select";

import VenueModal from "../../components/Modal/VenueModal.js";
import OfferModal from "../../components/Modal/OfferModal.js";
import ActivityModal from "../../components/Modal/ActivityModalId.js";
import EventModal from "components/Modal/EventModalId.js";
import VoucherModal from "components/Modal/VoucherModal.js";

import noImage from "../../assets/images/No_Image_Available.jpg";

import SliderModal from "components/Modal/PackageModal.js";
import VideoModal from "components/Modal/VideoModal.js";

import Countdown from "./CountDown.js";
import { set } from "lodash";
import FileUpload from "components/Common/FileUpload.js";
import DragDropModal from "./DragDropModal.js";

export default function CreateHomeBlock() {
  const location = useLocation();
  const state = location.state?.row;

  const [homeData, setHomeBlockData] = useState({});

  // new changes
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [customcomp, setCustomComp] = useState([]);
  // new chanes

  const [categoryList, setCategoryList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [coverLogo, setCoverLogo] = useState([]);
  const [offerListDetail, setOfferListDetail] = useState([]);
  const [venueListDetail, setVenueListDetail] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState([]);
  const [videoMdl, setVideoMdl] = useState(false);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [componentList, setComponentList] = useState([]);

  const [vouchars, setVouchars] = useState([]);

  const [venuegetId, setVenueId] = useState([]);
  const [offergetId, setOfferId] = useState([]);
  const [venuPreview, setVenuPreview] = useState([]);
  const [offerPreview, setOfferPreview] = useState([]);

  const [venueText, setVenueText] = useState("");
  const [idName, setIdName] = useState("venueId");
  const [typecustom, setTypeCustom] = useState(false);
  const [model, setModel] = useState(false);
  const [modelcustom, setModelCustom] = useState(false);
  const [dragDropData, setDragDropData] = useState([]);

  // new changes
  const [customModalComponent, setModelCustomComponent] = useState(false);
  const [modelDeal, setModelDeal] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);
  const [modelVoucher, setModelVoucher] = useState(false);
  const [modelEvent, setModelEvent] = useState(false);
  const [model2, setModel2] = useState(false);

  const [venueoptions, setvenueOptions] = useState([]);
  const [categoryoptions, setcategoryOptions] = useState([]);
  const [offeroptions, setofferOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState([]);
  const [urlCover, setURL] = useState([]);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  const [lowestDiscount, setLowestDiscount] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const [activityPreview, setActivityPreview] = useState([]);
  const [eventPreview, setEventPreview] = useState([]);
  const [activityId, setActivityId] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [startLoader, setStartLoader] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // dragdrop state
  const [showOrder, setShowOrder] = useState(false);

  useEffect(() => {
    fetchList();
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
  }, []);

  const AddUploadedVideo = (video) => {
    setUploadedVideo([...uploadedVideo, video]);
  };

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  };

  const pushSelectedDeal = (deal) => {
    const isExist = selectedDeals.find((x) => x._id === deal._id);

    if (isExist) {
      toast.error("Deal already added");
      return;
    }
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.push(deal);
    setSelectedDeals(selectedDealsCopy);
    closeModalVoucher();
  };

  const popSelectedDeal = (index) => {
    const selectedDealsCopy = [...selectedDeals];
    selectedDealsCopy.splice(index, 1);
    setSelectedDeals(selectedDealsCopy);
  };

  const addVoucher = () => {
    setVouchars([...vouchars, { saved: false }]);
    setEditIndex(vouchars.length);
  };

  const handleInputChange = (e, index, field) => {
    const newVouchers = [...vouchars];
    newVouchers[index][field] = e.target.value;
    setVouchars(newVouchers);
  };

  const closeVideoModal = () => {
    setVideoMdl(false);
  };

  useEffect(() => {
    // need to calculate lowest discount discount is string so we need to convert it to number
    var lowest = 100000;
    if (vouchars.length > 0) {
      lowest = vouchars.reduce((prev, curr) => {
        return parseFloat(prev.discountValue) < parseFloat(curr.discountValue)
          ? prev
          : curr;
      });
    }
    setLowestDiscount(lowest ? lowest.discountValue : "");
  }, [vouchars]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const saveVoucher = (index) => {
    setVouchars(
      vouchars.map((voucher, i) =>
        i === index ? { ...voucher, saved: true } : voucher
      )
    );
    setEditIndex(null);
  };

  const deleteVoucher = (index) => {
    setVouchars(vouchars.filter((_, i) => i !== index));
  };

  const pushOferPackage = (data) => {
    const tempPack = [...packages];
    tempPack.push(data);
    setPackages(tempPack);
  };

  const deletePackage = async (index, id) => {
    if (id) {
      const tempPack = [...packages];
      tempPack.splice(index, 1);
      setPackages(tempPack);
      const response = await deleteRequest("offer/package/delete", {
        packageId: id,
        offerId: state?._id,
      });
      return;
    }

    const tempPack = [...packages];
    tempPack.splice(index, 1);
    setPackages(tempPack);
  };

  const msUpdateDeal = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDeal(ids);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const closeModalSlider = () => {
    setOpen(false);
  };

  // const msUpdateVideo = (selectedList, selectedItem) => {
  //   const ids = selectedList.map((item) => item.id);
  //   setSelectedVideo(ids);
  // };

  const msUpdateComponent = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedComponent(ids);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateOffer = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fetchList();
    fetchVideoList();
    fetchOfferList();
    fetchSizeList();
    fetchComponentList();
    fetchDealList();
    fetchCategoryList();
    fetchCategoryList2();
  }, []);

  const fetchCategoryList2 = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };

  const fetchDealList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/deal/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setDealList(list);
    }
    setLoading(false);
  };

  const fetchComponentList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/custom/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setComponentList(list);
    }
    setLoading(false);
  };

  const fetchSizeList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });

    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setOfferList(list);
      const mappedOptions = response.data.list.map((item) => ({
        value: item._id,
        label: item.title,
      }));
      setofferOptions(mappedOptions);
      setOfferListDetail(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueListDetail(response.data.list);
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
      const mappedOptions = response.data.list;
      setvenueOptions(mappedOptions);
    }
    setLoading(false);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setcategoryOptions(response.data.list);
    }
    setCategoryList(options);

    setLoading(false);
  };

  const fetchVideoList = async () => {
    setLoading(true);
    const response = await postRequest("homeblock/video/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setVideoList(list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const toggleModel2 = () => {
    setModel2(!model2);
  };
  const toggleModelCustom = () => {
    setModelCustom(!modelcustom);
  };

  // new changes
  const CustomMOdalComponenet = () => {
    setModelCustomComponent(!customModalComponent);
  };
  const toggleModelDeal = () => {
    setModelDeal(!modelDeal);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModelVoucher = () => {
    setModelVoucher(!modelVoucher);
  };

  const toggleModelEvent = () => {
    setModelEvent(!modelEvent);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const closeModal3 = () => {
    setModelActivity(false);
  };
  const closeModalVoucher = () => {
    setModelVoucher(false);
  };
  const closeModal4 = () => {
    setModelEvent(false);
  };
  const closeModal = () => {
    setModel(false);
    setModelDeal(false);
    setModelCustom(false);
    // new changes
    setPreviewImage("");
    setSelectedImage("");
    setModelCustomComponent(false);
  };

  const selectRow = (selectRow, id, name) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    cover = selectRow.cover;
    logo = selectRow.logo;
    address = selectRow.address;
    label = selectRow.name;
    arr = { cover, logo, address, label };
    setVenuPreview([...venuPreview, arr]);
    setVenueId([...venuegetId, id]);
    setVenueText(name);
    closeModal();
  };

  const selectRow2 = (selectRow2, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow2.title;
    description = selectRow2.description;
    cover = selectRow2.image;
    logo = selectRow2.venue.logo;
    address = selectRow2.venue.address;
    label = selectRow2.venue.name;
    arr = { cover, logo, address, label, title, description };
    setOfferPreview([...offerPreview, arr]);
    setOfferId([...offergetId, id]);
    closeModal2();
  };

  const selectActivityRow = (selectRow, id) => {
    // check if activity is already added

    const isActivityAlreadyAdded = activityId.includes(id);

    if (isActivityAlreadyAdded) {
      toast.error("Activity already added");
      return;
    }

    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow.name;
    description = selectRow?.description?.description;
    cover = selectRow.provider?.logo;
    logo = selectRow.provider?.logo;
    address = selectRow.provider?.address;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, description };
    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };
  const selectVoucherRow = (selectRow, id) => {
    // check if activity is already added

    const isVoucherAlreadyAdded = voucherId.includes(id);

    if (isVoucherAlreadyAdded) {
      toast.error("Voucher already added");
      return;
    }

    closeModal3();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow.name;
    description = selectRow?.description?.description;
    cover = selectRow.provider?.logo;
    logo = selectRow.provider?.logo;
    address = selectRow.provider?.address;
    label = selectRow.provider?.name;
    arr = { cover, logo, address, label, title, description };
    setActivityPreview([...activityPreview, arr]);
    setActivityId([...activityId, id]);
  };
  const selectEventRow = (selectRow, id) => {
    // check if activity is already added

    const isEventAlreadyAdded = eventId.includes(id);

    if (isEventAlreadyAdded) {
      toast.error("Event already added");
      return;
    }

    closeModal4();
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      discount,
      description;
    title = selectRow.title;
    description = selectRow?.description;
    cover = selectRow?.image;
    logo = selectRow?.orgLogo;
    address = selectRow?.orgAddress;
    discount = selectRow?.pack?.discountValue;

    label = selectRow?.orgName;

    arr = { cover, label, logo, address, title, description, discount };
    setEventPreview([...eventPreview, arr]);
    setEventId([...eventId, id]);
  };

  const [formValues, setFormValues] = useState([]);

  const [currentForm, setCurrentForm] = useState({
    title: "",
    subTitle: "",
    description: "",
    info: "",
    badge: "",
    typeselect: "",
    type: "",
  });

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const updateCategory = (e) => {
    setCurrentForm((prevForm) => ({
      ...prevForm,
      category: e.value,
    }));
  };

  ///new changes
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    const formData = new FormData();
    formData.append("image", file);
    setLoading1(true);
    let response = await postCustomRequestForm("image/upload", formData);
    setLoading1(false);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      ["image"]: response.data.url,
    }));
  };

  const CustomFormValue = (event) => {
    event.preventDefault();

    // Reset states
    setCoverLogo([]);
    setPreviewImage(null);
    setSelectedImage(null);
    setCustomComp([]);

    // Reset currentForm state
    setCurrentForm({
      title: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
      type: "",
    });

    // Extract data from coverLogo state
    const newData = coverLogo[0];
    console.log({ newData });

    // Update formValues state with currentForm
    setFormValues((prevValues) => [...prevValues, currentForm]);

    // Merge newData and currentForm
    const mergeData = { ...newData, ...currentForm };
    console.log({ mergeData });

    // Update mergeformData state
    setMergeformData((prevValues) => [...prevValues, mergeData]);
  };

  const CustomFormDeal = (event) => {
    event.preventDefault();
    // setInputs('');

    // get current form data

    setCoverLogo([]);
    const newData = {
      title: currentForm.title,
      description: currentForm.description,
      venue: coverLogo[0]?.bid,
      category: currentForm.category,
      startDate: currentForm.startDate,
      endDate: currentForm.endDate,
      image: selectedImage,
      lowestDiscount: lowestDiscount,
      ...coverLogo[0],
    };
    newData.vouchars = vouchars;
    pushData(newData);
    setCurrentForm({
      title: "",
      category: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: "",
    });
    setVouchars([]);
    setFormValues((prevValues) => [...prevValues, newData]);
    setCoverLogo([]);

    closeModal();
  };

  useEffect(() => {
    if (formValues.length !== 0) {
      setModelCustom(false);
      setModelCustomComponent(false);
    }
  }, [formValues, venuPreview, venuegetId, offerPreview, offergetId]);

  const handleRemovevenuPreview = (index) => {
    const newArray = [...venuPreview];
    newArray.splice(index, 1);
    setVenuPreview(newArray);
    const newvenue = [...venuegetId];
    newvenue.splice(index, 1);
    setVenueId(newvenue);
  };
  const handleRemoOfferPreview = (index) => {
    const newArray = [...offerPreview];
    newArray.splice(index, 1);
    setOfferPreview(newArray);
    const newvenue = [...offergetId];
    newvenue.splice(index, 1);
    setOfferId(newvenue);
  };

  const handleRemoveActivityPreview = (index) => {
    const newArray = [...activityPreview];
    newArray.splice(index, 1);
    setActivityPreview(newArray);
    const newvenue = [...activityId];
    newvenue.splice(index, 1);
    setActivityId(newvenue);
  };

  const handleRemoveEventPreview = (index) => {
    const newArray = [...eventPreview];
    newArray.splice(index, 1);
    setEventPreview(newArray);
    const newvenue = [...eventId];
    newvenue.splice(index, 1);
    setEventId(newvenue);
  };
  const handleRemoveFormValue = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
    setFormValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });

    setMergeformData((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const createHomeBlock = async (e) => {
    e.preventDefault();

    // if (!homeData.title) {
    //   toast.error("Title is required");
    //   return;
    // }

    // if (!homeData.size) {
    //    homeData.size = "{64743d1ba021f3bf326ab3ce}";
    //   toast.error("Size is required");
    //   return;
    // }

    if (!homeData.type) {
      toast.error("Type is required");
      return;
    }

    if (homeData.type != "video" && homeData.type != "my-outing") {
      if (!homeData.title) {
        toast.error("Tittle is required");
        return;
      }
    }

    if (homeData.type == "venue" && !homeData.size) {
      // homeData.size = "64743d1ba021f3bf326ab3ce";
      toast.error("Size is required");
      return;
    }

    // if(!homeData.description){
    //   toast.error("Description is required");
    //   return;
    // }

    if (!homeData.type) {
      toast.error("Type is required");
      return;
    }

    if (homeData.type == "video" && !uploadedVideo.length) {
      toast.error("Please select video");
      return;
    }

    if (homeData.type == "deal" && !selectedDeals.length) {
      toast.error("Please add deals");
      return;
    }

    if (
      homeData.type == "venue" &&
      homeData.isAuto == "0" &&
      !venuegetId.length
    ) {
      toast.error("Please select venue");
      return;
    }

    if (
      homeData.type == "venue" &&
      homeData.isAuto == "1" &&
      !selectedTheme.length &&
      !selectedCuisine.length &&
      !selectedMusic.length &&
      !selectedFeature.length
    ) {
      toast.error("Please select atleast one Category");
      return;
    }

    if (homeData.type == "activity" && !activityId.length) {
      toast.error("Please select activity");
      return;
    }

    if (
      homeData.type == "offer" &&
      homeData.isAuto == "0" &&
      !offergetId.length
    ) {
      toast.error("Please select offer");
      return;
    }

    if (homeData.type == "event" && homeData.isAuto == "0" && !eventId.length) {
      toast.error("Please select event");
      return;
    }

    if (homeData.type == "slider" && !selectedSlider.length) {
      toast.error("Please select slider");
      return;
    }

    if (homeData.type == "custom-venue" && !formValues.length) {
      toast.error("Please add custom venue");
      return;
    }

    if (homeData.type == "custom-offer" && !formValues.length) {
      toast.error("Please add custom offer");
      return;
    }

    if (homeData.type == "custom-components" && !formValues.length) {
      toast.error("Please add custom components");
      return;
    }
    
    if (
      homeData.type == "offer" &&
      homeData.isAuto == "1" &&
      homeData.promoteOffer == "yes" && !offergetId.length) {
      toast.error("Please select atleast one any promote offer");
      return;
    }
    if (
      homeData.type == "event" &&
      homeData.isAuto == "1" &&
      homeData.promoteEvent == "yes" && !eventId.length) {
      toast.error("Please select atleast one any promote event");
      return;
    }
    if (
      homeData.type == "venue" &&
      homeData.isAuto == "1" &&
      homeData.promoteVenue == "yes" && !venuegetId.length) {
      toast.error("Please select atleast one any promote venue");
      return;
    }

    // setLoading(true);

    const dealIds = selectedDeals.map((deal) => deal._id);

    let uVideos = uploadedVideo?.map((video) => video._id);

    const payload = {
      ...homeData,
      offers: homeData.isAuto === "1" && homeData.promoteOffer === "yes" || homeData.isAuto === "0" ? offergetId : [],
      // offers: homeData.isAuto === "0" ? offergetId : offergetId,
      sliders: selectedSlider,
      events: homeData.isAuto === "1" && homeData.promoteEvent === "yes" || homeData.isAuto === "0" ? eventId : [],
      videos: uVideos,
      customComponents: selectedComponent,
      // venues: homeData.isAuto === "0" ? venuegetId : venuegetId,
      venues: homeData.isAuto === "1" && homeData.promoteVenue === "yes" || homeData.isAuto === "0" ? venuegetId : [],
      deals: homeData.type == "deal" ? dealIds : [],
      // offers: selectedOffer,
      categoryForOffer: selectedCategory,
      customVenues: homeData.type == "custom-venue" ? MergeformData : [],
      // customVenues: homeData.type == "custom-venue" ? formValues : [],
      activities: homeData.type == "activity" ? activityId : [],
      // customOffers: homeData.type == "custom-offer" ? formValues : [],
      customOffers: homeData.type == "custom-offer" ? MergeformData : [],
      customComponents: homeData.type == "custom-components" ? formValues : [],
    };

    if (homeData.type == "venue" && homeData?.isAuto == "1") {
      let currentCategoryForAuto = {
        themes: selectedTheme,
        musics: selectedMusic,
        features: selectedFeature,
        cuisines: selectedCuisine,
      };
      payload.venueFilterForAuto = currentCategoryForAuto;
    }

    console.log(homeData.size);

    if (homeData.type == "venue") {
      payload.size = homeData.size;
    }

    const response = await postRequest("homeblock/search/create", payload);
    setLoading(false);
    const newId = response?.data?._id;

    if (response.status === 1) {
      // If the update is successful, call the pushUpdates function
      const pushUpdatesResponse = await pushUpdates({
        type: "home-block",
        id: newId,
        receivers: [],
      });

      toast.success("Search Block created successfully");
      setTimeout(() => {
        history.push("/searchblock");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  // new changes
  const customValue = (index, value, name) => {
    const list = { ...customcomp };
    list[index] = value;
    setCustomComp(list);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      ["type"]: value,
    }));

    if (index == "type") {
      if (value == "offer") {
        setIdName("offerId");
      }
      if (value == "venue") {
        setIdName("venueId");
      }
    }
  };
  // end new changes
  const updateValue = (index, value) => {
    const list = { ...homeData };
    list[index] = value;
    if (index == "type") {
      if (value == "custom-offer") {
        setTypeCustom("custom");
        setIdName("offerId");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setData([]);
        setMergeformData([]);
        // new changes
        setPreviewImage(null);
        setSelectedImage(null);
      }

      if (value == "custom-venue") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setMergeformData([]);
        setData([]);
        // new changes
        setPreviewImage(null);
        setSelectedImage(null);
      }
      // new changes
      if (value == "custom-components") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
        setFormValues([]);
        setCurrentForm([]);
        setData([]);
        setPreviewImage(null);
        setSelectedImage(null);
      }
      if (value == "event") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "recent";
      }
      if (value == "venue") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value == "offer") {
        list["isAuto"] = "1";
        list["categoryForAuto"] = "all";
      }
      if (value != "venue" && value != "event" && value != "offer") {
        list["isAuto"] = "0";
        list["categoryForAuto"] = "";
      }
    }
    setHomeBlockData(list);
  };

  // new changes
  const handleCoverChange = (e, index) => {
    let arr = [],
      cover,
      logo,
      address,
      bid,
      label,
      covercustom; // new changes
    arr = arr.concat(coverLogo);
    let value = e.value;
    let title = e.label;

    if (
      homeData?.type == "custom-venue" ||
      homeData?.type == "deal" ||
      customcomp?.type == "venue"
    ) {
      let result = venueListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.cover;
      logo = result.logo;
      address = result.address;
      label = title;
      bid = value;
      covercustom = previewImage;
      // new changes
    } else if (
      homeData?.type == "custom-offer" ||
      customcomp?.type == "offer"
    ) {
      let result = offerListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.venue.cover;
      logo = result.venue.logo;
      address = result.venue.address;
      label = title;
    }
    arr[0] = { cover, logo, address, label, bid, covercustom };
    setCoverLogo(arr);
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [idName]: value,
    }));
  };

  const [customdata, setData] = useState([]);
  const [MergeformData, setMergeformData] = useState([]);

  const [VenuePromoteModal, setVenuePromote] = useState(false);
  const pushData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };

  const toggleVenuePromote = () => {
    setVenuePromote(!model2);
  };

  // DragDropTable

  const DragDropTable = () => {
    if (homeData.type === "venue") {
      setDragDropData(venuPreview);
    } else if (homeData.type === "offer") {
      setDragDropData(offerPreview);
    } else if (homeData.type === "video") {
      setDragDropData(uploadedVideo);
    } else if (homeData.type === "custom-venue") {
      setDragDropData(MergeformData);
    } else if (homeData.type === "custom-offer") {
      setDragDropData(MergeformData);
    } else if (homeData.type === "event") {
      setDragDropData(eventPreview);
    } else if (homeData.type === "activity") {
      setDragDropData(activityPreview);
    }
    setShowOrder(true);
  };

  const updateDragDropData = async (data) => {
    console.log(homeData.type);
    if (homeData.type == "venue") {
      setVenuPreview(data);
    } else if (homeData.type == "offer") {
      setOfferPreview(data);
    } else if (homeData.type == "video") {
      setDragDropData(data);
    } else if (homeData.type == "custom-venue") {
      setMergeformData(data);
    } else if (homeData.type == "custom-offer") {
      setMergeformData(data);
    } else if (homeData.type === "event") {
      setEventPreview(data);
    } else if (homeData.type === "activity") {
      setActivityPreview(data);
    }

    console.log(data);
    setShowOrder(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Search Block Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Serach Block Management</li>:{" "}
              <li className="breadcrumb-item">Create Search Block</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Search Block </h4>
                <Form onSubmit={createHomeBlock}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                              // required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Show Title in App
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              defaultChecked={true}
                              onChange={(e) =>
                                updateValue("showTitle", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                              // required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="mediaType"
                              class="form-select"
                            >
                              <option value="">Select</option>
                              <option value="video">Video</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                              {/* <option value="slider">Slider</option> */}
                              <option value="activity">Activity</option>
                              <option value="event">Event</option>
                              <option value="deal">Deal</option>
                              <option value="custom-venue">Custom Venue</option>
                              <option value="custom-offer">Custom offer</option>
                              <option value="custom-components">
                                Custom Components
                              </option>
                              <option value="my-outing">My Outing</option>
                            </select>
                          </div>
                        </div>
                        {homeData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Size
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("size", e.target.value)
                                }
                                class="form-select"
                              >
                                <option> Select</option>
                                {sizeList.map((item, index) => (
                                  <option value={item.id}>
                                    {item.type + " - " + item.ratio}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "deal" && (
                          <>
                            <div className="row align-items-center">
                              {formValues.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",

                                      backgroundImage: form?.image
                                        ? `url(${URL.createObjectURL(
                                            form?.image
                                          )})`
                                        : `url(${form?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <Countdown endDate={form.endDate} />

                                    <div class="badge-deal">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      {form?.lowestDiscount
                                        ? `${form?.lowestDiscount} off`
                                        : "0 % off"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{ textAlign: "left" }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "32px",
                                        lineHeight: "36px",
                                      }}
                                      // style={{ textAlign: "left" }}
                                      // className="subtitle"
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>
                                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                          <h3 style={{ fontSize: "12px" }}>
                                            {form?.label
                                              ? form?.label
                                              : "Select"}
                                          </h3>
                                          <p style={{ fontSize: "10px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelVoucher}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "activity" && (
                          <>
                            <div className="row align-items-center">
                              {activityPreview.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom red-border "
                                    className={
                                      form[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.logo})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    {/* <Countdown endDate={form.endDate} /> */}

                                    <div class="badge-deal">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      {form?.discount
                                        ? `${form?.discount} off`
                                        : "0 % off"}
                                    </div>

                                    <h5
                                      className="title"
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {form.title ? form.title : "Title"}
                                    </h5>

                                    <h4
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "32px",
                                        lineHeight: "36px",
                                      }}
                                      // style={{ textAlign: "left" }}
                                      // className="subtitle"
                                    >
                                      {form.description
                                        ? form.description
                                        : "Description"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center deal-footer">
                                        <div
                                          className="logo"
                                          style={{ background: "inherit" }}
                                        >
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                            className="rounded-circle"
                                          />
                                        </div>

                                        <div>
                                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                                          <h3 style={{ fontSize: "12px" }}>
                                            {form?.label
                                              ? form?.label
                                              : "Select"}
                                          </h3>
                                          <p style={{ fontSize: "10px" }}>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveActivityPreview(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelActivity}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>

                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  Activity Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "event" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Event Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("isAuto", e.target.value,
                                  setEventPreview([]),
                                  setEventId([]) 
                                   )
                                }
                                class="form-select"
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}
                        {homeData?.type == "event" &&
                          homeData?.isAuto == "1" && (
                          <>
                          <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Select Category
                              </label>
                              <div className="col-sm-6">
                                <select
                                  onChange={(e) =>
                                    updateValue(
                                      "categoryForAuto",
                                      e.target.value
                                    )
                                  }
                                  class="form-select"
                                >
                                  <option value="recent">Recent</option>
                                  <option value="upcoming">Upcoming</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any event
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteEvent",
                                        e.target.value
                                      )
                                    }
                                    class="form-select"
                                  >
                                    <option>Please Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              {homeData?.promoteEvent == "yes" && (
                              <div className="row align-items-center">
                                {eventPreview.map((form, index) => (
                                  <div className="col-lg-4 mb-3">
                                    <div
                                      class="box-custom red-border "
                                      className={
                                        form[index]
                                          ? "box-custom bgcard"
                                          : "box-custom"
                                      }
                                      style={{
                                        border: "1px solid #ccc",
                                        backgroundImage: `url(${form?.cover})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                      }}
                                    >
                                      <div class="badge-deal">
                                        {form?.discount
                                          ? `${form?.discount} off`
                                          : "0 % off"}
                                      </div>

                                      <h5
                                        className="title"
                                        style={{
                                          textAlign: "left",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {form.title ? form.title : "Title"}
                                      </h5>

                                      <h4
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "32px",
                                          lineHeight: "36px",
                                        }}
                                      >
                                        {form.description
                                          ? form.description
                                          : "Description"}
                                      </h4>

                                      <div className="bottom-footer">
                                        <div className="d-flex align-items-center deal-footer">
                                          <div
                                            className="logo"
                                            style={{ background: "inherit" }}
                                          >
                                            <img
                                              src={form?.logo || noImage}
                                              alt="logo"
                                              className="rounded-circle"
                                            />
                                          </div>

                                          <div>
                                            <h3 style={{ fontSize: "12px" }}>
                                              {form?.label
                                                ? form?.label
                                                : "Select"}
                                            </h3>
                                            <p style={{ fontSize: "10px" }}>
                                              {form?.address
                                                ? form?.address
                                                : "Address"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ textAlign: "start" }}>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveEventPreview(index)
                                        }
                                        className="btn-remove"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    onClick={toggleModelEvent}
                                    className="modal-custom-button mt-3 mb-3"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>
                                  <Button
                                    onClick={DragDropTable}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Event Order
                                  </Button>
                                  
                                </div>
                              </div>
                              )}
                          </>

                          )}
                        {homeData?.type == "event" &&
                          homeData?.isAuto == "0" && (
                            <>
                              <div className="row align-items-center">
                                {eventPreview.map((form, index) => (
                                  <div className="col-lg-4 mb-3">
                                    <div
                                      class="box-custom red-border "
                                      className={
                                        form[index]
                                          ? "box-custom bgcard"
                                          : "box-custom"
                                      }
                                      style={{
                                        border: "1px solid #ccc",
                                        backgroundImage: `url(${form?.cover})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                      }}
                                    >
                                      <div class="badge-deal">
                                        {form?.discount
                                          ? `${form?.discount} off`
                                          : "0 % off"}
                                      </div>

                                      <h5
                                        className="title"
                                        style={{
                                          textAlign: "left",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {form.title ? form.title : "Title"}
                                      </h5>

                                      <h4
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "32px",
                                          lineHeight: "36px",
                                        }}
                                      >
                                        {form.description
                                          ? form.description
                                          : "Description"}
                                      </h4>

                                      <div className="bottom-footer">
                                        <div className="d-flex align-items-center deal-footer">
                                          <div
                                            className="logo"
                                            style={{ background: "inherit" }}
                                          >
                                            <img
                                              src={form?.logo || noImage}
                                              alt="logo"
                                              className="rounded-circle"
                                            />
                                          </div>

                                          <div>
                                            <h3 style={{ fontSize: "12px" }}>
                                              {form?.label
                                                ? form?.label
                                                : "Select"}
                                            </h3>
                                            <p style={{ fontSize: "10px" }}>
                                              {form?.address
                                                ? form?.address
                                                : "Address"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ textAlign: "start" }}>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveEventPreview(index)
                                        }
                                        className="btn-remove"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    onClick={toggleModelEvent}
                                    className="modal-custom-button mt-3 mb-3"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>
                                  <Button
                                    onClick={DragDropTable}
                                    className="btn btn-info"
                                    style={{ marginLeft: "2rem" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>{" "}
                                    Event Order
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}

                        {homeData?.type == "custom-venue" && (
                          <>
                            <div className="row align-items-center">
                              {MergeformData.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom"
                                    className={
                                      customdata[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      textAlign: "start",
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${form?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      <input
                                        type="text"
                                        value={form.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <h5 className="title">
                                      {form.title ? form.title : "Title"}
                                    </h5>
                                    <h4 className="subtitle">
                                      {form.subTitle
                                        ? form.subTitle
                                        : "Sub Title"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {form.description
                                            ? form.description
                                            : "Description"}
                                        </h6>
                                        <p>{form.info ? form.info : "Info"}</p>
                                      </div>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {" "}
                                            {form?.label
                                              ? form?.label
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelCustom}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  custom Venue Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* {homeData?.type == "custom-venue" && (
                          <>
                            <div className="row align-items-center">
                              {formValues.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom"
                                    className={
                                      customdata[index]
                                        ? "box-custom bgcard"
                                        : "box-custom"
                                    }
                                    style={{
                                      textAlign: "start",
                                      border: "1px solid #ccc",
                                      backgroundImage: `url(${customdata[index]?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                    
                                      <input
                                        type="text"
                                        value={form.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <h5 className="title">
                                      {form.title ? form.title : "Title"}
                                    </h5>
                                    <h4 className="subtitle">
                                      {form.subTitle
                                        ? form.subTitle
                                        : "Sub Title"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {form.description
                                            ? form.description
                                            : "Description"}
                                        </h6>
                                        <p>{form.info ? form.info : "Info"}</p>
                                      </div>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={
                                              customdata[index]?.logo || noImage
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {" "}
                                            {customdata[index]?.label
                                              ? customdata[index]?.label
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {customdata[index]?.address
                                              ? customdata[index]?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelCustom}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  custom Venue Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )} */}

                        {homeData?.type == "custom-offer" && (
                          <>
                            <div className="row align-items-center">
                              {MergeformData.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom"
                                    className={
                                      form ? "box-custom bgcard" : "box-custom"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${form?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      <input
                                        type="text"
                                        value={form.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <h5 className="title">
                                      {form.title ? form.title : "Title"}
                                    </h5>
                                    <h4 className="subtitle">
                                      {form.subTitle
                                        ? form.subTitle
                                        : "Sub Title"}
                                    </h4>

                                    <div className="bottom-footer">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {form.description
                                            ? form.description
                                            : "Description"}
                                        </h6>
                                        <p>{form.info ? form.info : "Info"}</p>
                                      </div>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={form?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {form?.label
                                              ? form?.label
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {form?.address
                                              ? form?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              {/* </> : null}  */}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModelCustom}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                  onClick={DragDropTable}
                                  className="btn btn-info"
                                  style={{ marginLeft: "2rem" }}
                                >
                                  <i className="fas fa-grip-vertical"></i>{" "}
                                  Custom Order
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "video" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Add Video
                            </label>
                            {/* <div className="col-sm-6">
                              <MultiSelect
                                options={videoList}
                                onSelect={msUpdateVideo}
                                selectedValues={[]}
                                placeholder="Select Video"
                              />
                            </div> */}

                            {uploadedVideo.length ? (
                              <>
                                {uploadedVideo.map((item, index) => (
                                  <div className="col-lg-2">
                                    <video width="240" height="200" controls>
                                      <source
                                        src={item?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                //  onClick={CustomVideoModal}
                                onClick={() => setVideoMdl(true)}
                                className="modal-custom-button mt-3 mb-3"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {/* new changes custom-components*/}
                        {homeData?.type == "custom-components" && (
                          <>
                            <div className="row align-items-center">
                              {formValues.map((form, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    className={
                                      customdata[index]
                                        ? "box-custom customcomponent bgcard fix-heights"
                                        : "box-custom customcomponent fix-heights"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url('${form?.image}')`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div class="badge">
                                      {/* <img src={icon1} alt="icon" /> */}
                                      <input
                                        type="text"
                                        value={form.badge}
                                        autoComplete="off"
                                        readOnly
                                      />
                                    </div>
                                    <div className="bottom-footer mt-3">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={
                                              customdata[index]?.logo || noImage
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {customdata[index]?.label
                                              ? customdata[index]?.label
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {customdata[index]?.address
                                              ? customdata[index]?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="dic">
                                      <div className="mb-3">
                                        <h6 className="fnt-18">
                                          {form.description
                                            ? form.description
                                            : "Description"}
                                        </h6>
                                      </div>
                                      <h5 className="title">
                                        {form.title ? form.title : "Title"}
                                      </h5>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFormValue(index)
                                      }
                                      className="btn-remove"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              {/* </> : null}  */}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={CustomMOdalComponenet}
                                  className="modal-custom-button mt-3 mb-3"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {homeData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Venue Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue(
                                    "isAuto",
                                    e.target.value,
                                    setVenuPreview([]),
                                    setVenueId([]),
                                  )
                                }
                                class="form-select"
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "venue" &&
                          homeData?.isAuto == "1" && (
                            <>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Theme
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={themeList}
                                    onSelect={msUpdateTheme}
                                    selectedValues={[]}
                                    placeholder="Select Theme"
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Cuisine
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={cuisineList}
                                    onSelect={msUpdateCuisine}
                                    selectedValues={[]}
                                    placeholder="Select Cuisine"
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Music
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={musicList}
                                    onSelect={msUpdateMusic}
                                    selectedValues={[]}
                                    placeholder="Select Music"
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Select Feature
                                </label>
                                <div className="col-sm-6">
                                  <MultiSelect
                                    options={featureList}
                                    onSelect={msUpdateFeature}
                                    selectedValues={[]}
                                    placeholder="Select Feature"
                                  />
                                </div>
                              </div>

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any venue
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteVenue",
                                        e.target.value
                                      )
                                    }
                                    class="form-select"
                                  >
                                    <option>Please Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              {homeData?.promoteVenue === "yes" && (
                                <>
                                  <div className="row mb-4 align-items-center">
                                    {venuPreview.map((item, index) => (
                                      <div className="col-lg-4 mb-3">
                                        <div
                                          class="box-custom"
                                          className={
                                            item
                                              ? "box-custom bgcard fix-height"
                                              : "box-custom fix-height"
                                          }
                                          style={{
                                            border: "1px solid #ccc",
                                            textAlign: "start",
                                            backgroundImage: `url(${item?.cover})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                          }}
                                        >
                                          <div className="bottom-footer">
                                            <div className="d-flex align-items-center mb-3">
                                              <div className="logo">
                                                <img
                                                  src={item?.logo || noImage}
                                                  alt="logo"
                                                />
                                              </div>
                                              <div>
                                                <h3>
                                                  {item?.label
                                                    ? item?.label
                                                    : "Select Venue"}
                                                </h3>
                                                <p>
                                                  {item?.address
                                                    ? item?.address
                                                    : "Address"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ textAlign: "start" }}>
                                          <button
                                            onClick={() =>
                                              handleRemovevenuPreview(index)
                                            }
                                            className="btn-remove"
                                            type="button"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    ))}

                                    <div className="col-lg-4">
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="modal-custom-button"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </button>

                                      <Button
                                        onClick={DragDropTable}
                                        className="btn btn-info"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <i className="fas fa-grip-vertical"></i>{" "}
                                        venue Order
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                        {homeData?.type == "deal" ? (
                          <div className="row mb-4 align-items-center">
                            {selectedDeals?.map((item, index) => (
                              <div class="col-md-3">
                                <div
                                  class="box-custom red-border "
                                  className={
                                    item ? "box-custom bgcard" : "box-custom"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    backgroundImage: item?.image
                                      ? `url(${item?.image})`
                                      : `url(${item?.image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <Countdown
                                    endDate={`${item.startDate} ${item.startTime}`}
                                  />

                                  <div class="badge-deal">
                                    {item?.actualPrice ? (
                                      <>
                                        <del>{item.actualPrice}</del>{" "}
                                        {item.discountedPrice} AED
                                      </>
                                    ) : (
                                      "0 % off"
                                    )}
                                  </div>

                                  <h2 className="title">
                                    {item?.title ? item?.title : "Title"}
                                  </h2>

                                  <h5
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                    }}
                                    className="subtitle"
                                  >
                                    {item?.description
                                      ? item?.description
                                      : "Description"}
                                  </h5>

                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center deal-footer">
                                      <div
                                        className="logo"
                                        style={{ background: "inherit" }}
                                      >
                                        <img
                                          src={
                                            item?.venueLogo || item?.venueLogo
                                          }
                                          alt="logo"
                                          className="rounded-circle"
                                          height={50}
                                          width={50}
                                        />
                                      </div>

                                      <div>
                                        {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                                        <h3>
                                          {item?.venueName
                                            ? item?.venueName
                                            : "Venue Name"}
                                        </h3>
                                        {/* <h3 style={{ fontSize: "12px" }}>
                                              {coverLogo[index]?.label
                                                ? coverLogo[index]?.label
                                                : "Select"}
                                            </h3> */}
                                        <p style={{ fontSize: "10px" }}>
                                          {item?.venueAddress
                                            ? item?.venueAddress
                                            : "Venue Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() => popSelectedDeal(index)}
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {homeData?.type == "venue" &&
                          homeData?.isAuto == "0" && (
                            <div className="row mb-4 align-items-center">
                              {venuPreview.map((item, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom"
                                    className={
                                      item
                                        ? "box-custom bgcard fix-height"
                                        : "box-custom fix-height"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${item?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div className="bottom-footer">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={item?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {item?.label
                                              ? item?.label
                                              : "Select Venue"}
                                          </h3>
                                          <p>
                                            {item?.address
                                              ? item?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      onClick={() =>
                                        handleRemovevenuPreview(index)
                                      }
                                      className="btn-remove"
                                      type="button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}

                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModel}
                                  className="modal-custom-button"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                <Button
                                        onClick={DragDropTable}
                                        className="btn btn-info"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <i className="fas fa-grip-vertical"></i>{" "}
                                        venue Order
                                      </Button>
                              </div>
                            </div>
                          )}

                        {homeData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Offer Selection
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue(
                                    "isAuto",
                                    e.target.value,
                                    setOfferPreview([]),
                                    setOfferId([])
                                  )
                                }
                                class="form-select"
                              >
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "offer" &&
                          homeData?.isAuto == "0" && (
                            <div className="row mb-4 align-items-center">
                              {offerPreview.map((item, index) => (
                                <div className="col-lg-4 mb-3">
                                  <div
                                    class="box-custom"
                                    className={
                                      item
                                        ? "box-custom bgcard fix-heights"
                                        : "box-custom fix-heights"
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      textAlign: "start",
                                      backgroundImage: `url(${item?.cover})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                    }}
                                  >
                                    <div className="bottom-footer mt-0">
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="logo">
                                          <img
                                            src={item?.logo || noImage}
                                            alt="logo"
                                          />
                                        </div>
                                        <div>
                                          <h3>
                                            {item?.label
                                              ? item?.label
                                              : "Select Offer"}
                                          </h3>
                                          <p>
                                            {item?.address
                                              ? item?.address
                                              : "Address"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dic">
                                      <p className="mb-0">
                                        {item?.title ? item?.title : "Title"}
                                      </p>
                                      <h3 className="limit-text">
                                        {item?.description
                                          ? item?.description
                                          : "Description"}
                                      </h3>
                                    </div>
                                  </div>
                                  <div style={{ textAlign: "start" }}>
                                    <button
                                      onClick={() =>
                                        handleRemoOfferPreview(index)
                                      }
                                      className="btn-remove"
                                      type="button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-lg-4">
                                <button
                                  type="button"
                                  onClick={toggleModel2}
                                  className="modal-custom-button"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                                  <Button
                                        onClick={DragDropTable}
                                        className="btn btn-info"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <i className="fas fa-grip-vertical"></i>{" "}
                                        offer Order
                                      </Button>
                              </div>
                            </div>
                          )}

                        {homeData?.type == "offer" &&
                          homeData?.isAuto == "1" && (
                            <>
                              {state?.categoryId ? null : (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Category
                                  </label>
                                  <div className="col-sm-6">
                                    <MultiSelect
                                      options={categoryList}
                                      onSelect={msUpdateCategory}
                                      selectedValues={defaultCategory}
                                      placeholder="Select Category"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Do you want to promote any offer
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "promoteOffer",
                                        e.target.value
                                      )
                                    }
                                    class="form-select"
                                  >
                                    <option>Please Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>

                              {homeData?.promoteOffer === "yes" && (
                                <div className="row mb-4 align-items-center">
                                  {offerPreview.map((item, index) => (
                                    <div className="col-lg-4 mb-3">
                                      <div
                                        class="box-custom"
                                        className={
                                          item
                                            ? "box-custom bgcard fix-heights"
                                            : "box-custom fix-heights"
                                        }
                                        style={{
                                          border: "1px solid #ccc",
                                          textAlign: "start",
                                          backgroundImage: `url(${item?.cover})`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                        }}
                                      >
                                        <div className="bottom-footer mt-0">
                                          <div className="d-flex align-items-center mb-3">
                                            <div className="logo">
                                              <img
                                                src={item?.logo || noImage}
                                                alt="logo"
                                              />
                                            </div>
                                            <div>
                                              <h3>
                                                {item?.label
                                                  ? item?.label
                                                  : "Select Offer"}
                                              </h3>
                                              <p>
                                                {item?.address
                                                  ? item?.address
                                                  : "Address"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="dic">
                                          <p className="mb-0">
                                            {item?.title
                                              ? item?.title
                                              : "Title"}
                                          </p>
                                          <h3 className="limit-text">
                                            {item?.description
                                              ? item?.description
                                              : "Description"}
                                          </h3>
                                        </div>
                                      </div>
                                      <div style={{ textAlign: "start" }}>
                                        <button
                                          onClick={() =>
                                            handleRemoOfferPreview(index)
                                          }
                                          className="btn-remove"
                                          type="button"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="col-lg-4">
                                    <button
                                      type="button"
                                      onClick={toggleModel2}
                                      className="modal-custom-button"
                                    >
                                      <i class="fas fa-plus"></i>
                                    </button>

                                    <Button
                                      onClick={DragDropTable}
                                      className="btn btn-info"
                                      style={{ marginLeft: "2rem" }}
                                    >
                                      <i className="fas fa-grip-vertical"></i>{" "}
                                      Order
                                    </Button>
                                  </div>

                                  {/* <div className="col-lg-4">
                                    <Button
                                      onClick={DragDropTable}
                                      className="btn btn-info"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <i className="fas fa-grip-vertical"></i>{" "}
                                      Order
                                    </Button>
                                  </div> */}
                                </div>
                              )}
                            </>
                          )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  style={{ marginRight: "1rem" }}
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal
        show={model}
        closeModal={closeModal}
        selectRow={selectRow}
        action="homeblock"
      />

      <ActivityModal
        show={modelActivity}
        closeModal={closeModal3}
        selectRow={selectActivityRow}
      />
      <EventModal
        show={modelEvent}
        closeModal={closeModal4}
        selectRow={selectEventRow}
      />

      <OfferModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow2}
      />
      <VoucherModal
        show={modelVoucher}
        closeModal={closeModalVoucher}
        selectRow={(deal) => pushSelectedDeal(deal)}
      />

      <Modal
        isOpen={modelcustom}
        size="lg"
        className="deal-modal"
        style={{ minWidth: "80% !important" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {homeData.type == "custom-venue" ? <>Custom Venue </> : null}
            {homeData.type == "custom-offer" ? <>Custom Offer</> : null}
          </h5>
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormValue}>
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="row relativerow justify-content-center">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="title"
                        autoComplete="off"
                        placeholder="Title"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.title || ""}
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <textarea
                        value={currentForm.subTitle || ""}
                        type="textarea"
                        autoComplete="off"
                        name="subTitle"
                        placeholder="Sub Title"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        autoComplete="off"
                        value={currentForm.description || ""}
                        name="description"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                        placeholder="Description"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        name="info"
                        value={currentForm.info || ""}
                        autoComplete="off"
                        placeholder="Info"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="badge"
                        value={currentForm.badge || ""}
                        className="form-control"
                        required="required"
                        autoComplete="off"
                        placeholder="Badge"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-sm-12 ">
                      {homeData.type == "custom-venue" ? (
                        <>
                          <input
                            type="hidden"
                            value={(currentForm.typeselect = "venue" || "")}
                            name="typeselect"
                            onChange={handleInputChanges}
                          />
                          <Select
                            name={`${idName}`}
                            options={venueoptions.map((item) => ({
                              label: item.name,
                              value: item._id,
                            }))}
                            placeholder="Select Venue"
                            onChange={(e) => handleCoverChange(e, index)}
                          />
                        </>
                      ) : null}

                      {homeData.type == "custom-offer" ? (
                        <>
                          <input
                            type="hidden"
                            value={(currentForm.typeselect = "offer" || "")}
                            name="typeselect"
                            onChange={handleInputChanges}
                          />
                          <Select
                            name={`${idName}`}
                            options={offeroptions}
                            value={currentForm.badge}
                            placeholder="Select Offer"
                            onChange={(e) => handleCoverChange(e, index)}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    class="box-custom"
                    className={
                      coverLogo[index] ? "box-custom bgcard" : "box-custom"
                    }
                    style={{
                      border: "1px solid #ccc",
                      backgroundImage: `url(${coverLogo[index]?.cover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div class="badge">
                      {/* <img src={icon1} alt="icon" /> */}
                      <input
                        type="text"
                        value={currentForm.badge}
                        autoComplete="off"
                        readOnly
                      />
                    </div>
                    <h5 className="title">
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>
                    <h4 className="subtitle">
                      {currentForm.subTitle
                        ? currentForm.subTitle
                        : "Sub Title"}
                    </h4>

                    <div className="bottom-footer">
                      <div className="mb-3">
                        <h6 className="fnt-18">
                          {currentForm.description
                            ? currentForm.description
                            : "Description"}
                        </h6>
                        <p>{currentForm.info ? currentForm.info : "Info"}</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <div className="logo">
                          <img
                            src={coverLogo[index]?.logo || noImage}
                            alt="logo"
                          />
                        </div>
                        <div>
                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                          <h3>
                            {coverLogo[index]?.label
                              ? coverLogo[index]?.label
                              : "Select"}
                          </h3>
                          <p>
                            {coverLogo[index]?.address
                              ? coverLogo[index]?.address
                              : "Address"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn-primary btn btn-saves">
                    Save
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </div>
      </Modal>

      {/* New Changes Custom Component*/}
      <Modal
        isOpen={customModalComponent}
        size="lg"
        className="deal-modal"
        style={{ minWidth: "80% !important" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Custom Components
          </h5>
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormValue}>
            {Array.from({ length: 1 }).map((_, index) => (
              <>
                <div className="row relativerow justify-content-center">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-4">
                        <input
                          type="text"
                          name="title"
                          autoComplete="off"
                          placeholder="Title"
                          onChange={handleInputChanges}
                          className="form-control"
                          value={currentForm.title || ""}
                          required="required"
                        />
                      </div>

                      <div className="col-lg-12  mb-4">
                        <textarea
                          type="textarea"
                          autoComplete="off"
                          value={currentForm.description || ""}
                          name="description"
                          className="form-control"
                          id="horizontal-password-input"
                          required="required"
                          placeholder="Description"
                          onChange={handleInputChanges}
                        />
                      </div>

                      <div className="col-lg-12 mb-4">
                        <input
                          type="text"
                          name="badge"
                          value={currentForm.badge || ""}
                          className="form-control"
                          required="required"
                          autoComplete="off"
                          placeholder="Badge"
                          onChange={handleInputChanges}
                        />
                      </div>

                      <div className="col-lg-12 mb-0">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          // onChange={(e) => setSelectedImage(e.target.files[0])}
                          name="image"
                          className="form-control"
                          id="horizontal-password-input"
                          required="required"
                        />
                        {loading1 ? (
                          <Spinner
                            color="info"
                            style={{
                              height: "20px",
                              width: "20px",
                              position: "absolute",
                              top: "6px",
                              right: "25px",
                            }}
                          ></Spinner>
                        ) : null}
                        <br />
                      </div>
                      <div className="col-lg-12 mb-4">
                        <select
                          className="form-control"
                          onChange={(e) => customValue("type", e.target.value)}
                          name="type"
                          class="form-select"
                          placeholder=" Type"
                        >
                          <option value="">Select Type</option>
                          <option value="offer">Offer</option>
                          <option value="venue">Venue</option>
                        </select>
                      </div>

                      <div className="col-sm-12 mb-3">
                        {customcomp.type == "venue" ? (
                          <>
                            <input
                              type="hidden"
                              value={(currentForm.typeselect = "venue" || "")}
                              name="typeselect"
                              onChange={handleInputChanges}
                            />
                            <Select
                              name={`${idName}`}
                              options={venueoptions.map((item) => ({
                                label: item.name,
                                value: item._id,
                              }))}
                              placeholder="Select Venue"
                              onChange={(e) => handleCoverChange(e, index)}
                              className="mb-3"
                            />
                          </>
                        ) : null}
                        {customcomp.type == "offer" ? (
                          <>
                            <input
                              type="hidden"
                              value={(currentForm.typeselect = "offer" || "")}
                              name="typeselect"
                              onChange={handleInputChanges}
                            />
                            <Select
                              name={`${idName}`}
                              options={offeroptions}
                              placeholder="Select Offer"
                              onChange={(e) => handleCoverChange(e, index)}
                              className="mb-3"
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      class="box-custom"
                      className={
                        coverLogo[index]
                          ? "box-custom customcomponent bgcard"
                          : "box-custom customcomponent"
                      }
                      style={{
                        border: "1px solid #ccc",
                        backgroundImage: `url(${previewImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div class="badge">
                        <input
                          type="text"
                          value={currentForm.badge}
                          autoComplete="off"
                          readOnly
                        />
                      </div>
                      <div
                        className="bottom-footer"
                        style={{ marginTop: "2rem" }}
                      >
                        <div className="d-flex align-items-center mb-3">
                          <div className="logo">
                            <img
                              src={coverLogo[index]?.logo || noImage}
                              alt="logo"
                            />
                          </div>
                          <div className="me-3 mx-3">
                            <h3>
                              {coverLogo[index]?.label
                                ? coverLogo[index]?.label
                                : "Select"}
                            </h3>
                            <p>
                              {coverLogo[index]?.address
                                ? coverLogo[index]?.address
                                : "Address"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="bottom-footer">
                        <div className="mb-3">
                          <h5 className="title">
                            {currentForm.title ? currentForm.title : "Title"}
                          </h5>
                          <h6 className="fnt-18">
                            {currentForm.description
                              ? currentForm.description
                              : "Description"}
                          </h6>
                          {/* <p>{currentForm.info ? currentForm.info : "Info"}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      disabled={loading1}
                      className="btn-primary btn btn-saves"
                    >
                      Save{" "}
                      {loading1 ? (
                        <Spinner
                          color="info"
                          style={{
                            height: "25px",
                            width: "25px",
                            position: "absolute",
                            top: "14px",
                            right: "31px",
                          }}
                        ></Spinner>
                      ) : null}
                    </button>
                  </div>
                </div>
              </>
            ))}
          </Form>
        </div>
      </Modal>

      {/* modelDeal */}
      <Modal
        isOpen={modelDeal}
        size="lg"
        className="modal-deal"
        style={{ minWidth: "80vw !important" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Add Deal
          </h5>
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormDeal}>
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="row relativerow justify-content-center">
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="title"
                        autoComplete="off"
                        placeholder="Title"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.title || ""}
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        autoComplete="off"
                        defaultValue={currentForm.description || ""}
                        name="description"
                        className="form-control"
                        id="horizontal-password-input"
                        // required="required"
                        placeholder="Description"
                        onChange={handleInputChanges}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <input
                        type="hidden"
                        value={(currentForm.typeselect = "venue" || "")}
                        name="typeselect"
                        onChange={handleInputChanges}
                      />
                      <Select
                        name={`${idName}`}
                        options={venueoptions.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        placeholder="Select Venue"
                        onChange={(e) => handleCoverChange(e, index)}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <Select
                        name={`category`}
                        options={categoryoptions.map((item) => ({
                          label: item.title,
                          value: item._id,
                        }))}
                        placeholder="Select Category"
                        onChange={(e) => updateCategory(e)}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <input
                        type="file"
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                        name="cover"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                      />
                      <br />
                      {previewImage && (
                        <img
                          src={previewImage}
                          onClick={() => showLightBox(previewImage)}
                          style={{ width: "50px", height: "50px" }}
                        />
                      )}
                    </div>

                    <div className="col-lg-6 mb-4">
                      <input
                        type="date"
                        name="startDate"
                        autoComplete="off"
                        placeholder="Start Date"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.startDate || ""}
                        required="required"
                      />
                    </div>

                    <div className="col-lg-6 mb-4">
                      <input
                        type="date"
                        name="endDate"
                        autoComplete="off"
                        placeholder="End Date"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.endDate || ""}
                        required="required"
                      />
                    </div>
                  </div>

                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardHeader className="d-flex justify-content-between">
                          <h4 className="card-title">Vouchers</h4>
                          <Button
                            onClick={addVoucher}
                            type="button"
                            color="primary"
                          >
                            Add Voucher
                          </Button>
                        </CardHeader>
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Sl No.</th>
                                  <th scope="col">Title</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Actual Pricing</th>
                                  <th scope="col">Discounted Price</th>
                                  <th scope="col">
                                    Discount <br />
                                    (In Percentage)
                                  </th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {vouchars.map((voucher, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.title}
                                          onChange={(e) =>
                                            handleInputChange(e, index, "title")
                                          }
                                        />
                                      ) : (
                                        voucher.title
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.description}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              index,
                                              "description"
                                            )
                                          }
                                        />
                                      ) : (
                                        voucher.description
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="number"
                                          defaultValue={voucher.originalPrice}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "originalPrice"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.originalPrice
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.discountedPrice}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "discountedPrice"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.discountedPrice
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="text"
                                          defaultValue={voucher.discountValue}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "discountValue"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.discountValue
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="date"
                                          defaultValue={voucher.startDate}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "startDate"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.startDate
                                      )}
                                    </td>
                                    <td>
                                      {editIndex === index ? (
                                        <input
                                          type="date"
                                          defaultValue={voucher.endDate}
                                          onChange={(e) => {
                                            handleInputChange(
                                              e,
                                              index,
                                              "endDate"
                                            );
                                          }}
                                        />
                                      ) : (
                                        voucher.endDate
                                      )}
                                    </td>
                                    <td>
                                      {voucher.saved ? (
                                        <a
                                          onClick={() => deleteVoucher(index)}
                                          href="javascript:void(0)"
                                        >
                                          Delete
                                        </a>
                                      ) : (
                                        <div>
                                          <Button
                                            onClick={() => saveVoucher(index)}
                                          >
                                            Save
                                          </Button>
                                          <br />
                                          <Button
                                            onClick={() => deleteVoucher(index)}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className="col-lg-3">
                  <div
                    class="box-custom red-border "
                    className={
                      coverLogo[index] ? "box-custom bgcard" : "box-custom"
                    }
                    style={{
                      border: "1px solid #ccc",
                      backgroundImage: selectedImage
                        ? `url(${URL.createObjectURL(selectedImage)})`
                        : `url(${coverLogo[index]?.cover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <Countdown endDate={currentForm.endDate} />

                    <div class="badge-deal">
                      {/* <img src={icon1} alt="icon" /> */}
                      {lowestDiscount ? `${lowestDiscount} off` : "0 % off"}
                    </div>

                    <h5 className="title">
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>

                    <h4
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "32px",
                        lineHeight: "36px",
                      }}
                      className="subtitle"
                    >
                      {currentForm.description
                        ? currentForm.description
                        : "Description"}
                    </h4>

                    <div className="bottom-footer">
                      <div className="d-flex align-items-center deal-footer">
                        <div className="logo" style={{ background: "inherit" }}>
                          <img
                            src={coverLogo[index]?.logo || noImage}
                            alt="logo"
                            className="rounded-circle"
                          />
                        </div>

                        <div>
                          {/* <label className="mb-0"><i class="fas fa-map-marker-alt"></i> 1.3Km away</label> */}
                          {/* <h3>{inputs[index]?.selectCustom ? inputs[index]?.selectCustom.label : 'Select Venue'}</h3> */}
                          <h3 style={{ fontSize: "12px" }}>
                            {coverLogo[index]?.label
                              ? coverLogo[index]?.label
                              : "Select"}
                          </h3>
                          <p style={{ fontSize: "10px" }}>
                            {coverLogo[index]?.address
                              ? coverLogo[index]?.address
                              : "Address"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="box-custom2 bgcard"
                    style={{
                      border: "1px solid #D6D6D6",
                      minHeight: "300px",
                      marginTop: "40px",
                      backgroundColor: "#D6D6D6 !important",
                    }}
                  >
                    {vouchars?.map(
                      (voucher, index) =>
                        voucher.saved && (
                          <div className="ticket" key={index}>
                            <div className="section left">
                              <h2>{voucher.discountValue} Off</h2>
                            </div>
                            <div className="section right">
                              <p>{voucher.description}</p>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>

                <div className="text-center" style={{ margin: "10px" }}>
                  <button
                    disabled={!vouchars?.length}
                    type="submit"
                    className="btn-primary btn btn-saves "
                  >
                    Save
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </div>
      </Modal>
      <VideoModal
        show={videoMdl}
        AddUploadedVideo={AddUploadedVideo}
        closeModal={closeVideoModal}
      />

      <SliderModal
        addData={pushOferPackage}
        show={open}
        closeModal={closeModalSlider}
      />
      <FileUpload message="File Uploading" status={startLoader} />

      <DragDropModal
        show={showOrder}
        data={dragDropData}
        setData={updateDragDropData}
        closeModal={() => setShowOrder(false)}
      />
    </React.Fragment>
  );
}
