import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // Check if the user is authenticated
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // Get account_type from local storage
      const accountType = localStorage.getItem("acc_type");

      console.log(accountType);

      // Check if the account type is business and the current URL is not under the business account section
      if (accountType === "business" && !props.location.pathname.startsWith("/business-account")) {
        // Redirect to business account specific dashboard or page
        return (
          <Redirect
            to={{ pathname: `/business-account${props.location.pathname}`, state: { from: props.location } }}
          />
        );
      }

      // Render the layout and component if the user has proper access
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;