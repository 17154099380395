import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import YachtClubModal from "components/Modal/YachtClubModal.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect.js";

import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import IconPackModal from "components/Modal/IconPackModal";
import Picker from "emoji-picker-react";
import LocationPicker from "react-location-picker";
import Toggle from "react-toggle";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};
export default function CreateYacht() {
  const [previewCover, setPreviewCover] = useState([]);
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [yachtData, setYachtData] = useState(null);
  const [yachtClubId, setYachtClubId] = useState("");
  const [yachtClubText, setYachtClubText] = useState("");
  const [yachtClubImage, setYachtClubImage] = useState("");
  const [iconModal, setIconModal] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [model, setModel] = useState(false);
  const [packages, setPackages] = useState([]);
  const [specification, setSpecification] = useState([
    { title: "Builder", showTitle: true, value: "", readOnly: true },
    { title: "Guests", showTitle: true, value: "", readOnly: true },
    { title: "Speed", showTitle: true, value: "", readOnly: true },
    { title: "Year", showTitle: true, value: "", readOnly: true },
    { title: "Cabins", showTitle: true, value: "", readOnly: true },
    { title: "Length", showTitle: true, value: "", readOnly: true },
    { title: "Beam", showTitle: true, value: "", readOnly: true },
    { title: "Bathrooms", showTitle: true, value: "", readOnly: true },
    { title: "Depth", showTitle: true, value: "", readOnly: true },
  ]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");

  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);

  const [yachtClubOptions, setYachtClubOptions] = useState([
    { value: "", label: "None" },
  ]);

  let history = useHistory();

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeYachtClubModal = () => {
    setModel(false);
  };

  const selectYachtClubRow = (id, name, club) => {
    setYachtClubId(id);
    setYachtClubText(id.name);
    setYachtClubImage(id.logo);
    closeYachtClubModal();
  };

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], yachtData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const yachtDt = { ...yachtData, latitude: lat, longitude: lng };
    setYachtData(yachtDt);
  };

  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }

    setCurrentLocation(newLocation);
    setYachtData({
      ...yachtData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);
  const fetchList = async () => {
    setLoading(true);
    const param = { page: 1, limit: 100000 };
    const yachtClub = await postRequest("yacht/club/list", param);
    if (yachtClub.status == 1) {
      const options = yachtClub.data.list.map((item) => {
        return { value: item._id, label: item.name };
      });

      setYachtClubOptions(options);
    }

    setLoading(false);
  };

  const handlerUploadImage = async (e) => {
    const formData = new FormData();
    const selectedImageForm = new FormData();
    setStartLoader(true);
    selectedImageForm.append("image", e.target.files[0]);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    ).then((res) => {
      let data = res.data;
      console.log({ data });
      if (data?.url) {
        let url = data?.url;
        console.log({ url });
        setPreviewCover((preview) => [...preview, url]);
        setStartLoader(false);
      }
    });
  };

  const removeUploadImage = async (index) => {
    // Filter out the image at the specified index
    const filteredImages = previewCover.filter((item, i) => i !== index);
    // Update the state with the filtered images
    setPreviewCover(filteredImages);
  };

  const Createyacht = async (e) => {
    e.preventDefault();

    // if (!yachtData.yachtClubId) {
    //   toast.error("Please select Yacht Club");
    //   return;
    // }
    if (!yachtClubId) {
      toast.error("Please Select Yacht Club ");
      return;
    }

    if (!yachtData?.name) {
      toast.error("Please enter yacht name");
      return;
    }
    if (!yachtData.about) {
      toast.error("Please enter yacht about");
      return;
    }
    if (!yachtData.latitude) {
      toast.error("Please enter yacht latitude");
      return;
    }
    if (!yachtData.longitude) {
      toast.error("Please enter yacht longitude");
      return;
    }

    // if (!yachtData.year) {
    //   toast.error("Please enter yacht year");
    //   return;
    // }
    // if (!yachtData.people) {
    //   toast.error("Please enter yacht people");
    //   return;
    // }
    // if (!yachtData.cabins) {
    //   toast.error("Please enter yacht cabins");
    //   return;
    // }
    // if (!yachtData.size) {
    //   toast.error("Please enter yacht size");
    //   return;
    // }

    if (!previewCover.length > 0) {
      toast.error("please add atleast one image");
      return;
    }
    // const formData = new FormData();
    // const selectedImageForm = new FormData();
    // setStartLoader(true);
    // selectedImageForm.append("image", selectedCover);
    // const selectedImageRes = await postRequestForm(
    //   "comman/img/upload",
    //   selectedImageForm
    // );
    // setStartLoader(false);

    // formData.append("yachtClubId", yachtClubId._id);
    // formData.append("name", yachtData?.name);
    // formData.append("about", yachtData.about);
    // formData.append("year", yachtData.year);
    // formData.append("people", yachtData.people);
    // formData.append("cabins", yachtData.cabins);
    // formData.append("size", yachtData.size);
    // formData.append("features", packages);
    // // formData.append("image", selectedCover);
    // if (previewCover?.length > 0) {
    //   formData.append("image", previewCover);
    //   // formData.append("image", selectedImageRes.data.url);
    // } else {
    //   // Handle the case where the image URL is not available in the response
    //   console.error("Image URL not found in the response.");
    // }
    console.log({ yachtData });
    let payload = {
      yachtClubId: yachtClubId._id,
      name: yachtData.name,
      about: yachtData.about,
      year: yachtClubId.year,
      people: yachtClubId.people,
      cabins: yachtClubId.cabins,
      size: yachtClubId.size,
      latitude: yachtData.latitude,
      longitude: yachtData.longitude,
      features: packages,
      specifications: specification,
      images: previewCover,
    };

    setLoading(true);
    const response = await postRequest("yacht/create", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/yacht");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...yachtData };
    list[index] = value;
    setYachtData(list);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const updatePackageField = (index, fieldName, value) => {
    console.log(value);

    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
    console.log({ newPackages });
  };
  const updateSpecificationField = (index, fieldName, value) => {
    console.log(index, fieldName, value);

    const newPackages = [...specification];
    newPackages[index][fieldName] = value;
    setSpecification(newPackages);
    console.log({ newPackages });
  };

  const addRow = () => {
    setPackages([
      ...packages,
      { icon: "", feature: "", iconName: "", selectedType: "emoji", emoji: "" },
    ]);
  };

  const addSpecificationRow = () => {
    setSpecification([
      ...specification,
      { title: "", showTitle:false , value: "", readOnly: false },
    ]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const saveSpecificationPackage = (index) => {
    const newPackages = [...specification];
    newPackages[index] = { ...newPackages[index], saved: true };
    setSpecification(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };
  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const deleteSpecificationPackage = (index) => {
    if (index <= 8) {
      toast.error("This is a required field. You cannot delete it.");
      const newPackages = [...specification];
      newPackages[index] = { ...newPackages[index], saved: false };
      setSpecification(newPackages);
      return true;
    }
    const newPackages = specification.filter((item, i) => i !== index);
    setSpecification(newPackages);
  };

  const selectIconRow = (icon) => {
    console.log({ icon });
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, index, fieldName) => {
    const newPackages = [...packages];
    const currentEmoji = newPackages[index][fieldName];

    const updatedEmoji = currentEmoji
      ? currentEmoji + event?.emoji
      : event?.emoji;
    newPackages[index][fieldName] = updatedEmoji;
    setPackages(newPackages);
    setShowPicker(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Yacht Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Yacht Management</li>:{" "}
              <li className="breadcrumb-item">Create Yacht</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Yacht </h4>
                <Form onSubmit={Createyacht}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Yacht Club
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {yachtClubText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseYachtBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Yacht Club
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={yachtClubImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={yachtClubText}
                                  id="selectedYachtClub"
                                  placeholder="No Yacht Club chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseYachtBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Yacht Club
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedYachtClub"
                                  placeholder="No Yacht Club chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => handlerUploadImage(e)}
                              // onChange={(e) =>
                              //   setSelectedCover(e.target.files[0])
                              // }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />

                            <br />
                            {previewCover && (
                              <div className="d-flex">
                                {previewCover.map((item, index) => (
                                  <div className=" me-2" key={index}>
                                    <img
                                      src={item}
                                      onClick={() => showLightBox(item)}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt={`Preview ${index}`} // It's a good practice to add an alt attribute to images
                                    />
                                    <button
                                      onClick={() => removeUploadImage(index)}
                                      type="button"
                                      className="btn btn-danger mt-1 d-block"
                                    >
                                      <i className="fal fa-trash fs-5"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        {/*   <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            People
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("people", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="people"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Year
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("year", e.target.value)
                              }
                              name="year"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Cabins
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("cabins", e.target.value)
                              }
                              name="cabins"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Size
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="size"
                              onChange={(e) =>
                                updateValue("size", e.target.value)
                              }
                              name="size"
                              className="form-control"
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Type</th>
                                  <th>Select </th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <select
                                        className="form-control"
                                        style={{ width: "150px" }}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "selectedType",
                                            e.target.value
                                          );
                                        }}
                                        value={item?.selectedType}
                                        name="selectedType"
                                        class="form-select"
                                      >
                                        <option value="icon">Icon</option>
                                        <option value="emoji">Emoji</option>
                                      </select>
                                    </td>

                                    {item.selectedType == "icon" && (
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            padding:
                                              " 0.375rem 1.75rem 0.375rem 0.75rem",
                                            borderRadius: "0.25rem",
                                            lineHeight: 1.5,
                                          }}
                                        >
                                          <div style={{ minWidth: "100%" }}>
                                            {item?.icon && (
                                              <img
                                                onClick={() => {
                                                  openIconModal(index);
                                                }}
                                                src={item.icon}
                                                style={{
                                                  width: "60px",
                                                  height: "60px",
                                                }}
                                              />
                                            )}

                                            {!item?.icon && (
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  openIconModal(index);
                                                }}
                                                style={{
                                                  color: "white",
                                                  backgroundColor: "tomato",
                                                  border: "none",
                                                  padding: "10px 20px",
                                                }}
                                              >
                                                Select Icon
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {item.selectedType == "emoji" && (
                                      <td>
                                        <div className="picker-container">
                                          <input
                                            className="input-style"
                                            // value={inputStr}
                                            // onChange={(e) =>
                                            //   setInputStr(e.target.value)
                                            // }
                                            value={`${item?.emoji}`}
                                            onChange={(e) => {
                                              updatePackageField(
                                                index,
                                                "emoji",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <img
                                            className="emoji-icon"
                                            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                            onClick={() =>
                                              setShowPicker((val) => !val)
                                            }
                                          />
                                          {showPicker && (
                                            <Picker
                                              pickerStyle={{ width: "100%" }}
                                              onEmojiClick={(event) =>
                                                onEmojiClick(
                                                  event,
                                                  index,
                                                  "emoji"
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    )}
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        style={{ width: "150px" }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Specification
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Value</th>
                                  <th>Show Title </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {specification.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.title}`}
                                        onChange={(e) => {
                                          updateSpecificationField(
                                            index,
                                            "title",
                                            e.target.value
                                          );
                                        }}
                                        readOnly={item?.readOnly}
                                        style={{ width: "150px" }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.value}`}
                                        onChange={(e) => {
                                          updateSpecificationField(
                                            index,
                                            "value",
                                            e.target.value
                                          );
                                        }}
                                        required={index <= 8}
                                        style={{ width: "150px" }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      {/* <input
                                        type="text"
                                        value={`${item?.showTitle}`}
                                        onChange={(e) => {
                                          updateSpecificationField(
                                            index,
                                            "showTitle",
                                            e.target.value
                                          );
                                        }}
                                        style={{ width: "150px" }}
                                        className="form-control"
                                      /> */}

                                      <label>
                                        <Toggle
                                          defaultChecked={
                                            item.showTitle === true
                                          } // Check if status is 0 for "Active"
                                          onChange={(e) => {
                                            updateSpecificationField(
                                              index,
                                              "showTitle",
                                              !item.showTitle
                                            );
                                          }}
                                          icons={false} // Hide the default icons
                                        />
                                        <br></br>
                                        {/* {item.showTitle
                                          ? "Published"
                                          : "Unpublished"} */}
                                      </label>
                                    </td>

                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() =>
                                            saveSpecificationPackage(index)
                                          }
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() =>
                                            deleteSpecificationPackage(index)
                                          }
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addSpecificationRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={yachtData?.latitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lat")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={yachtData?.longitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lng")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <YachtClubModal
        show={model}
        closeYachtClubModal={closeYachtClubModal}
        selectRow={selectYachtClubRow}
      />

      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
