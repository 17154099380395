import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import IconPackModal from "components/Modal/IconPackModal.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import ReactCrop from "react-image-crop";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload";

import { canvasPreview } from "../VenueManagement/canvasPreview.js";
import { useDebounceEffect } from "../VenueManagement/useDebounceEffect.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function CreatePackage() {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  const [isPopular, setIsPopular] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const blobUrlRef = useRef("");

  let history = useHistory();

  const showLightBox = (previewLogo) => {
    setPreviewlogo(previewLogo);
    setOpenLightBox(true);
  };

  const Createpackage = async (e) => {
    e.preventDefault();
    if (!packageData?.title) {
      toast.error("Title is required");
      return;
    }

    if (!packageData?.subTitle) {
      toast.error("Please enter subtitle");
      return;
    }

    if (!packageData?.description) {
      toast.error("Please enter description");
      return;
    }

    if (!packageData?.time) {
      toast.error("Please select validity");
      return;
    }

    if (!packageData?.discountText) {
      toast.error("Please enter discount text");
      return;
    }

    if (!packageData?.actualPrice) {
      toast.error("Please enter actual price");
      return;
    }

    if (parseInt(packageData?.actualPrice) < 1) {
      toast.error("Please enter actual price greater than 0");
      return;
    }
   
    // if (parseInt(packageData?.discountedPrice) < 1) {
    //   toast.error("Please enter discounted price greater than 0");
    //   return;
    // }

    // if (!packageData?.discountedPrice) {
    //   toast.error("Please enter discount price");
    //   return;
    // }

    if (!packages) {
      toast.error("Please add atleast on package");
      return;
    }

    const formData = new FormData();
    const selectedImageForm = new FormData();
    // selectedImageForm.append("backgroundImage", remoteLogo);

    // setStartLoader(true);
    // const selectedImageRes = await postRequestForm(
    //   "comman/img/upload",
    //   selectedImageForm
    // );
    // setStartLoader(false);

    // formData.append("title", packageData.title);
    // formData.append("subTitle", packageData.subTitle);
    // formData.append("discountText", packageData.discountText);
    // formData.append("time", packageData.time ? packageData.time : "monthly");
    // formData.append(
    //   "package_key",
    //   packageData.package_key ? packageData.package_key : "silver_package_key"
    // );
    // formData.append("actualPrice", packageData.actualPrice);
    // formData.append("discountedPrice", packageData.discountedPrice);
    // formData.append("feature", packageData.feature);
    // formData.append("isPopular", isPopular);
    // if (selectedImageRes?.data?.url) {
    //   formData.append("backgroundImage", selectedImageRes.data.url);
    // } else {
    //   console.error("Image URL not found in the response.");
    // }
    let payload = {}
    payload.title = packageData.title;
    payload.subTitle = packageData.subTitle;
    payload.description = packageData.description;
    payload.time = packageData.time;
    payload.daysForCustom = packageData.daysForCustom;
    payload.additionalValidity = packageData.additionalValidity;
    payload.actualPrice = packageData.actualPrice;
    payload.discountType = packageData.discountType;
    payload.discount = packageData.discount;
    payload.buyQty = packageData.buyQty;
    payload.getQty = packageData.getQty;
    payload.isPopular = isPopular;
    payload.features = packages;
    payload.termsAndCondition = packageData.termsAndCondition;
    payload.description = packageData.description;
    payload.discount = packageData.discount;
    payload.discountText = packageData.discountText;
  
    setLoading(true);
    const response = await postRequest(
      "subscription/membership/create",
      payload
    );


    if (response.status == 1) {
      toast.success(response.message);
      setLoading(false);
      setTimeout(() => {
        history.push("/package");
      }, 3000);
    } else {
      toast.error(response.message);
      setLoading(false);
    }
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], "bk-image.jpg", { type: "image/jpeg" });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleIsPopularChange = (event) => {
    setIsPopular(event.target.checked);
  };

  const updateSquareCrop = (crop) => {
    const size = Math.max(crop.width, crop.height);
    setCrop({ ...crop, width: size, height: size });
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  
  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };
  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Package Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Package Management</li>:{" "}
              <li className="breadcrumb-item">Create Package</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Package </h4>
                <Form onSubmit={Createpackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              required
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              required
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              required
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={packageData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />

                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Validity
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("time", e.target.value)
                              }
                              name="time"
                              class="form-select"
                            >
                              <option value="monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="half-yearly">Half-yearly</option>
                              <option value="yearly">Yearly</option>
                              <option value="lifetime">Lifetime</option>
                              <option value="custom">Custom</option>
                            </select>
                          </div>
                        </div>

                        {packageData?.time == 'custom' &&
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Custom Validity ( In Days )
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="Number"
                                onChange={(e) =>
                                  updateValue("daysForCustom", e.target.value)
                                }
                                name="daysForCustom"
                                min={0}
                                className="form-control"
                              />
                            </div>
                          </div>
                          }
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Additional Validity ( In Days )
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="Number"
                              onChange={(e) =>
                                updateValue("additionalValidity", e.target.value)
                              }
                              name="additionalValidity"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              name="actualPrice"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              name="discountPrice"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("discountType", e.target.value)
                              }
                              name="discountType"
                              class="form-select"
                            >
                              <option >Select</option>
                              <option value="regular">Regular</option>
                              <option value="group">Group</option>

                            </select>
                          </div>
                        </div>

                        {packageData?.discountType == 'regular' &&
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Discount In Percentage
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="Number"
                                onChange={(e) =>
                                  updateValue("discount", e.target.value)
                                }
                                name="discount"
                                min={0}
                                className="form-control"
                              />
                            </div>
                          </div>
                          }

                        {packageData?.discountType == 'group' &&
                          <>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Buy Qty
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="Number"
                                  onChange={(e) =>
                                    updateValue("buyQty", e.target.value)
                                  }
                                  name="buyQty"
                                  min={0}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Get Qty
                              </label>
                              <div className="col-sm-6">
                                <input
                                  required
                                  type="Number"
                                  onChange={(e) =>
                                    updateValue("getQty", e.target.value)
                                  }
                                  name="getQty"
                                  min={0}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                          }

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discountText", e.target.value)
                              }
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Package Key
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("package_key", e.target.value)
                              }
                              name="package_key"
                              class="form-select"
                            >
                              <option value="silver_package_key">Silver</option>
                              <option value="gold_package_key">Gold</option>
                              <option value="platinum_package_key">
                                Platinum
                              </option>
                            </select>
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Terms and Conditions
                          </label>
                          <div className="col-sm-6">
                            <ReactQuill
                              value={packageData?.termsAndCondition}
                              onChange={(value) =>
                                updateValue("termsAndCondition", value)
                              }
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, false] }],
                                  [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                  ],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image", "video"],
                                  ["clean"],
                                ],
                              }}
                              formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "video",
                              ]}
                            />
                          </div>
                        </div>

                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Popular
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPopular}
                                  onChange={handleIsPopularChange}
                                  color="primary"
                                  value="true"
                                  name="isPopular"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPopular}
                                  onChange={(event) =>
                                    setIsPopular(!event.target.checked)
                                  }
                                  color="primary"
                                  value="false"
                                  name="isPopular"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />
    </React.Fragment>
  );
}
