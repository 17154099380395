import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";

import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import "tippy.js/dist/tippy.css";

import "react-toastify/dist/ReactToastify.css";

import {
  postRequest,
  deleteRequest,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import VenueModal from "components/Modal/VenueModal.js";
import PromoterModal from "components/Modal/PromoterModal.js";

const VenuePromoterTable = ({ UserId }) => {
  const [PromoterData, setPromoterData] = useState([]);
  const [lbData, setLBData] = React.useState([]);

  const [photoIndex, setPhotoIndex] = React.useState(0);

  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [galleryMdl, setGalleryMdl] = useState(false);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const selectRow = async  (id) => {
    console.log({ id });

    const venueId = id?._id;
   
    const venueName = id?.name;
    const venueLogo = id?.logo;

    setVenueId(venueId);
    setVenueText(venueName);
    setVenueImage(venueLogo);

    // Check if venueId already exists in venueData
    if (PromoterData?.some((venue) => venue?._id === venueId)) {
      toast.error("This Promoter is already available");
    } else {

      try {
        let payload = {
          venueId: UserId,
          promoterIds: [id?._id]
        };
  
        const res = await postRequest("promoter/venue/add-promoters", payload);
  
        // Handle the response
        console.log(res.data); // Log the response data to the console
  
        // Check if the request was successful
        if (res.status === 1) {
          // Display a success toast message
          toast.success("promoter added successfully!");
          setPromoterData((pre) => [...pre, id]);
          closeModal();
        } else {
          // Display a warning toast message if the response status is not 200
          toast.warning("Unexpected response status: " + res.status);
        }
      } catch (error) {
        // Handle errors
        console.error("An error occurred:", error);
  
        // Display an error toast message
        toast.error("An error occurred while adding venues.");
      }





  
    }
  };

  const showLightBox = (galleryData, initialIndex = 0) => {
    setLBData(galleryData);
    setPhotoIndex(initialIndex);
    setOpenLightBox(true);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Venue ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      // Use async to allow awaiting Axios call
      if (result.isConfirmed) {
        try {
          let payload = {
            venueId: UserId,
            promoterIds: [id],
          };

          const res = await postRequest(
            "promoter/venue/remove-promoters",
            payload
          );
          if (res.status == 1) {
            // Show success toast message
            toast.success("promoter  remove successfully!");
            PromoterDetails();
          }
        } catch (error) {
          // Handle errors
          console.error("An error occurred:", error);
          // Show error toast message
          toast.error("An error occurred while deleting the venue .");
        }
      }
    });
  };



  const PromoterDetails = async (e) => {
    try {
      let payload = {
        venueId: UserId,
      };

      const res = await postRequest("promoter/fetch-by-venueId", payload);

      if (res.status === 1) {
        let Data = res?.data;
        setPromoterData(Data);
        console.log("venue Details", { Data });
      } else {
        // Display a warning toast message if the response status is not 200
        toast.warning("Unexpected response status: " + res.status);
      }
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);

      // Display an error toast message
      toast.error("An error occurred while adding venues.");
    }
  };

  useEffect(() => {
    PromoterDetails();
  }, []);
  return (
    <>
      <React.Fragment>
        {/* <ToastContainer /> */}
        <Row>
          <div className="col-md-12 text-end" style={{ marginBottom: "20px" }}>
            <Col className="col-lg-12 d-flex mb-3 justify-content-end">
              <div className="col-sm-3" onClick={() => toggleModel()}>
                {venueText ? (
                  <div class="input-group">
                    <button
                      class="btn"
                      style={{
                        border: "1px solid #ced4da",
                        background: "#e60489",
                        color: "#ffff",
                      }}
                      type="button"
                      id="chooseVenueBtn"
                      onclick={() => toggleModel()}
                    >
                      Add Promoter
                    </button>
                    <div
                      style={{
                        border: "1px solid #ced4da",
                        width: "16px",
                      }}
                    ></div>
                    <img
                      src={venueImage}
                      style={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid #ced4da",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    ></img>
                    <input
                      type="text"
                      style={{ borderLeft: "none" }}
                      class="form-control"
                      readonly
                      value={venueText}
                      id="selectedVenue"
                      placeholder="No Promoter  Add"
                    />
                  </div>
                ) : (
                  <div class="input-group">
                    <button
                      class="btn"
                      style={{
                        border: "1px solid #ced4da",
                        background: "#e60489",
                        color: "#ffff",
                      }}
                      type="button"
                      id="chooseVenueBtn"
                      onclick={() => toggleModel()}
                    >
                      Add Promoter
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      id="selectedVenue"
                      placeholder="No Promoter Add"
                    />
                  </div>
                )}
              </div>{" "}
            </Col>
          </div>

          {loader ? (
            <Col md={12} style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </Col>
          ) : (
            <>
              {PromoterData?.length == 0 && (
                <div className="col-md-12 text-center">
                  <h4>
                    {venueId == "" ? "Please Select Promoter" : "No Promoter"}
                  </h4>
                </div>
              )}

              {PromoterData.map((item, index) => (
                <Col
                  key={item._id}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={3}
                  className="col-xxxl-3"
                >
                  <Card>
                    <CardBody>
                      <img
                        src={item?.images||item?.image}
                        onClick={() =>
                          showLightBox(
                            PromoterData.map((item) => item.item?.image),
                            index
                          )
                        }
                        alt="img"
                        className="mb-3"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-truncate font-size-14">
                          {item.first_name ||item?.name} {item.last_name}
                        </h5>

                        <Button
                          color="danger"
                          onClick={() => handlerdelete(item._id)}
                        >
                          <i className="fal fa-trash fs-5"></i>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}

          
            </>
          )}
        </Row>

        <PromoterModal
          show={model}
          closeModal={closeModal}
          selectRow={selectRow}
        />
      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={lbData[photoIndex]}
          onCloseRequest={() => setOpenLightBox(false)}
          nextSrc={lbData[(photoIndex + 1) % lbData.length]}
          prevSrc={lbData[(photoIndex + lbData.length - 1) % lbData.length]}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lbData.length - 1) % lbData.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lbData.length)
          }
        />
      )}
    </>
  );
};

export default VenuePromoterTable;
