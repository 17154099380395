import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload";
import {
  postRequestForm,
  postRequest,
  customValidator,
  
} from "../../components/Common/Utils.js";
import YachtClubModal from "components/Modal/YachtClubModal.js";

export default function CreateYachtGallery() {
  const [yachtGalleryData, setYachtGalleryData] = useState({ title: "" });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [yachtClubList, setYachtClubList] = useState([]);

  const [yachtClubId, setYachtClubId] = useState("");
  const [yachtClubText, setYachtClubText] = useState("");
  const [yachtClubImage, setYachtClubImage] = useState("");
  const [model, setModel] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
  }, []);
  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("yacht/club/list", { limit: 10000000 });
    if (response.status == 1) {
      setYachtClubList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeYachtClubModal = () => {
    setModel(false);
  };

  const selectYachtClubRow = (id, name,club) => {
    setYachtClubId(id);
    setYachtClubText(id.name);
    setYachtClubImage(id.logo);
    closeYachtClubModal();
  };

  const Createyachtgallery = async (e) => {
    e.preventDefault();
   

    if(!yachtClubId){
      toast.error("Please Select Yacht Club ");
      return;
    }
    

    const formData = new FormData();
    const selectedImageForm = new FormData();
    setStartLoader(true);
    selectedImageForm.append("file", selectedImage);    
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
    setStartLoader(false);
    formData.append("yachtClubId", yachtClubId._id);
   
    if (selectedImageRes?.data?.url) {
      formData.append("file", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    } 
    setLoading(true);
    const response = await postRequestForm("yacht/gallery/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/yachtgallery");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...yachtGalleryData };
    list[index] = value;
    setYachtGalleryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Yacht Gallery Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Yacht Gallery Management
              </li>
              : <li className="breadcrumb-item">Create Yacht Gallery</li>
            </ol>
          </div>
        </div>
        
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Yacht Gallery </h4>
                <Form onSubmit={Createyachtgallery}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Yacht Club
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {yachtClubText ? (
                             
                              <div class="input-group">
                                <button class="btn" style={{ border: "1px solid #ced4da", background: "#e60489", color: "#ffff"   }} type="button" id="chooseYachtBtn" onclick={() => toggleModel()}>Choose Yacht Club</button>
                                <div style={{ border: "1px solid #ced4da",width:"16px"}}></div><img src={yachtClubImage} style={{ width: "40px", height: "40px", border: "1px solid #ced4da",borderRight:"none",borderLeft:"none" }}></img>
                                <input type="text" style={{borderLeft:"none"}} class="form-control" readonly value={yachtClubText} id="selectedYachtClub" placeholder="No Yacht Club chosen"/> 
                              </div>
                            ) : (
                             
                              <div class="input-group">
                              <button class="btn" style={{ border: "1px solid #ced4da", background: "#e60489", color: "#ffff"  }} type="button" id="chooseYachtBtn" onclick={() => toggleModel()}>Choose Yacht Club</button>
                              <input type="text" class="form-control" readonly id="selectedYachtClub" placeholder="No Yacht Club chosen"/>                                
                            
                            </div>
                            )}
                          </div>
                        </div>
                       

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      <YachtClubModal
        show={model}
        closeYachtClubModal={closeYachtClubModal}
        selectRow={selectYachtClubRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
