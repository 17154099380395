import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import io from 'socket.io-client';

class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentValue: 0,
            onlineUsersCount: 0, // Add state to track the latest online users count
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#f3f3f3'],
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0.5,
                        stops: [0, 90, 100]
                    },
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: this.generateXaxisCategories(),
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: true,
                        formatter: (value) => {
                            if (value === 0) return "60 sec";
                            if (value === 15) return "45 sec";
                            if (value === 30) return "30 sec";
                            if (value === 45) return "15 sec";
                            return "";
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    labels: {
                        show: true,
                        formatter: (value) => { return Math.floor(value); }
                    },
                    tickAmount: 5, // Ensure there are a reasonable number of ticks, adjust as necessary
                },
                legend: {
                    show: false
                },
            },
            series: [{
                name: 'Users',
                data: this.generateInitialData()
            }],
        }

        this.socket = io('https://socket.whosin.me'); // Connect to the Socket.IO server
    }

    generateInitialData() {
        return Array(60).fill(0);
    }

    generateXaxisCategories() {
        const categories = [];
        for (let i = 0; i < 60; i++) {
            categories.push(i);
        }
        return categories;
    }

    componentDidMount() {
        this.socket.on('online_users_updated', (data) => {           
            const onlineUsersCount = data.length;

            console.log('onlineUsersCount', onlineUsersCount)

            this.setState({ onlineUsersCount });
        });

        this.socket.on('country_data_updated', (data) => {           
            const onlineUsersCount = data;
            console.log('country data', onlineUsersCount)           
        });
        

        this.interval = setInterval(this.updateGraph, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.socket.disconnect();
    }

    updateGraph = () => {
        this.setState((prevState) => {
            const newData = [...prevState.series[0].data];
            newData.shift();
            newData.push(prevState.onlineUsersCount);

            const maxValue = Math.max(...newData);
            const yAxisMax = maxValue === 0 ? 1 : Math.ceil(maxValue * 1.4); // Set y-axis max to 40% above the highest value, rounded up to ensure minimum step of 1

            const colors = this.getBarColors(newData);

            return {
                series: [{
                    name: 'Users',
                    data: newData
                }],
                options: {
                    ...prevState.options,
                    colors: colors,
                    yaxis: {
                        // ...prevState.options.yaxis,
                        max: yAxisMax,
                        min: 0,
                        tickAmount: yAxisMax,
                    }
                },
                currentValue: prevState.onlineUsersCount
            };
        });
    }

    getBarColors(data) {
        let isDowntrend = false;
        return data.map((value, index, array) => {
            if (index === 0) {
                return '#0061C2'; // default color for the first value
            } else {
                if (value < array[index - 1]) {
                    isDowntrend = true;
                    return '#E42C2C'; // red shade for downtrend
                } else if (value > array[index - 1]) {
                    isDowntrend = false;
                    return '#28a745'; // green shade for uptrend
                } else {
                    return isDowntrend ? '#E42C2C' : '#28a745'; // keep the color consistent with the trend
                }
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Users Online {this.state.currentValue}</h4>
                        <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="300" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MonthlyEarnings;
