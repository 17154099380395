import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData }) => {
  const [packageData, setPackageData] = useState({});

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const addDataPackage = (e) => {
    e.preventDefault();

    if (!packageData.title) {
      alert("Title is required");
      return false;
    }

    if (!packageData.amount) {
      alert("Please enter amount");
      return false;
    }

    // check amount, discount, qty not less than 0

    if (packageData.amount < 0) {
      alert("Amount should not be less than 0");
      return false;
    }

    if(!packageData.discount){
      alert("Please enter discount");
      return false;
    }

    if (packageData.discount < 5) {
      alert("Discount should not be less than 5");
      return false;
    }

    // discount should not be less than 100

    if (packageData.discount > 99) {
      alert("Discount must be less than 100");
      return false;
    }

    if(!packageData.qty){
      alert("Please enter qty");
      return false;
    }

    if (packageData.qty < 0) {
      alert("Qty should not be less than 0");
      return false;
    }

    if (packageData.discount > 100) {
      alert("Discount should not be greater than 100");
      return false;
    }

    if (packageData.leftQtyAlert) {
      if (packageData.leftQtyAlert < 1) {
        alert("Please enter valid left qty alert");
        return false;
      }
    }



    const packData = { ...packageData };

    addData(packageData);
    packData.title = "";
    packData.packages_description = "";
    packData.isFeatured = false;
    packData.qty = 0;
    packData.leftQtyAlert = 0;
    packData.amount = 0;
    packData.discount = 0;
    
    // packData.claimCode = "";
    //setPackageData(packData);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="table-shorting">
                <Form onSubmit={addDataPackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue(
                                  "packages_description",
                                  e.target.value
                                )
                              }
                              value={packageData?.packages_description}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={packageData?.amount}
                              min={1}
                              name="amount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount In Percentage
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min="5"
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              value={packageData?.discount}
                              name="discount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Claim Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("claimCode", e.target.value)
                              }
                              value={packageData?.claimCode}
                              name="claimCode"
                              className="form-control"
                            />
                          </div>
                        </div> */}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Sell
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isAllowSale}
                              onChange={(e) =>
                                updateValue("isAllowSale", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        {packageData.isAllowSale && (
                          <>
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">QTY</label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                onChange={(e) =>
                                  updateValue("qty", e.target.value)
                                }
                                value={packageData?.qty}
                                min={1}
                                name="qty"
                                className="form-control"
                              />
                            </div>
                          </div>   
                          
                          
                          <div
                              className="row mb-4"
                              style={{
                                display: packageData.isAllowClaim
                                  ? "none"
                                  : "flex",
                              }}
                            >
                              <label className="col-sm-3 col-form-label">
                                Left Qty Alert On
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={1}
                                  max={packageData?.qty}
                                  onChange={(e) =>
                                    updateValue("leftQtyAlert", e.target.value)
                                  }
                                  value={packageData?.leftQtyAlert}
                                  // value={
                                  //   packageData?.qty === undefined
                                  //     ? 0
                                  //     : packageData.qty
                                  // }
                                  name="qty"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            </>
                          
                          

                        )}


                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Featured
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              checked={packageData.isFeatured}
                              onChange={(e) =>
                                updateValue("isFeatured", e.target.checked)
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const PackageModal = ({ show, closeModal, addData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add Package
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent addData={addData} />
      </div>
    </Modal>
  );
};

export default PackageModal;
