import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModalOrg.js";
export default function CreateCustomComponent() { 
  

  const [customData, setCustomData] = useState(null); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [loading, setLoading] = useState(false);  
  const [selectedImage, setSelectedImage] = useState(""); 
  const [venueList, setVenueList] = useState([]);
  const [offerList, setOfferList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false); 

  let history = useHistory();

  const { id } = useParams(); 
  
  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  
  useEffect(() => {
    fetchList();
    fetchOfferList();
    }, []);

    const fetchList = async () => {
      setLoading(true);
      const response = await postRequest("venue/list", { limit: 10000000});
      if (response.status == 1) {
        setVenueList(response.data.list);
      }
      setLoading(false);
    };

    const fetchOfferList = async () => {
      setLoading(true);
      const response = await postRequest("venue/offer/list/admin", { limit: 10000000});
      if (response.status == 1) {
        setOfferList(response.data.list);
      }
      setLoading(false);
    };
    const toggleModel = () => {
      setModel(!model);
    };

    const toggleModel2 = () => {
      setModel2(!model2);
    };
  
 
    const closeModal = () => {
      setModel(false);
    };
    const closeModal2 = () => {
      setModel2(false);
    };

    const selectRow = (id, name) => {
      setVenueId(id);
      setVenueText(name);
      closeModal();
    };  

    const selectRow2 = (id, title) => {
      setOfferId(id);
      setOfferText(title);
      closeModal2();
    };  

  const Createcustom = async (e) => {
    e.preventDefault();
    // if (!venueId) {
    //   toast.error("Please select venue");
    //   return;
    // }
    if (!customData.title) {
      toast.error("Title is required");
      return;
    }
    if (!customData.description) {
      toast.error("Please enter description");
      return;
    }
    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }
   setLoading(true);
  
    const formData = new FormData();
    formData.append("venueId", venueId); 
    formData.append("offerId", offerId);
    formData.append("title", customData.title);
    formData.append("description", customData.description);   
    formData.append("badge", customData.badge); 
    formData.append("type", customData.type);
    formData.append("image", selectedImage);
    
   
    
   
    
    const response = await postRequestForm("homeblock/custom/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/customComponents");
      }, 3000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...customData}
    list[index] = value
    setCustomData(list)
    
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Custom Component Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Custom Component Management</li>:{" "}
              <li className="breadcrumb-item">
              Create Custom Component
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Create Custom Component </h4>
              <Form onSubmit={Createcustom}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">            

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } name='title'  className="form-control"  />
                            </div>
                        </div>     
                       
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Description</label>
                            <div className="col-sm-6">
                            <textarea type="textarea" onChange={ (e) => updateValue('description', e.target.value) } name='description' className="form-control" id="horizontal-password-input" />
                            
                            </div>
                        </div>  
                        
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="type"
                              class="form-select"
                            >
                              <option value="">Select</option>                           
                              <option value="offer">Offer</option>                              
                              <option value="venue">Venue</option>
                              
                            </select>
                          </div>
                        </div>

                        {customData?.type == "offer" && (           
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Offer</label>
                            
                            <div className="col-sm-6"  onClick={() => toggleModel2()}>
                            {offerText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {offerText}
                                  </a>
                                </div>
                                <div>
                                  
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Offer
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel2()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div> ) }

          
                       {customData?.type == "venue" && (           
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Venue</label>
                            
                            <div className="col-sm-6"  onClick={() => toggleModel()}>
                            {venueText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {venueText}
                                  </a>
                                </div>
                                <div>
                                  
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Venue
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div> ) }
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Badge
                          </label>
                        <div className="col-sm-6">
                                      <input type="text" name="badge" className="form-control" 
                                      required="required" autoComplete="off" placeholder="Badge"
                                      onChange={(e) =>
                                        updateValue("badge", e.target.value)
                                      }
                                      />
                                    </div> 
                        </div>                           
                        
                        

                        <div className="row mb-4">
                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Image</label>
                            <div className="col-sm-6">
                            <input type="file"  accept="image/*" onChange={(e) => setSelectedImage(e.target.files[0])}  name='image' className="form-control" id="horizontal-password-input" required="required" />
                           <br/>
                           {previewImage && (
                              <img
                                src={previewImage}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          
                            </div>                           
                        </div>                       
                                             
             
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} /> 
      <OfferModal show={model2} closeModal={closeModal2} selectRow={selectRow2} /> 

    </React.Fragment>



  );
}
