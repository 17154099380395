import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import "./bucket.css";
import "./CustomCarduser.css";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import BootstrapTable from "react-bootstrap-table-next";

import Lightbox from "react-image-lightbox";
import CustomAvatar from "../../components/Common/Avatar.js";
import Swal from "sweetalert2";
import moment from "moment";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.css";
import "react-image-lightbox/style.css";

const filterObj = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  createdAt: "",
  enabled: "",
};

const BucketList = ({ showBucketModal, closeBucketModal, offer }) => {
  const [modal_center, setmodal_center] = useState(false);
  const sharedWithData = offer?.shared_with;
  const highlight = offer?.galleries;

  const [bucketData, setBucketData] = useState([]);

  const [offersData, setOffersData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [activeTab, setActiveTab] = useState("sharedWith");

  useEffect(() => {
    setOffersData(offer?.offers); // Update the bucketData when offer.items changes
  }, [offer?.offers]);
  useEffect(() => {
    setEventsData(offer?.events ? offer.events : []); // Update the bucketData when offer.items changes
  }, [offer?.events]);
  useEffect(() => {
    setActivityData(offer?.activity); // Update the bucketData when offer.items changes
  }, [offer?.activity]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }
  function ImgFormatter2(cellContent, row) {
    return (
      <>
        {row.image ? (
          <img
            src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              row?.first_name + row?.last_name == ""
                ? "*"
                : `${row?.first_name} ${row?.last_name} `
            }
          />
        )}
      </>
    );
  }

  function LogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.coverImage}
          onClick={() => showLightBox(row.coverImage)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const columnsUser = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "last_name",
      text: "Last name",
      sort: false,
    },

    {
      dataField: "first_name",
      text: "First name",
      sort: false,
    },

    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter2,
      sort: false,
    },

    // {
    //   dataField: "venue.name",
    //   text: "Venue",
    //   sort: false,
    // },
    {
      dataField: "actions",
      text: "Remove",
      formatter: iconFormatterOffer,
      sort: false,
    },
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
    },

    // {
    //   dataField: "venue.name",
    //   text: "Venue",
    //   sort: false,
    // },
    {
      dataField: "actions",
      text: "Remove",
      formatter: iconFormatterOffer,
      sort: false,
    },
  ];

  const columnsActivity = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: LogoFormatter,
      sort: false,
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
    },
    {
      dataField: "actions",
      text: "Remove",
      formatter: iconFormatterActivity,
      sort: false,
    },
  ];

  const columnsEvent = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      text: "Name",
      sort: false,
    },

    {
      dataField: "orgData.name",
      text: "Provider Name",
      sort: false,
    },

    {
      dataField: "venue.name",
      text: "Venue Name",
      sort: false,
    },

    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
    },
    {
      dataField: "actions",
      text: "Remove",
      formatter: iconFormatterEvent,
      sort: false,
    },
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };

  const handlesorting = (event) => {
    setsort(event.target.value);
  };

  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };

  const handleReload = () => {
    window.location.reload();
  };

  function iconFormatterOffer(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, "offer")}
          >
            <i className="fal fa-ban fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  function iconFormatterActivity(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, "activity")}
          >
            <i className="fal fa-ban fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  function iconFormatterEvent(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex gap-1">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, "event")}
          >
            <i className="fal fa-ban fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const handlerdelete = (id, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Remove this offer",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, type); 
      }
    });
  };

  const showRandomWordDialog = (id, type) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm removal, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        removeItem(id, type);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const removeItem = async (id, type) => {
    let body = { id: offer._id, action: "delete" };

    if (type === "event") {
      body.eventId = id;
    } else if (type === "activity") {
      body.activityId = id;
    } else {
      body.offerId = id;
    }

    const response = await postRequest("bucket/item/addRemove", body);
    if (response.status) {
      Swal.fire("Removed!", "Item Removed Successfully");
      window.location.reload();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  const renderUserList = () => {
    return (
      <div className="user-list">
        {sharedWithData?.map((user) => (
          <div className="user-item mx-3" key={user.id}>
            <img
              src={user.image}
              onError={(e) =>
                (e.target.src =
                  "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png")
              }
            />

            <p>
              {user.first_name} {user.last_name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      isOpen={showBucketModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
      style={{ width: "80%" }}
    >
      <>
        <React.Fragment>
          <div className="modal-header">
            {/* <div>
              <h4 className="mx-2 my-2">{offer?.name}</h4>
              <h6 className="mx-2 my-2">{offer?.items?.length} Offer</h6>
            </div> */}
            <h4 className="modal-title mt-0" id="myLargeModalLabel">
              Bucket Details
            </h4>

            <button
              onClick={() => {
                closeBucketModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div
              className="cover-image"
              style={{ backgroundImage: `url(${offer?.coverImage})` }}
              onClick={() => showLightBox(offer?.coverImage)}
            ></div>
            <h4 className="mx-2 my-2">{offer?.name}</h4>
            <h6 className="mx-2 my-2">{offer?.description}</h6>
          </div>

          {/* <ToastContainer /> */}

          <Row>
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Col md="12" style={{marginLeft:"inherit"}}>
                      <div
                        id="exTab2"
                        className="twitter-like-tabs2"
                        style={{ background: "#ffff", marginLeft: "25px" }}
                      >
                        <ul
                          className="nav nav-pills"
                          style={{ width: "480px", display: "contents" }}
                        >
                          <li
                            className={
                              activeTab === "sharedWith" ? "active" : ""
                            }
                          >
                            <a
                              href="#sharedWith"
                              data-toggle="tab"
                              onClick={() => setActiveTab("sharedWith")}
                            >
                              Shared With
                            </a>
                          </li>
                          <li className={activeTab === "offer" ? "active" : ""}>
                            <a
                              href="#offer"
                              data-toggle="tab"
                              onClick={() => setActiveTab("offer")}
                            >
                              Offers
                            </a>
                          </li>

                          <li className={activeTab === "event" ? "active" : ""}>
                            <a
                              href="#event"
                              data-toggle="tab"
                              onClick={() => setActiveTab("event")}
                            >
                              Events
                            </a>
                          </li>
                          <li
                            className={activeTab === "activity" ? "active" : ""}
                          >
                            <a
                              href="#activity"
                              data-toggle="tab"
                              onClick={() => setActiveTab("activity")}
                            >
                              Activity
                            </a>
                          </li>
                          <li
                            className={
                              activeTab === "highlight" ? "active" : ""
                            }
                          >
                            <a
                              href="#highlight"
                              data-toggle="tab"
                              onClick={() => setActiveTab("highlight")}
                            >
                              Highlight
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="tab-content clearfix">
                        <div
                          className={
                            activeTab === "event"
                              ? "tab-pane active padded-tab"
                              : "tab-pane padded-tab"
                          }
                          id="event"
                        >
                          <PaginationProvider
                            pagination={paginationFactory({
                              page: currentFPage,
                              sizePerPage: sizePerPageOrg,
                              totalSize: totalPage,
                              custom: true,
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <>
                                <span> </span>
                                <ToolkitProvider
                                  keyField="_id"
                                  data={eventsData}
                                  columns={columnsEvent}
                                >
                                  {(props) => (
                                    <Row>
                                      <Col className="col-lg-12 text-end">
                                        <BootstrapTable
                                          onTableChange={handleTableChange}
                                          remote={true}
                                          rowClasses={rowClasses}
                                          // classes="table-custom-striped"
                                          classes="table-custom-border"
                                          {...props.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </ToolkitProvider>

                                <Row>
                                  <Col className="col-lg-12 text-end">
                                    <div className="text-md-right">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </PaginationProvider>
                        </div>

                        <div
                          className={
                            activeTab === "activity"
                              ? "tab-pane active padded-tab"
                              : "tab-pane padded-tab"
                          }
                          id="activity"
                        >
                          <PaginationProvider
                            pagination={paginationFactory({
                              page: currentFPage,
                              sizePerPage: sizePerPageOrg,
                              totalSize: totalPage,
                              custom: true,
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <>
                                <span> </span>
                                <ToolkitProvider
                                  keyField="_id"
                                  data={activityData}
                                  columns={columnsActivity}
                                >
                                  {(props) => (
                                    <Row>
                                      <Col className="col-lg-12 text-end">
                                        <BootstrapTable
                                          onTableChange={handleTableChange}
                                          remote={true}
                                          rowClasses={rowClasses}
                                          // classes="table-custom-striped"
                                          classes="table-custom-border"
                                          {...props.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </ToolkitProvider>

                                <Row>
                                  <Col className="col-lg-12 text-end">
                                    <div className="text-md-right">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </PaginationProvider>
                        </div>

                        <div
                          className={
                            activeTab === "highlight"
                              ? "tab-pane active padded-tab"
                              : "tab-pane padded-tab"
                          }
                          id="highlight"
                        >
                          <div className="user-list-container">
                            <Row>
                              {highlight?.map((gallery, index) => (
                                <Col
                                  key={gallery}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                >
                                  <Card>
                                    <CardBody>
                                      <img
                                        src={gallery}
                                        alt="gallery image "
                                        className="mb-3"
                                        style={{
                                          height: "200px",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                        onClick={() => showLightBox(gallery)}
                                      />
                                    </CardBody>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>

                        <div
                          className={
                            activeTab === "sharedWith"
                              ? "tab-pane active padded-tab"
                              : "tab-pane padded-tab"
                          }
                          id="sharedWith"
                        >
                          <PaginationProvider
                            pagination={paginationFactory({
                              page: 1,
                              sizePerPage: 20,
                              totalSize: sharedWithData?.length,
                              custom: true,
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <>
                                <span> </span>
                                <ToolkitProvider
                                  keyField="_id"
                                  data={sharedWithData}
                                  columns={columnsUser}
                                >
                                  {(props) => (
                                    <Row>
                                      <Col className="col-lg-12 text-end">
                                        <BootstrapTable
                                          onTableChange={handleTableChange}
                                          remote={true}
                                          rowClasses={rowClasses}
                                          // classes="table-custom-striped"
                                          classes="table-custom-border"
                                          {...props.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </ToolkitProvider>

                                <Row>
                                  <Col className="col-lg-12 text-end">
                                    <div className="text-md-right">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </PaginationProvider>
                        </div>

                        <div
                          className={
                            activeTab === "offer"
                              ? "tab-pane active padded-tab"
                              : "tab-pane padded-tab"
                          }
                          id="offer"
                        >
                          <PaginationProvider
                            pagination={paginationFactory({
                              page: currentFPage,
                              sizePerPage: sizePerPageOrg,
                              totalSize: totalPage,
                              custom: true,
                            })}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <>
                                <span> </span>
                                <ToolkitProvider
                                  keyField="_id"
                                  data={offersData}
                                  columns={columns}
                                >
                                  {(props) => (
                                    <Row>
                                      <Col className="col-lg-12 text-end">
                                        <BootstrapTable
                                          onTableChange={handleTableChange}
                                          remote={true}
                                          rowClasses={rowClasses}
                                          // classes="table-custom-striped"
                                          classes="table-custom-border"
                                          {...props.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </ToolkitProvider>

                                <Row>
                                  <Col className="col-lg-12 text-end">
                                    <div className="text-md-right">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </PaginationProvider>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          {openLightbox && (
            <Lightbox
              mainSrc={image}
              onCloseRequest={() => setOpenLightBox(false)}
            />
          )}
        </React.Fragment>
      </>
    </Modal>
  );
};

export default BucketList;
