import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from 'react-apexcharts';
import './styles.css';

class YearlySales extends Component {
    constructor(props) {
        super(props);

        const defaultData = [8, 6, 4, 7, 10, 12, 7, 4, 9, 12, 13, 11, 12];
        const defaultTitle = 'Yearly Sales';
        const defaultDescription = 'The languages only differ in grammar';
        const defaultLink = '#';
        const defaultCount = '52,345';

        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: '70%',
                        dataLabels: {
                            show: false
                        },
                        distributed: true,
                        borderRadius: 10, // Adding border radius
                        barHeight: '70%',
                    },
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    show: false,
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    categories: [],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                },
                fill: {
                    type: 'gradient', // Adding gradient fill
                    gradient: {
                        shade: 'light',
                        type: "horizontal",
                        shadeIntensity: 0.25,
                        gradientToColors: undefined, // Optionally set an array of colors for gradient
                        inverseColors: true,
                        opacityFrom: 0.85,
                        opacityTo: 0.85,
                        stops: [50, 0, 100, 100]
                    }
                },
            },
            series: [{
                name: 'Series A',
                data: props.data || defaultData
            }],
            colors: this.getBarColors(props.data || defaultData),
            title: props.title || defaultTitle,
            description: props.description || defaultDescription,
            link: props.link || defaultLink,
            count: props.count || defaultCount,
        }
    }

    getBarColors(data) {
        return data.map((value, index, array) => {
            if (index === 0) {
                return '#0061C2';
            } else {
                return value > array[index - 1] ? '#28a745' : (value < array[index - 1] ? '#E42C2C' : '#0061C2');
            }
        });
    }

    render() {
        const { options, series, colors, title, description, link, count } = this.state;

        return (
            <React.Fragment>
                <Card className="custom-card">
                    <CardBody className="custom-card-body">
                        <h4 className="card-title mb-4">{title}</h4>
                        <Row>
                            <Col md="4">
                                <div className="card-statistics">
                                    <h3>{count}</h3>
                                    <p className="text-muted">{description}</p>
                                    <Link to={link} className="text-primary">Learn more <i className="mdi mdi-chevron-double-right"></i></Link>
                                </div>
                            </Col>
                            <Col md="8" className="text-end">
                                <div id="sparkline">
                                    <ReactApexChart options={{ ...options, colors }} series={series} type="bar" height="130" className="apex-charts" />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default YearlySales;
