import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload"; 


import Lightbox from "react-image-lightbox";
export default function UpdatedAddonYacht() {



  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [openLightbox, setOpenLightBox] = React.useState(false);  

  let history = useHistory();

  const { _id } = useParams();
  const location = useLocation();
  const state = location.state?.row;

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);



useEffect(()=>{
console.log({state})
setBannerData(state)
setPreviewImage(state?.image)
},[state])




  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };





  const Createbanner = async (e) => {
    e.preventDefault();
   

   


    if (!bannerData?.title) {
      toast.error("Title is required");
      return;
    }
 

  
  

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
    setStartLoader(false);

    formData.append("iconId", bannerData._id);
    formData.append("title", bannerData.title);
    formData.append("price", bannerData.price);
    // formData.append("image", selectedImage);

    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    } 


    setLoading(true);
    const response = await postRequestForm("yacht/update/addons", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/Yacht-icon-Management-list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Yacht Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Yacht Management</li>:{" "}
              <li className="breadcrumb-item">Updat Add On </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Updat Add On </h4>
                <Form onSubmit={Createbanner}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("price", e.target.value)
                              }
                              value={bannerData?.price}
                              name="price"
                              className="form-control"
                              min={0}
                            />
                          </div>
                        </div>

                     

                    

                    

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                          
                            />
                            <br />
                            {bannerData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">Start Date</label>
                              <div className="col-sm-6">
                              <input type="date" onChange={ (e) => updateValue('startDate', e.target.value) } name='startDate'  className="form-control"  />

                                                            </div>
                          </div>           

                          <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">End Date</label>
                              <div className="col-sm-6">
                                  <input type="date" onChange={ (e) => updateValue('endDate', e.target.value) } name='endDate'  className="form-control"  />
                              </div>
                          </div>     */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}




      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
      {/* modal */}
     
    </React.Fragment>
  );
}
