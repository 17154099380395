import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ addData, currentPackageData }) => {
  console.log("currentPackageData", currentPackageData);

  const [packageData, setPackageData] = useState(currentPackageData || {});

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const ClaimAmount = (e) => {
    e.preventDefault();

    if (!packageData.title) {
      alert("Title is required");
      return false;
    }
    if (!packageData.description) {
      alert("Title is required");
      return false;
    }
    if (!packageData.unit) {
      alert("please select unit");
      return false;
    }

  


    const packData = {
      ...packageData,
    };

    addData(packData);

    console.log(packData, packageData);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="">
                <Form onSubmit={ClaimAmount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              required
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                            required
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={packageData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              value={packageData?.amount}
                              name="amount"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              value={packageData?.discount}
                              name="discount"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Unit
                          </label>
                          <div className="col-sm-6">
                            <select
                            required
                              onChange={(e) =>
                                updateValue("unit", e.target.value)
                              }
                              value={packageData?.unit}
                              name="unit"
                              className="form-select"
                            >
                              <option selected>select Unit Type</option>
                              <option value="hours">Hours</option>
                              <option value="days">Days</option>
                              <option value="weeks">Weeks</option>
                              <option value="months">Months</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Min Unit
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("minUnit", e.target.value)
                              }
                              value={packageData?.minUnit}
                              name="minUnit"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Max Unit
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("maxUnit", e.target.value)
                              }
                              value={packageData?.maxUnit}
                              name="maxUnit"
                              min={packageData?.minUnit||1}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Price    Per Unit
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              required
                              onChange={(e) =>
                                updateValue("perUnitPrice", e.target.value)
                              }
                              value={packageData?.perUnitPrice}
                              name="perUnitPrice"
                              min="1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const YachtPackageAddHourly = ({
  show,
  closeModal,
  addData,
  currentPackageData,
}) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Package Info
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent
          addData={addData}
          currentPackageData={currentPackageData}
        />
      </div>
    </Modal>
  );
};

export default YachtPackageAddHourly;
