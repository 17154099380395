import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";


import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css'; // optional for styling

import FilterModal from "../../components/Modal/FilterModal";
import {  
  postRequest,
  // callCSVRequest,
  deleteRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";


import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomAvatar from "../../components/Common/Avatar.js";
import LogManagementList from "./LogManagementList";

const filterObj = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "",
  createdAt: "",
  status: "",
};

const UserManagementlist = (props) => {
  const  permissions  =  { create: true, update: true, delete: true, show: true } ;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc"); 

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  // useEffect(() => {
  //   userManagment();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (currentPage == 1) {
  //     userManagment();
  //     return;
  //   }
  //   setCurrentPage(1);
  // }, [sort, sortOrder, sizePerPageOrg]);

  // useEffect(() => {  
  //   if(!resetSearch) return;
  //   if (currentPage == 1) {
  //     userManagment([], true);
  //     return;
  //   }
  //   setCurrentPage(1);    
  // }, [resetSearch]);

  useEffect(() => {
    const fetchData = () => {
      if (resetSearch) {
        userManagment([], true);
      } else {
        userManagment();
      }
    };

    // Call fetchData only when specific dependencies change
    fetchData();
  }, [currentPage, sort, sortOrder, sizePerPageOrg, resetSearch]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [sort, sortOrder, sizePerPageOrg]);

  const userManagmentSearch = async () => {
    if(currentPage == 1){
      userManagment();
      return;
    }
    setCurrentPage(1);
  };





  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleDownload = async () => {
    try {
      const response = await callCSVRequest("user/get-csv")

      console.log('response', response)

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response], { type: 'text/csv' });

      // Use FileSaver to save the file
      saveAs(blob, 'user-data.csv');
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value;
    
    const dataObj = {
      userId: actId,
      enable: status,
    };

    const res = await postRequest("user/update/admin", dataObj);

    if (res.status == 1) {
      toast.success("User Status updated Successfully");
      userManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const VipAccessOnChange = async (value, _id) => {
    let actId = _id;
    let status = value;
    
    const dataObj = {
      userId: actId,
      isVip: status,
    };

    const res = await postRequest("user/update/admin", dataObj);

    if (res.status == 1) {
      toast.success("User Status updated Successfully");
      userManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);        
      }
    }    
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  const updateFilter = (key, value) => {

    if(key === "phone"){

      // valid phone +1 123-456-7890 apply regex
      if(value.includes("-")){     
        return;
      } 
    }
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };


  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email" ||
          key === "phone" ||
          key === "gender"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "enable") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  }  

  const applyFilter = () => {
    setFilterActive(true)    
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email" ||
          key === "phone" ||
          key === "gender"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "enable") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });    
    toggleModel();    

    userManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false)
    setFilter({ ...filterObj });
    toggleModel();
    userManagment(null, true);
  };
  

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.enable == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.enable = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.enable = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        {row.image ? (
          <img
            src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              row?.first_name + row?.last_name == ""
                ? "*"
                : `${row?.first_name} ${row?.last_name} `
            }
          />
        )}
      </>
    );
  }
    

  function SNoFormatter(cellContent, row, rowIndex) {
    let firstItemIndex;

      console.log("totalPage", sortOrder)
  
      // if (sortOrder === 'desc') {
      //   // Calculate the first item index on the current page in descending order

      //   console.log("totalPage", totalPage)

      //   firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      // } else {
      //   // Calculate the first item index on the current page in ascending order
      //   firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
        
      // }

      if (sortOrder === 'desc') {
        // Calculate the first item index on the current page in descending order
        firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      } else {
        // Calculate the first item index on the current page in ascending order
        firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
      }

      // Adjust the rowIndex to calculate the actual item index
      const itemIndex = sortOrder === 'desc' ? firstItemIndex - rowIndex : firstItemIndex + rowIndex;


      return <>{itemIndex}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">First Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.first_name}
          onChange={(e) => updateFilter("first_name", e.target.value)}
          name="first_name"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Last Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.last_name}
          onChange={(e) => updateFilter("last_name", e.target.value)}
          name="last_name"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Email</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.email}
          onChange={(e) => updateFilter("email", e.target.value)}
          name="email"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Phone</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.phone}
          onChange={(e) => updateFilter("phone", e.target.value)}
          name="last_name"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Gender</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("gender", e.target.value)}
          className="form-select"
          value={filter.gender}
        >
          <option value="">Select Gender</option>
          <option value="man">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("enable", e.target.value)}
          className="form-select"
          value={filter.enable}
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "image",
      text: "User Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
    },

    {
      dataField: "email",
      text: "Email",
      sort: false,
    },

    {
      dataField: "type",
      text: "Type",
      sort: false,
    },

    {
      dataField: "phone",
      text: "Phone No.",
      sort: false,
    },
    // {
    //   dataField: "platform",
    //   text: "Platform",
    //   sort: false,
    //   formatter: platformFormatter,
    // },
    // {
    //   dataField: "social_platform",
    //   text: "Social platform",
    //   sort: false,
    //   formatter: socialPlatformFormatter,
    // },
    // {
    //   dataField: "isVip",
    //   text: "VIP Access",
    //   sort: false,
    //   formatter: VIPAccessformFormatter,
    // },
    // {
    //   dataField: "enable",
    //   text: "Status",
    //   formatter: activeFormatter,
    //   sort: false,
    // },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };

 


  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };



  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">

        <Tippy content="View Logs">
            <button type="button" className=" btn btn-warning mx-1">
              <Link
                to={{
                  //  pathname: `/venue-analytic/${row._id}`,
                   pathname: `/log-management-list/${row._id}`,
                   state: { row },
                }}
              >
                <i className="fal fa-analytics fs-5 text-light"></i>
                
              </Link>
            </button>
          </Tippy>
          &nbsp;&nbsp;
        {permissions.update && (
        <Tippy content="Edit">

          <button type="button" className=" btn btn-info  mx-2">
            <Link
              to={{
                pathname: `/updateRole/${row._id}`,
                state: { row },
              }}
            >
              <i className="fal fa-pencil fs-5 text-light"></i>
            </Link>
          </button>
          </Tippy> 
        )}
         {permissions.delete && (
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
           )}
        </div>
      </React.Fragment>
    );
  }

  function platformFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="text-center">
          {row.platform === "ios" ? (
            <button type="button" className=" btn btn-dark text-light">
              {" "}
              <i className="fab fa-apple text-light"></i>
            </button>
          ) : (
            <>
              {row.platform === "android" ? (
                <button type="button" className=" btn btn-success text-light">
                  {" "}
                  <i className="fab fa-android text-light"></i>
                </button>
              ) : (
                <button type="button" className=" btn btn-warning text-light">
                  {" "}
                  <i className="fa fa-globe text-light"></i>
                </button>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }

  function socialPlatformFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="text-center">
          {row.social_platform === "apple" ? (
            <button type="button" className=" btn btn-dark text-light">
              {" "}
              <i className="fab fa-apple text-light"></i>
            </button>
          ) : (
            <>
              {row.social_platform === "google" ? (
                <button type="button" className=" btn btn-danger text-light">
                  {" "}
                  <i className="fab fa-google text-light"></i>
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }

  function VIPAccessformFormatter(cellContent, row) {
    return (
      <>
      {row.isVip ? (
        <>
          <button
            className="btn btn-active"
            type="button"
            onClick={() => VipAccessOnChange((row.isVip = false), row._id)}
          >
            Yes
          </button>
        </>
      ) : (
        <>
          <button
            className="btn btn-deactive"
            type="button"
            onClick={() => VipAccessOnChange((row.isVip = true), row._id)}
          >
            No
          </button>
        </>
      )}
    </>
    );
  }

  const userManagment = async (filterArgs = [], resetFilter = false) => {    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }

    if(filterActive && !filterArgs?.length && !resetFilter){
      payload.filterArgs = fetchFilter()
    }


    try {
      const response = await postRequest(`user/admin/list`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setuserdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id, ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, ids);
      }
    });
  };

  const showRandomWordDialog = (id, ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteRequestFunction(id, ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteRequestFunction = async (id, ids) => {
    const response = await deleteRequest("user/remove/admin", { userId: id });
    if (response.status === 1) {
      Swal.fire("Deleted!", "Deleted successfully");
      userManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("user/remove/admin", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      userManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Role Access Management</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Role Access Management</li>:{" "}
                <li className="breadcrumb-item">Role Access List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
          
            <Link to="/createRole">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
             
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={userdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="first_name">
                                      First Name
                                    </option>
                                    <option value="last_name">Last Name</option>
                                    <option value="email">Email</option>
                                    <option value="phone">Phone</option>
                                    <option value="platform">Platform</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        userManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                    {/* <button
                                      type="button"
                                      onClick={() => handleDownload()}
                                      className="btn btn-success"
                                    >
                                      CSV
                                    </button> */}
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    bootstrap4
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>

                                { totalPage > sizePerPageOrg ? (
                                   <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ sizePerPageOrg }</b> From <b>{totalPage}</b> </p> 
                                ) : (
                                  <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ totalPage }</b> From <b>{totalPage}</b> </p>
                                ) }


                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default UserManagementlist;
