import React, { Component } from 'react';
import { Row, Card, CardBody, Col } from "reactstrap";
import io from 'socket.io-client';
import Vector from "./Vector"; // Ensure the path is correct

class RevenueByLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData: []
        };
    }

    componentDidMount() {
        //this.socket = io('http://64.227.131.3:2096'); // Connect to the Socket.IO server
        this.socket = io('https://socket.whosin.me'); // Connect to the Socket.IO server
        this.socket.on('country_data_updated', (data) => {
            this.setState({ countryData: data });
        });
    }

    componentWillUnmount() {
        this.socket.disconnect();
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Online Users by Locations</h4>
                            <div id="world-vectormap" style={{ height: "400px" }}>
                                <Vector
                                    countryData={this.state.countryData}
                                />
                            </div>
                            <Row className="justify-content-center">
                                <Col xl={5} md={6}>
                                    {this.state.countryData.map((country, index) => (
                                        <div className="clearfix py-2" key={index}>
                                            <h5 className="float-end font-size-16 m-0">{country.count}</h5>
                                            <p className="text-muted mb-0 text-truncate">{country.country} :</p>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default RevenueByLocations;
